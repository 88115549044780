import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@andre-muller/ckeditor5-build-decoupled-document';
import api from '~/services/api';
import ValidationError from '~/components/ValidationError';

import '~/assets/css/ckeditor.css';

export default function Ckeditor({ name, defaultValue }) {
  const [value, setValue] = useState(defaultValue || '');
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />
      <div className="document-editor">
        <div className="document-editor__toolbar" />
        <div className="document-editor__editable-container">
          <div className="document-editor__editable">
            <CKEditor
              onInit={(editor) => {
                const toolbarContainer = document.querySelector(
                  '.document-editor__toolbar',
                );

                toolbarContainer.appendChild(editor.ui.view.toolbar.element);

                window.editor = editor;
              }}
              onChange={(event, editor) => {
                setValue(editor.getData());
              }}
              onFocus={clearError}
              config={{
                toolbar: {
                  items: [
                    'exportPdf',
                    '|',
                    'heading',
                    '|',
                    'fontSize',
                    'fontFamily',
                    'fontBackgroundColor',
                    'fontColor',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    '|',
                    'pageBreak',
                    'alignment',
                    '|',
                    'numberedList',
                    'bulletedList',
                    '|',
                    'indent',
                    'outdent',
                    '|',
                    'link',
                    'blockQuote',
                    'imageUpload',
                    'insertTable',
                    'mediaEmbed',
                    '|',
                    'undo',
                    'redo',
                  ],
                },
                exportPdf: {
                  stylesheets: [
                    'EDITOR_STYLES',
                    // Add an external stylesheet after the 'EDITOR_STYLES' token.
                    '../../assets/css/ckeditor.css',
                  ],
                  fileName: 'mt-documento.pdf',
                  converterOptions: {
                    // Document format settings with proper margins.
                    format: 'Letter',
                    margin_top: '19mm',
                    margin_bottom: '19mm',
                    margin_right: '19mm',
                    margin_left: '19mm',
                    page_orientation: 'portrait',
                  },
                },
                image: {
                  styles: ['full', 'alignLeft', 'alignRight'],
                  toolbar: [
                    'imageStyle:alignLeft',
                    'imageStyle:full',
                    'imageStyle:alignRight',
                  ],
                },
                table: {
                  contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                  ],
                },
                simpleUpload: {
                  // The URL that the images are uploaded to.
                  uploadUrl: `${
                    process.env.NODE_ENV === 'development'
                      ? 'http://192.168.1.128:3333'
                      : 'https://backend-vanessaterapeuta.herokuapp.com'
                  }/terapeutas/files-ckeditor`,

                  // Enable the XMLHttpRequest.withCredentials property.
                  withCredentials: true,

                  // Headers sent along with the XMLHttpRequest to the upload server.
                  headers: {
                    'X-CSRF-TOKEN': 'CSFR-Token',
                    Authorization: api.defaults.headers.Authorization,
                  },
                },
                // This value must be kept in sync with the language defined in webpack.config.js.
                language: 'pt-br',
              }}
              editor={DecoupledEditor}
              data={value}
            />
          </div>
        </div>
      </div>
      <ValidationError error={error} />
    </>
  );
}

Ckeditor.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

// Same approach for defaultProps too
Ckeditor.defaultProps = {
  defaultValue: '',
};
