import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  financeiroInstitutions: {
    data: [],
  },
};

export default function financeiroInstitutions(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroInstitutions/INDEX_REQUEST_SUCCESS': {
        draft.financeiroInstitutions.data = action.payload.data;
        draft.loading = false;

        break;
      }
      case '@financeiroInstitutions/INDEX_REQUEST':
      case '@financeiroInstitutions/FAILURE': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
