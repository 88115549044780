export function indexRequest(page, perPage) {
  return {
    type: '@financeiroCategorias/INDEX_REQUEST',
    payload: { page, perPage },
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroCategorias/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function storeRequest(name, color, parent_id, type) {
  return {
    type: '@financeiroCategorias/STORE_REQUEST',
    payload: {
      name,
      color,
      parent_id,
      type,
    },
  };
}

export function storeSuccess(data, closeModal) {
  return {
    type: '@financeiroCategorias/STORE_SUCCESS',
    payload: { data, closeModal },
  };
}

export function updateRequest(id, name, color) {
  return {
    type: '@financeiroCategorias/UPDATE_REQUEST',
    payload: {
      id,
      name,
      color,
    },
  };
}

export function categoriaRequest(id) {
  return {
    type: '@financeiroCategorias/CATEGORIA_REQUEST',
    payload: {
      id,
    },
  };
}

export function categoriaRequestSuccess(data) {
  return {
    type: '@financeiroCategorias/CATEGORIA_REQUEST_SUCCESS',
    payload: {
      data,
    },
  };
}

export function selectRequest() {
  return {
    type: '@financeiroCategorias/SELECT_REQUEST',
  };
}

export function selectPaisRequest() {
  return {
    type: '@financeiroCategorias/SELECT_PAIS_REQUEST',
  };
}

export function selectPaisSuccess(data) {
  return {
    type: '@financeiroCategorias/SELECT_PAIS_SUCCESS',
    payload: { data },
  };
}

export function selectRequestSuccess(data) {
  return {
    type: '@financeiroCategorias/SELECT_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function togleFechaModalAdd(modal, categoriaId, categoriaName) {
  return {
    type: '@financeiroCategorias/FECHA_ADD_MODAL',
    payload: { modal, categoriaId, categoriaName },
  };
}

export function deleteRequest(id) {
  return {
    type: '@financeiroCategorias/DELETE_REQUEST',
    payload: {
      id,
    },
  };
}

export function failure() {
  return {
    type: '@financeiroCategorias/FAILURE',
  };
}
