const routes = [
  {
    path: '/terapeuta/dashboard',
    name: 'Atendimentos',
    icon: 'fas fa-calendar text-primary',
  },
  {
    path: '/terapeuta/pacientes',
    name: 'Clientes',
    icon: 'fas fa-user-friends text-primary',
  },
  {
    path: '/terapeuta/registros-atendimentos',
    name: 'Registros',
    icon: 'ni ni-archive-2 text-primary',
  },
  {
    collapse: true,
    name: 'Financeiro',
    icon: 'ni ni-chart-pie-35 text-primary',
    state: 'finCollapse',
    views: [
      {
        path: '/terapeuta/financeiro',
        name: 'Visão Geral',
      },
      {
        path: '/terapeuta/financeiro/movimentacoes',
        name: 'Movimentações',
      },
      {
        path: '/terapeuta/financeiro/contas',
        name: 'Contas',
      },
    ],
  },
  {
    collapse: true,
    name: 'Configurações',
    icon: 'fas fa-cogs text-primary',
    state: 'mapsCollapse',
    views: [
      {
        path: '/terapeuta/categorias-registros',
        name: 'Registros',
      },
      {
        path: '/terapeuta/categorias-agendamentos',
        name: 'Agendamentos',
      },
      {
        path: '/terapeuta/financeiro-categorias',
        name: 'Categorias Financeiras',
      },
    ],
  },
];

export default routes;
