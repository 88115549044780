import React, { useState, useRef, useEffect } from 'react';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter';
import Sidebar from 'components/Sidebar/Sidebar';

import routes from '../routes/routes';

const Admin = (props) => {
  const [sidenavOpen, setSideNavOpen] = useState(true);
  const mainContent = useRef();

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setSideNavOpen(false);
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    }
  }, []);

  // toggles collapse between mini sidenav and normal
  function toggleSidenav(e) {
    if (sidenavOpen) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSideNavOpen(!sidenavOpen);
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/',
          imgSrc: require('assets/img/brand/wanessa-araujo.png'),
          imgAlt: 'Wanessa Araujo',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          theme="light"
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText="Brand Text"
        />
        {props.children}
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

export default Admin;
