/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import MainHeader from '../../../components/Headers/MainHeader';
import Paginations from '../../../components/Pagination/Paginations';
import ModalDelete from '../../../components/ConfirmDelete';
import loadingGlobal from '../../../components/loadingGlobal';
import { transformMoeda } from '~/helpers/transform';
import StoreAgendamento from './store';

import {
  indexRequest,
  deleteRequest,
  togleFechaModalAdd as togleFechaModalAddCategoria,
} from '../../../store/modules/categoriasAgendamento/actions';
import { togleFechaModalAdd } from '../../../store/modules/modal/actions';

export default function CategoriaAgendamentos() {
  const addModal = useSelector((state) => state.modal.addModal);
  const addModalCategoria = useSelector(
    (state) => state.categoriasAgendamento.addModal,
  );
  const categoriasLoadingData = useSelector(
    (state) => state.categoriasAgendamento.loading,
  );
  const categoriaAgendamentos = useSelector(
    (state) => state.categoriasAgendamento.categoriasAgendamento.data,
  );
  const { total, perPage, page } = useSelector(
    (state) => state.categoriasAgendamento.categoriasAgendamento,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    loadingGlobal(categoriasLoadingData);
  }, [categoriasLoadingData]);

  useEffect(() => {
    dispatch(indexRequest(1, 20));
  }, [dispatch]);

  function togleAddModal(modal, agendamento) {
    dispatch(togleFechaModalAddCategoria(modal, agendamento));
  }

  function handlerEdit(data) {
    togleAddModal(!addModalCategoria, data);
  }

  function handleDelete(id) {
    dispatch(togleFechaModalAdd(!addModal, id));
  }

  return (
    <>
      <MainHeader
        name="Categorias De Agendamentos"
        parentName="Configurações"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Categorias de Agendamentos</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href="#empoderaTerapeuta"
                  onClick={() => togleAddModal(!addModalCategoria)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                  <span className="btn-inner--text">Nova Categoria</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th />
                <th>Nome</th>
                <th>Intervalo</th>
                <th>Duração</th>
                <th>Valor</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {categoriaAgendamentos.map((data) => (
                <tr key={data.id}>
                  <td>
                    {data.color && (
                      <span
                        className="timeline-step badge-success"
                        style={{
                          position: 'relative',
                          backgroundColor: data.color,
                          color: '#fff',
                        }}
                      >
                        <i className={`fa fa-${data.icon}`} />
                      </span>
                    )}
                  </td>
                  <td className="table-user">
                    <a
                      style={{
                        color: '#525f7f',
                      }}
                      href="#empoderaTerapeuta"
                      onClick={() => handlerEdit(data)}
                    >
                      {data.name}
                    </a>
                  </td>
                  <td>
                    <span className="text-muted">{data.intervalo}</span>
                  </td>
                  <td>
                    <span className="text-muted">{`${data.duracao}min`}</span>
                  </td>
                  <td>
                    <span className="text-muted">
                      {transformMoeda(data.valor, 2)}
                    </span>
                  </td>
                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={() => handlerEdit(data)}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action-delete"
                      href="#empoderaTerapeuta"
                      onClick={() => handleDelete(data.id)}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
              <StoreAgendamento />
              <ModalDelete deleteRequest={deleteRequest} />
            </tbody>
          </Table>
          <Paginations
            page={page}
            total={total}
            perPage={perPage}
            funcaoIndex={indexRequest}
          />
        </Card>
      </Container>
    </>
  );
}
