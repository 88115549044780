import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

const chamarWhats = require('~/assets/img/chamar-whats.png');

export default function Register() {
  const email = useSelector((state) => state.auth.email);

  return (
    <>
      <div className="header bg-default py-7 py-lg-8 pt-lg-9">
        <div className="container">
          <div className="header-body text-center mb-3">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 className="text-white">
                  Cadastro realizado com sucesso, verifique seu e-mail.
                </h1>
                <p className="text-lead text-white">
                  Enviamos o link de ativação da sua conta para:
                  <br />
                  <strong>{email}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-left">
                  <p>
                    Tanto o acesso ao sistema quanto a comunição precisarão
                    utilizar seu e-mail, por isso é importante que ele esteja
                    correto e recebendo nossos comunicados.
                  </p>
                  <p>
                    Caso não tenha recebido o e-mail, ou queira apenas bater um
                    papo será uma satisfação te conhecer!
                  </p>
                  <div className="text-center">
                    <p> Por favor nos chame no Whatsapp sempre que precisar!</p>
                    <a
                      href="https://api.whatsapp.com/send/?phone=555184354064&text=Acabei+de+fazer+meu+cadastro+e+queria+tirar+algumas+duvidas&lang=pt_br"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img alt="Chamar no Whatsapp" src={chamarWhats} />
                    </a>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
