import React, { useState, useRef } from "react";
import { useDispatch, useSelector, } from 'react-redux';
import { useParams } from "react-router";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader";
import { Form } from '@unform/web';
import Input from '../../../components/Input'
import * as Yup from 'yup'

import { newPasswordRequest } from '../../../store/modules/novaSenha/actions';


export default function NewPassword(props) {
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [focusedPasswordConfirmation, setFocusedPasswordConfirmation] = useState(false);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const { recover_password_token } = useParams();

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required('Senha é obrigatória.')
          .min(8, 'Passowrd muito curto - precisa pelo menos 8 caracteres.'),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Senha e confirmação não conferem')
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const { password, password_confirmation } = data;
      dispatch(newPasswordRequest(recover_password_token, password, password_confirmation));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <AuthHeader
        title="Recuperar senha"
        lead=""
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Insira a nova senha desejada</small>
                </div>
                <Form role="form" ref={formRef} onSubmit={handleSubmit} className="needs-validation">
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Password"
                        className="form-control"
                        type="password"
                        onFocus={() =>
                          setFocusedPassword(true)
                        }
                        onBlur={() =>
                          setFocusedPassword(false)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPasswordConfirmation
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password_confirmation"
                        placeholder="Password Confirmation"
                        className="form-control"
                        type="password"
                        onFocus={() =>
                          setFocusedPasswordConfirmation(true)
                        }
                        onBlur={() =>
                          setFocusedPasswordConfirmation(false)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-muted font-italic">
                    <small>
                      password strength:{" "}
                      <span className="text-success font-weight-700">
                        strong
                        </span>
                    </small>
                  </div>
                  <div className="text-center">
                    <Button className="mt-4" color="info" type="submit">
                      {loading ? '... carregando' : 'Cadastrar'}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
