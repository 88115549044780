export function selectRequest() {
  return {
    type: '@compartilhamentos/SELECT_REQUEST'
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {

  return {
    type: '@compartilhamentos/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data }

  };
}

export function compartilhamentoRequest(registro_id) {

  return {
    type: '@compartilhamentos/COMPARTILHAMENTO_REQUEST',
    payload: { registro_id }

  };
}

export function compartilhamentoRequestSuccess(id, compartilhamento_id, terapeutas, invites) {

  return {
    type: '@compartilhamentos/COMPARTILHAMENTO_REQUEST_SUCCESS',
    payload: { id, compartilhamento_id, terapeutas, invites }

  };
}

export function compartilhamentoUpdateRequest(registro_id, compartilhamento_id) {

  return {
    type: '@compartilhamentos/COMPARTILHAMENTO_UPDATE_REQUEST',
    payload: { registro_id, compartilhamento_id }

  };
}

export function inviteRequest(registro_id, email) {

  return {
    type: '@compartilhamentos/INVITE_REQUEST',
    payload: { registro_id, email }

  };
}

export function compartilhamentoDeleteRequest(registro_id, terapeuta_id) {

  return {
    type: '@compartilhamentos/COMPARTILHAMENTO_DELETE_REQUEST',
    payload: { registro_id, terapeuta_id }

  };
}

export function inviteDeleteRequest(registro_id, invite_id) {

  return {
    type: '@compartilhamentos/INVITE_DELETE_REQUEST',
    payload: { registro_id, invite_id }

  };
}

export function togleFechaModalEdit(modal) {
  return {
    type: '@compartilhamentos/FECHA_EDIT_MODAL',
    payload: { modal }

  };
}

export function failure() {
  return {
    type: '@compartilhamentos/FAILURE'
  };
}
