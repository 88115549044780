/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';

import TextInputMask from 'react-masked-text';
import history from '~/services/history';
import Input from '../../../components/Input';

import { indexRequest as indexRequestRegistrosAtendimentos } from '../../../store/modules/registrosCompartilhados/actions';

export default function SearchHeader({ defaultFilters }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [categorias, setCategorias] = useState([]);
  const [dataIni, setDataIni] = useState('');
  const [dataEnd, setDataEnd] = useState('');
  const [name, setNameValue] = useState('');
  const [title, setTitleValue] = useState('');
  const [conteudo, setConteudoValue] = useState('');
  const [terapeuta, setTerapeuta] = useState('');

  useEffect(() => {
    if (defaultFilters) {
      if (defaultFilters.name) {
        setNameValue(defaultFilters.name);
      }
      if (defaultFilters.title) {
        setTitleValue(defaultFilters.title);
      }
      if (defaultFilters.conteudo) {
        setConteudoValue(defaultFilters.conteudo);
      }
      if (defaultFilters.categorias) {
        setCategorias(defaultFilters.categorias);
      }
      if (defaultFilters.dateIni) {
        const value = moment(
          defaultFilters.dateIni,
          ['YYYY-MM-DD'],
          'pt-br',
        ).format('DD/MM/YYYY');
        setDataIni(value);
      }
      if (defaultFilters.dateEnd) {
        const value = moment(
          defaultFilters.dateEnd,
          ['YYYY-MM-DD'],
          'pt-br',
        ).format('DD/MM/YYYY');
        setDataEnd(value);
      }
      if (defaultFilters.terapeuta) {
        setTerapeuta(defaultFilters.terapeuta);
      }
    }
  }, [defaultFilters]);

  function handleClean() {
    formRef.current.reset();
    formRef.current.setData({
      name: '',
      title: '',
      conteudo: '',
      terapeuta: '',
    });
    setDataIni('');
    setDataEnd('');
    setCategorias([]);
    setNameValue('');
    setTerapeuta('');
    setTitleValue('');
    setConteudoValue('');
    if (history.location.state) {
      history.location.state = null;
    }
    dispatch(indexRequestRegistrosAtendimentos(1, 20));
  }

  async function handleSubmit() {
    const dateIni = dataIni
      ? moment(dataIni, ['DD/MM/YYYY'], 'pt-br').format('YYYY-MM-DD')
      : null;
    const dateEnd = dataEnd
      ? moment(dataEnd, ['DD/MM/YYYY'], 'pt-br').format('YYYY-MM-DD')
      : null;

    const filters = {
      name,
      dateIni,
      dateEnd,
      categorias,
      title,
      conteudo,
      terapeuta,
    };

    dispatch(indexRequestRegistrosAtendimentos(1, 20, filters));
  }

  return (
    <>
      <Container className="search-header">
        <Row>
          <Col>
            <Form
              role="form"
              ref={formRef}
              onSubmit={handleSubmit}
              className="needs-validation"
            >
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Paciente"
                        className="form-control"
                        type="text"
                        onChange={(e) => setNameValue(e.target.value)}
                        value={name}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col xs="3">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInputMask
                        name="dateIni"
                        className="form-control"
                        placeholder="Data inicial"
                        kind="custom"
                        options={{
                          mask: '99/99/9999',
                        }}
                        onChangeText={(e) => setDataIni(e)}
                        value={dataIni}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInputMask
                        name="dateEnd"
                        className="form-control"
                        placeholder="Data final"
                        kind="custom"
                        options={{
                          mask: '99/99/9999',
                        }}
                        onChangeText={(e) => setDataEnd(e)}
                        value={dataEnd}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="terapeuta"
                        placeholder="Terapeuta"
                        className="form-control"
                        type="text"
                        onChange={(e) => setTerapeuta(e.target.value)}
                        value={terapeuta}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-caps-small" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="title"
                        placeholder="Título"
                        className="form-control"
                        type="text"
                        onChange={(e) => setTitleValue(e.target.value)}
                        value={title}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-chat-round" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="conteudo"
                        placeholder="Conteudo"
                        className="form-control"
                        type="text"
                        onChange={(e) => setConteudoValue(e.target.value)}
                        value={conteudo}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-left btn-padding" xs="12">
                  <Button
                    className="btn-neutral btn-round btn-icon"
                    color="default"
                    href=""
                    onClick={() => {
                      handleClean();
                    }}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-notes-medical" />
                    </span>
                    <span className="btn-inner--text">Limpar Filtros</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-round btn-icon"
                    color="default"
                    href=""
                    type="submit"
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-notes-medical" />
                    </span>
                    <span className="btn-inner--text">Buscar</span>
                  </Button>
                </Col>
              </Row>
              <br />
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

SearchHeader.propTypes = {
  defaultFilters: PropTypes.shape({
    name: PropTypes.string,
    dateIni: PropTypes.string,
    dateEnd: PropTypes.string,
    categorias: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    conteudo: PropTypes.string,
    terapeuta: PropTypes.string,
  }),
};

SearchHeader.defaultProps = {
  defaultFilters: {},
};
