import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';
import ValidationError from '~/components/ValidationError';

import { togleFechaModalAdd } from '~/store/modules/categoriasAgendamento/actions';

export default function SelectCategoriasAgendamento({ defaultValue }) {
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(
    'agendamento_categoria_id',
  );

  /** Variaveis para montar o select create */
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [value, setValue] = useState(null);
  const [catIdSel, setCatIdSel] = useState(null);

  const selectCategorias = useSelector(
    (state) => state.categoriasAgendamento.categoriasAgendamento.data,
  );

  useEffect(() => {
    if (defaultValue && defaultValue.id && defaultValue.name) {
      setCatIdSel(defaultValue.id);
      setValue({ label: defaultValue.name, value: defaultValue.id });
    }
  }, [defaultValue]);

  const addModal = useSelector((state) => state.categoriasAgendamento.addModal);

  useEffect(() => {
    if (!addModal) {
      setIsLoading(false);
    }
  }, [addModal]);

  const togleModal = useCallback(
    (v, tp) => {
      dispatch(togleFechaModalAdd(v, tp));
      if (tp === 1) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setValue(null);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const nArray = [];
    selectCategorias.forEach((e) => {
      nArray.push({ label: e.name, value: e.id });
    });
    setOption(nArray);
  }, [selectCategorias]);

  const handleChange = useCallback(
    (newValue) => {
      clearError();
      setValue(newValue || null);
      setCatIdSel(newValue ? newValue.value : null);
    },
    [clearError],
  );

  const handleCreate = useCallback(
    (inputValue) => {
      togleModal(true, {
        id: undefined,
        color: '#9f70ed',
        name: inputValue,
        duracao: '',
        intervalo: '',
        valor: 0,
        financeiro_conta_id: 0,
        add_registro: 0,
        due_days: 0,
      });
      setIsLoading(true);
    },
    [togleModal],
  );

  return (
    <>
      <input ref={inputRef} defaultValue={catIdSel} type="hidden" />
      <CreatableSelect
        placeholder="Selecione uma categoria de agendamento"
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={option}
        value={value}
        formatCreateLabel={(i) => `Nova: "${i}"`}
      />
      <ValidationError error={error} />
    </>
  );
}

SelectCategoriasAgendamento.propTypes = {
  defaultValue: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

SelectCategoriasAgendamento.defaultProps = {
  defaultValue: null,
};
