import produce from 'immer';

const INITIAL_STATE = {
  addModal: false,
  loading: false,
  hasPaciente: false,
  pacientesFound: [],
  pacientePosted: null,
  pacienteSelected: {
    id: null,
    name: null,
    nascimento: null,
    email: null,
    celular: null,
    telefone: null,
  },
  paciente: {
    id: null,
    responsavel_id: null,
    name: '',
    nascimento: '',
    email: '',
    celular: '',
    telefone: '',
    cep: '',
    cidade: '',
    uf: '',
    bairro: '',
    endereco: '',
    numero: '',
    complemento: '',
    guardians: [],
  },
  pacientes: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    filters: {},
    data: [],
  },
};

export default function pacientes(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@pacientes/INDEX_REQUEST_SUCCESS': {
        draft.pacientes.total = Number(action.payload.total);
        draft.pacientes.perPage = Number(action.payload.perPage);
        draft.pacientes.page = Number(action.payload.page);
        draft.pacientes.lastPage = Number(action.payload.lastPage);
        draft.pacientes.data = action.payload.data;
        draft.pacientes.filters = action.payload.filters;
        draft.loading = false;
        break;
      }
      case '@pacientes/PACIENTE_REQUEST': {
        draft.loading = true;
        draft.paciente = INITIAL_STATE.paciente;
        break;
      }
      case '@pacientes/PACIENTE_REQUEST_SUCCESS': {
        draft.paciente.id = action.payload.id;
        draft.paciente.responsavel_id = action.payload.responsavel_id;
        draft.paciente.name = action.payload.name;
        draft.paciente.nascimento = action.payload.nascimento;
        draft.paciente.email = action.payload.email;
        draft.paciente.celular = action.payload.celular;
        draft.paciente.telefone = action.payload.telefone;
        draft.paciente.cep = action.payload.cep;
        draft.paciente.cidade = action.payload.cidade;
        draft.paciente.uf = action.payload.uf;
        draft.paciente.bairro = action.payload.bairro;
        draft.paciente.endereco = action.payload.endereco;
        draft.paciente.numero = action.payload.numero;
        draft.paciente.complemento = action.payload.complemento;
        draft.paciente.guardians = action.payload.guardians;
        draft.loading = false;
        break;
      }
      case '@pacientes/QUICK_STORE_SUCCESS': {
        draft.pacienteSelected.id = action.payload.id;
        draft.pacienteSelected.name = action.payload.name;
        draft.pacienteSelected.nascimento = action.payload.nascimento;
        draft.pacienteSelected.email = action.payload.email;
        draft.pacienteSelected.celular = action.payload.celular;
        draft.pacienteSelected.telefone = action.payload.telefone;
        draft.loading = false;
        break;
      }
      case '@agendamentos/STORE_REQUEST_SUCCESS': {
        draft.pacienteSelected.id = null;
        draft.pacienteSelected.name = null;
        draft.pacienteSelected.nascimento = null;
        draft.pacienteSelected.email = null;
        draft.pacienteSelected.celular = null;
        draft.pacienteSelected.telefone = null;
        break;
      }
      case '@guardians/GUARDIAN_STORE_SUCCESS': {
        draft.paciente.guardians.push(action.payload.data);
        break;
      }
      case '@pacientes/PACIENTE_FOUND': {
        draft.pacientesFound = action.payload.pacientesFound;
        draft.pacientePosted = action.payload.pacientePosted;
        draft.hasPaciente = true;
        draft.loading = false;
        break;
      }
      case '@pacientes/FECHA_MODAL_FOUND': {
        draft.hasPaciente = false;
        break;
      }
      case '@pacientes/PACIENTE_TOGLE_MODAL_ADD': {
        draft.addModal = action.payload.modal;
        draft.paciente.id = action.payload.paciente
          ? action.payload.paciente.id
          : null;
        draft.paciente.name = action.payload.paciente
          ? action.payload.paciente.name
          : null;
        break;
      }
      case '@pacientes/STORE_REQUEST':
      case '@pacientes/INDEX_REQUEST':
      case '@pacientes/UPDATE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@pacientes/FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
