import { takeLatest, all, call, put } from 'redux-saga/effects';
import moment from 'moment';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import { dateFormat } from '~/helpers/transform';

import {
  guardianStoreSuccess,
  guardianRequestSuccess,
  togleFechaModalEdit,
  togleGuardianFound,
  guardianFound,
  failure,
} from './actions';
import { pacienteRequest } from '../pacientes/actions';

export function* storeRequest({ payload }) {
  try {
    const {
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      pacienteId,
      forceAdd,
    } = payload;

    const tratNacimento = nascimento
      ? dateFormat(nascimento, ['DD-MM-YYYY'], 'YYYY-MM-DD')
      : null;

    const response = yield call(api.post, 'terapeutas/guardians', {
      name,
      nascimento: tratNacimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      pacienteId,
      forceAdd,
    });

    const { hasPaciente, data: pacientesExistentes } = response.data;
    if (hasPaciente) {
      yield put(guardianFound(pacientesExistentes, payload));
    } else {
      yield put(guardianStoreSuccess(response.data));
      yield put(togleGuardianFound(false));
      toast.success('Responsável cadastrado com sucesso.');
    }
  } catch (err) {
    toast.error('Algo deu errado');
    yield put(failure());
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
    } = payload;

    const tratNacimento = nascimento
      ? dateFormat(nascimento, ['DD-MM-YYYY'], 'YYYY-MM-DD')
      : null;

    const response = yield call(api.put, `terapeutas/guardians/${id}`, {
      name,
      email,
      nascimento: tratNacimento,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      celular,
      telefone,
      cep,
      cidade,
    });

    if (response) {
      yield put(togleFechaModalEdit(false));
      toast.success('Dados atualizados com sucesso.');
    }
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error('Algo deu errado');
    }

    yield put(failure());
  }
}

export function* guardianRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/guardians/${id}`);

    const {
      responsavel_id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      bairro,
      endereco,
      numero,
      complemento,
    } = response.data;

    if (!name) {
      yield put(failure());
      toast.error('Algo não deu certo.');
    }

    yield put(
      guardianRequestSuccess(
        id,
        responsavel_id,
        name,
        moment(nascimento).format('DD/MM/YYYY'),
        email,
        celular,
        telefone,
        cep,
        cidade,
        uf,
        bairro,
        endereco,
        numero,
        complemento,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* guardianAttach({ payload }) {
  try {
    const { pacienteId, guardian } = payload;

    yield call(api.post, 'terapeutas/paciente-guardian-attach', {
      guardianId: guardian.id,
      pacienteId,
    });

    yield put(guardianStoreSuccess(guardian));
    yield put(togleGuardianFound(false));
    toast.success('Responsável cadastrado com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id, pacienteId } = payload;

    yield call(api.delete, `terapeutas/guardians/${pacienteId}`, {
      data: {
        guardianId: id,
      },
    });
    yield put(pacienteRequest(pacienteId));

    toast.success('Responsável removido com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest('@guardians/DELETE_REQUEST', deleteRequest),
  takeLatest('@guardians/STORE_REQUEST', storeRequest),
  takeLatest('@guardians/UPDATE_REQUEST', updateRequest),
  takeLatest('@guardians/GUARDIAN_REQUEST', guardianRequest),
  takeLatest('@guardians/GUARDIAN_ATTACH', guardianAttach),
]);
