import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

class Calendar extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{' '}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://www.wanessaaraujo.com.br?ref=empodera-terapeuta-admin-footer"
                  >
                    Empodera Terapeuta
                  </a>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
