import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// reactstrap components
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default function Paginations({
  total,
  perPage,
  page,
  funcaoIndex,
  filter,
}) {
  const dispatch = useDispatch();
  const pages = [];
  const numPages = Math.ceil(total / perPage);

  for (let i = 2; i <= numPages; i += 1) {
    pages[i - 1] = i;
  }

  const setActive = useCallback((p) => {
    const elements = document.getElementsByClassName('page-item active');

    for (let i = 0; i < elements.length; i += 1) {
      elements[i].classList.remove('active');
    }

    document.getElementById(p).classList.toggle('active');
  }, []);

  useEffect(() => {
    setActive(page);
  }, [page, setActive]);

  function showMax() {
    const max = page * perPage;
    if (max > total) {
      return total;
    }
    return max;
  }

  const showingMin = 1 + page * perPage - perPage;
  const showingMax = showMax();

  function changePage(p) {
    dispatch(funcaoIndex(p, perPage, filter));

    setActive(page);
  }

  return (
    <>
      <div className="pagination-states">
        {`Mostrando ${showingMin} até ${showingMax} de ${total} registros`}
      </div>
      <nav aria-label="...">
        <Pagination
          className="pagination pagination-md justify-content-end"
          listClassName="pagination-md justify-content-end"
        >
          <PaginationItem id={1} key={`${1}paginationComp`} className="active">
            <PaginationLink
              href="#pablo"
              onClick={(e) => e.preventDefault(changePage(1))}
            >
              1
            </PaginationLink>
          </PaginationItem>
          {pages.map((p) => (
            <PaginationItem id={p} key={`${p}paginationComp`}>
              <PaginationLink onClick={(e) => e.preventDefault(changePage(p))}>
                {p}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </nav>
    </>
  );
}

Paginations.propTypes = {
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  funcaoIndex: PropTypes.func.isRequired,
  filter: PropTypes.objectOf(PropTypes.any),
};

Paginations.defaultProps = {
  filter: null,
};
