/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap';

import history from '../../../services/history';
import MainHeader from '../../../components/Headers/MainHeader';
import SearchHeader from './searchHeader';
import Paginations from '../../../components/Pagination/Paginations';
import loadingGlobal from '../../../components/loadingGlobal';

import { indexRequest } from '../../../store/modules/registrosCompartilhados/actions';

export default function RegistrosAtendimentos() {
  const [defaultFilters, setDefaultFilters] = useState({});
  const registrosLoadingData = useSelector(
    (state) => state.registrosCompartilhados.loading,
  );
  const registrosAtendimentos = useSelector(
    (state) => state.registrosCompartilhados.registrosCompartilhados.data,
  );
  const { total, perPage, page, filters } = useSelector(
    (state) => state.registrosCompartilhados.registrosCompartilhados,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    loadingGlobal(registrosLoadingData);
  }, [registrosLoadingData]);

  useEffect(() => {
    setDefaultFilters(
      history.location.state ? history.location.state.filters : {},
    );
  }, []);

  useEffect(() => {
    dispatch(indexRequest(1, 20, defaultFilters));
  }, [dispatch, defaultFilters]);
  return (
    <>
      <MainHeader
        name="Registros compartilhados"
        parentName="Registros"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6 border-0" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Filtros</h3>
              </Col>
            </Row>
          </CardHeader>
          <SearchHeader defaultFilters={defaultFilters} />
        </Card>
      </Container>
      <Container fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Registros por paciente</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Data</th>
                <th>Paciente</th>
                <th>Terapeuta</th>
                <th>Categoria de registro</th>
                <th>Título</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {registrosAtendimentos.map((data) => (
                <tr key={data.id}>
                  <td className="table-user">
                    <b>
                      {moment
                        .tz(data.created_at, moment.tz.guess())
                        .format('DD/MM/YYYY HH:mm:ss')}
                    </b>
                  </td>
                  <td>
                    <span className="text-muted">{data.paciente.name}</span>
                  </td>
                  <td>
                    <span className="text-muted">{data.terapeuta.name}</span>
                  </td>
                  <td>
                    <span className="text-muted">
                      {data.prontuarioCategoria.name}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted">{data.title}</span>
                  </td>
                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/registro-compartilhado/visualizar/${data.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                            filters,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-folder-open" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginations
            page={page}
            total={total}
            perPage={perPage}
            funcaoIndex={indexRequest}
          />
        </Card>
      </Container>
    </>
  );
}
