import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  failure,
  updateRequestSuccess,
  UpdatePasswordRequestSuccess,
  showRequestSuccess,
} from './actions';

import { togleFechaModalAdd } from '../modal/actions';

import api from '../../../services/api';

export function* showRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `/terapeuta/${id}`);

    if (response.status === 200) {
      yield put(showRequestSuccess(response.data));
    }
  } catch (err) {
    yield put(failure());
    toast.error('Ooops, não foi possível carregar seus dados.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      name,
      celular,
      telefone,
      nascimento,
      cep,
      uf,
      cidade,
      bairro,
      endereco,
      numero,
      complemento,
    } = payload;

    const response = yield call(api.put, `/terapeutas/${id}`, {
      name,
      celular,
      telefone,
      nascimento,
      cep,
      uf,
      cidade,
      bairro,
      endereco,
      numero,
      complemento,
    });

    if (response.status === 200) {
      toast.success('Dados atualizados com sucesso!');
    }

    /* Mandar o perfil atualizado atravéz do response e alterar o estado dentro do reducer */
    yield put(updateRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Ooops, não foi possível atualizar seus dados.');
  }
}

export function* updatePasswordRequest({ payload }) {
  try {
    const {
      id,
      oldPassword,
      newPassword,
      passwordConfirmation,
      modal,
    } = payload;

    const response = yield call(api.put, `/terapeutas/update-password/${id}`, {
      oldPassword,
      password: newPassword,
      password_confirmation: passwordConfirmation,
    });

    if (response.status === 200) {
      toast.success(response.data);
    }

    yield put(UpdatePasswordRequestSuccess());
    yield put(togleFechaModalAdd(!modal));
  } catch (err) {
    yield put(failure());
    toast.error('Ooops, não foi possível atualizar sua senha.');
  }
}

export default all([
  takeLatest('@terapeuta/UPDATE_REQUEST', updateRequest),
  takeLatest('@terapeuta/UPDATE_PASSWORD_REQUEST', updatePasswordRequest),
  takeLatest('@terapeuta/SHOW_REQUEST', showRequest),
]);
