import React, { useRef } from 'react';
import moment from 'moment';
import {
  FormGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import MainHeader from '~/components/Headers/MainHeader';

import Input, { InputMasked, BuscaEndereco } from '~/components/Input';
import PacientesExistentes from './pacientesExistentes';
import { storeRequest } from '~/store/modules/pacientes/actions';

export default function PacienteStore() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        nascimento: Yup.string()
          .matches(
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            'Data inválida',
          )
          .required('Data de nascimento é obrigatória'),
        email: Yup.string().when('nascimento', {
          is: (nascimento) =>
            moment().diff(moment(nascimento, ['DD-MM-YYYY']), 'years', false) >=
            18,
          then: Yup.string()
            .email('Insira um e-mail valido')
            .required('E-mail é obrigatório'),
          otherwise: Yup.string(),
        }),
        cep: Yup.string(),
        uf: Yup.string(),
        cidade: Yup.string(),
        endereco: Yup.string(),
        numero: Yup.string(),
        bairro: Yup.string(),
        complemento: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      const {
        name,
        nascimento,
        email,
        celular,
        telefone,
        cep,
        cidade,
        uf,
        endereco,
        numero,
        complemento,
        bairro,
      } = data;
      dispatch(
        storeRequest(
          name,
          nascimento,
          email,
          celular,
          telefone,
          cep,
          cidade,
          uf,
          endereco,
          numero,
          complemento,
          bairro,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }
  return (
    <>
      <PacientesExistentes />
      <MainHeader
        name="Novo Cliente"
        parentName="Clientes"
        parentPath="/terapeuta/pacientes"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Novo Cliente</h3>
                </CardHeader>
                <CardBody>
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col className="mb-0" md="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Nome
                            </label>
                            <Input
                              name="name"
                              id="input-name"
                              placeholder="Nome completo"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-nascimento"
                            >
                              Data de nasicmento
                            </label>
                            <InputMasked
                              name="nascimento"
                              className="form-control"
                              placeholder="Nascimento"
                              kind="custom"
                              options={{
                                mask: '99/99/9999',
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              E-mail
                            </label>
                            <Input
                              name="email"
                              id="input-email"
                              placeholder="E-mail"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-celular"
                            >
                              Celular
                            </label>
                            <InputMasked
                              name="celular"
                              className="form-control"
                              placeholder="Fone ex: (99) 99999-9999"
                              kind="cel-phone"
                              options={{
                                maskType: 'BRL',
                                withDDD: true,
                                dddMask: '(99) ',
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-telefone"
                            >
                              Telefone
                            </label>
                            <InputMasked
                              name="telefone"
                              className="form-control"
                              placeholder="Fone ex: (99) 99999-9999"
                              kind="cel-phone"
                              options={{
                                maskType: 'BRL',
                                withDDD: true,
                                dddMask: '(99) ',
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-0" md="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cep"
                            >
                              Cep
                            </label>
                            <BuscaEndereco
                              name="cep"
                              formRef={formRef}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-0" md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-endereco"
                            >
                              Endereço
                            </label>
                            <Input
                              name="endereco"
                              id="input-endereco"
                              placeholder="Endereço"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Numero
                            </label>
                            <Input
                              name="numero"
                              id="input-numero"
                              placeholder="Número"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-complemento"
                            >
                              Complemento
                            </label>
                            <Input
                              name="complemento"
                              id="input-complemento"
                              placeholder="Complemento"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Bairro
                            </label>
                            <Input
                              name="bairro"
                              id="input-bairro"
                              placeholder="Bairro"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cidade"
                            >
                              Cidade
                            </label>
                            <Input
                              name="cidade"
                              id="input-cidade"
                              placeholder="Cidade"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="mb-0" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-uf"
                            >
                              UF
                            </label>
                            <Input
                              name="uf"
                              id="input-uf"
                              placeholder="Estado"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">
                        Salvar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
