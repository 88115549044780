import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { indexRequestSuccess, compartilhamentoRequestSuccess, failure, compartilhamentoRequest as reloadCompartilhamento } from './actions';

export function* selectRequest() {
  try {
    const response = yield call(api.get, 'terapeutas/categorias-compartilhamento');
    yield put(indexRequestSuccess(response.data.length, 1, 1, 1, response.data));

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export function* compartilhamentoRequest({ payload }) {
  try {
    const { registro_id } = payload;

    const response = yield call(api.get, `terapeutas/registro-compartilhamento/${registro_id}`);
    const registro = response.data;
    yield put(compartilhamentoRequestSuccess(registro.id, registro.compartilhamento_id, registro.registrosCompartilhadosTerapeutas, registro.inviteCompartilhamentoRegistro));

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export function* compartilhamentoUpdateRequest({ payload }) {
  try {
    const { registro_id, compartilhamento_id } = payload;

    yield call(api.put, `terapeutas/registro-compartilhamento/${registro_id}`, { compartilhamento_id });

    yield put(reloadCompartilhamento(registro_id));
    toast.success("Tipo de compartilhamento alterado com sucesso.");

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export function* compartilhamentoDeleteRequest({ payload }) {
  try {
    const { registro_id, terapeuta_id } = payload;

    yield call(api.delete, `terapeutas/registro-compartilhamento/${registro_id}`, { data: { terapeuta_id } });

    yield put(reloadCompartilhamento(registro_id));
    toast.success("Compartilhamento removido.");

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export function* inviteRequest({ payload }) {
  try {
    const { registro_id, email } = payload;

    yield call(api.post, 'terapeutas/invite-compartilhamento', { registro_id, email });

    yield put(reloadCompartilhamento(registro_id));
    toast.success("Enviado convite para visualizar registro.");

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export function* inviteDeleteRequest({ payload }) {
  try {
    const { registro_id, invite_id } = payload;

    yield call(api.delete, `terapeutas/invite-compartilhamento/${invite_id}`);

    yield put(reloadCompartilhamento(registro_id));
    toast.success("Convite removido.");

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export default all([
  takeLatest('@compartilhamentos/SELECT_REQUEST', selectRequest),
  takeLatest('@compartilhamentos/COMPARTILHAMENTO_REQUEST', compartilhamentoRequest),
  takeLatest('@compartilhamentos/COMPARTILHAMENTO_UPDATE_REQUEST', compartilhamentoUpdateRequest),
  takeLatest('@compartilhamentos/COMPARTILHAMENTO_DELETE_REQUEST', compartilhamentoDeleteRequest),
  takeLatest('@compartilhamentos/INVITE_DELETE_REQUEST', inviteDeleteRequest),
  takeLatest('@compartilhamentos/INVITE_REQUEST', inviteRequest)
]);
