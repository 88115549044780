export function indexRequest(page, perPage) {
  return {
    type: '@financeiroContas/INDEX_REQUEST',
    payload: { page, perPage },
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroContas/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function storeRequest(
  name,
  saldo_inicial,
  data_saldo_inicial,
  conta_tipo_id,
  institution_id,
) {
  return {
    type: '@financeiroContas/STORE_REQUEST',
    payload: {
      name,
      saldo_inicial,
      data_saldo_inicial,
      conta_tipo_id,
      institution_id,
    },
  };
}

export function updateRequest(id, name) {
  return {
    type: '@financeiroContas/UPDATE_REQUEST',
    payload: {
      id,
      name,
    },
  };
}

export function deleteRequest(id) {
  return {
    type: '@financeiroContas/DELETE_REQUEST',
    payload: {
      id,
    },
  };
}

export function togleFechaModalAdd(modal) {
  return {
    type: '@financeiroContas/FECHA_ADD_MODAL',
    payload: { modal },
  };
}

export function contaRequest(id) {
  return {
    type: '@financeiroContas/CONTA_REQUEST',
    payload: { id },
  };
}

export function contaRequestSuccess(data) {
  return {
    type: '@financeiroContas/CONTA_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function selectRequest() {
  return {
    type: '@financeiroContas/SELECT_REQUEST',
  };
}

export function selectRequestSuccess(data) {
  return {
    type: '@financeiroContas/SELECT_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function extratoRequest(id) {
  return {
    type: '@financeiroContas/EXTRATO_REQUEST',
    payload: { id },
  };
}

export function extratoRequestSuccess(data) {
  return {
    type: '@financeiroContas/EXTRATO_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function failure() {
  return {
    type: '@financeiroContas/FAILURE',
  };
}
