import { takeLatest, all, call, put } from 'redux-saga/effects';
import { failure } from './actions';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

export function* newPasswordRequest({payload}) {
  try {
    const { token, password, password_confirmation } = payload;
    const response = yield call(api.put, '/terapeuta-forgot-password', {
      token,
      password,
      password_confirmation
    });

    if(response.status === 204){
      toast.success("Senha recuperada com sucesso, você já pode realizar login na plataforma novamente!");
      history.push('/terapeuta-login');
    }else{
      toast.error("Falha ao registrar a nova senha");
    }

  } catch (err) {
    yield put(failure());
    toast.error("Falha ao registrar a nova senha");
  }
}

export default all([
  takeLatest('@newPassword/STORE_REQUEST', newPasswordRequest)
]);
