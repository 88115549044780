export function selectRequest() {
  return {
    type: '@financeiroMetodos/SELECT_REQUEST',
  };
}

export function selectRequestSuccess(data) {
  return {
    type: '@financeiroMetodos/SELECT_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function failure() {
  return {
    type: '@financeiroMetodos/FAILURE',
  };
}
