/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable global-require */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import RegistroFinanceiro from '../Registros/store';
import LoadingCard from '~/components/LoadingCard';
import history from '~/services/history';

import MainHeader from '~/components/Headers/MainHeader';
import {
  dateFormat,
  transformMoeda,
  situacaoAtual,
  exibeVariacao,
} from '~/helpers/transform';
import {
  proximosReceberRequest,
  proximosPagarRequest,
  ultimosRequest,
  bancosRequest,
  dashboardRequest,
} from '~/store/modules/financeiroDashboard/actions';

import {
  togleFechaModalRegistro,
  deleteRequest,
  showRequest,
  quickUpdateRequest,
} from '~/store/modules/financeiroRegistros/actions';

import { MyResponsiveLine } from './grafico';

function Dashboard() {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null);
  const financeiroModal = useSelector(
    (state) => state.financeiroRegistros.financeiroModal,
  );
  const saldos = useSelector(
    (state) => state.financeiroDashboard.resumes.saldos,
  );

  const proximosReceberLoading = useSelector(
    (state) => state.financeiroDashboard.proximosReceberLoading,
  );
  const proximosReceber = useSelector(
    (state) => state.financeiroDashboard.proximosReceber,
  );

  const proximosPagarLoading = useSelector(
    (state) => state.financeiroDashboard.proximosPagarLoading,
  );
  const proximosPagar = useSelector(
    (state) => state.financeiroDashboard.proximosPagar,
  );

  const ultimosLoading = useSelector(
    (state) => state.financeiroDashboard.ultimosLoading,
  );
  const ultimos = useSelector((state) => state.financeiroDashboard.ultimos);

  const bancosLoading = useSelector(
    (state) => state.financeiroDashboard.proximosPagarLoading,
  );
  const bancos = useSelector((state) => state.financeiroDashboard.bancos);

  const resumes = useSelector((state) => state.financeiroDashboard.resumes);
  const resumesLoading = useSelector(
    (state) => state.financeiroDashboard.resumesLoading,
  );

  useEffect(() => {
    dispatch(proximosReceberRequest(1, 5, null));
    dispatch(proximosPagarRequest(1, 5, null));
    dispatch(bancosRequest(1, 20));
    dispatch(ultimosRequest(1, 7));
    dispatch(dashboardRequest());
  }, [dispatch]);

  const handlerEdit = useCallback(
    (id) => {
      dispatch(showRequest(id));
    },
    [dispatch],
  );

  const togleModal = useCallback(
    (value) => {
      dispatch(togleFechaModalRegistro(value, 1));
    },
    [dispatch],
  );

  const quickPay = useCallback(
    (id, atualSituation, page, perPage, funcCallback) => {
      const situation = Number(atualSituation) === 1 ? 3 : 1;
      dispatch(
        quickUpdateRequest(id, situation, page, perPage, null, funcCallback),
      );
    },
    [dispatch],
  );

  const deleteAgendamento = useCallback(
    (id) => {
      dispatch(deleteRequest(id));
      setAlert(false);
    },
    [dispatch],
  );

  const deleteEventSweetAlert = useCallback(
    (id) => {
      setAlert(() => {
        return (
          <ReactBSAlert
            warning
            style={{ display: 'block' }}
            title="Você tem certeza?"
            onConfirm={() => {
              setAlert(false);
            }}
            onCancel={() => deleteAgendamento(id)}
            confirmBtnCssClass="btn-secondary"
            cancelBtnBsStyle="danger"
            confirmBtnText="Cancelar"
            cancelBtnText="Sim, delete isso"
            showCancel
            btnSize=""
          >
            Essa exclusão não poderá ser desfeita, tem certeza?
          </ReactBSAlert>
        );
      });
    },
    [deleteAgendamento],
  );

  return (
    <>
      {alert}
      <MainHeader
        name="Financeiro"
        parentName="Financeiro - Visão Geral"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6 border-0" fluid>
        <Row>
          <Col className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <LoadingCard show={resumesLoading} />
              <div className="card-body">
                <Row>
                  <Col>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Saldo Total
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {transformMoeda(resumes.saldoMes, 2)}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                  </Col>
                </Row>
                {exibeVariacao(resumes.variacaoSaldo)}
              </div>
            </div>
          </Col>

          <div className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <LoadingCard show={resumesLoading} />
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Recebimentos
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {transformMoeda(resumes.entradasMes, 2)}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </div>
                </div>
                {exibeVariacao(resumes.variacaoEntradasMes)}
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <LoadingCard show={resumesLoading} />
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Pagamentos
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {transformMoeda(resumes.gastosMes, 2)}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </div>
                </div>
                {exibeVariacao(resumes.variacaoGastosMes)}
              </div>
            </div>
          </div>
        </Row>

        <div className="row">
          <div className="col-xl-4">
            <div className="card">
              <LoadingCard show={bancosLoading} />
              <div className="card-header">
                <h5 className="h3 mb-0">Saldo de cada conta:</h5>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush list my--3">
                  {bancos.data.map((banco) => (
                    <li key={banco.id} className="list-group-item px-0">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <a
                            href="trocar.html"
                            className="avatar rounded-circle"
                          >
                            <img
                              alt="placeholder"
                              src={require(`assets/img/bank-logos/${banco.institution.img_name}.svg`)}
                            />
                          </a>
                        </div>
                        <div className="col">
                          <h5>
                            {`${banco.name} : ${transformMoeda(
                              banco.saldo_atual,
                              2,
                            )}`}
                          </h5>
                          <div className="progress progress-xs mb-0">
                            <div
                              className="progress-bar bg-orange"
                              role="progressbar"
                              aria-valuenow="60"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: '60%' }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-xl-8">
            <div className="card">
              <LoadingCard show={proximosReceberLoading} />
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Próximos valores a receber</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="btn btn-sm btn-primary btn-ver-mais"
                      color="primary"
                      onClick={() => togleModal(!financeiroModal)}
                      size="sm"
                    >
                      <span className="btn-inner--text">
                        + Novo recebimento
                      </span>
                    </Button>
                  </div>
                  <br clear="all" />
                </div>
              </div>

              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Data</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Situação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {proximosReceber.data.map((data) => (
                      <tr key={data.id}>
                        <th className="table-user" scope="row">
                          <b>
                            {dateFormat(
                              data.data_vencimento,
                              ['YYYY-MM-DD'],
                              'DD/MM/YYYY',
                            )}
                          </b>
                        </th>

                        <td>
                          <span className="text-muted">{data.descricao}</span>
                        </td>
                        <td>
                          <span
                            className={
                              Number(data.tipo) === 1 ? 'positivo' : 'negativo'
                            }
                          >
                            {transformMoeda(data.valor_base, 2)}
                          </span>
                        </td>
                        <td>{situacaoAtual(data.financeiro_situation_id)}</td>
                        <td>
                          <UncontrolledDropdown group>
                            <DropdownToggle
                              caret
                              color="secondary"
                              className="table-action"
                            >
                              <i
                                className="fas fa-ellipsis-v"
                                color="secondary"
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => handlerEdit(data.id)}
                              >
                                Editar
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  quickPay(
                                    data.id,
                                    data.financeiro_situation_id,
                                    1,
                                    5,
                                    proximosReceberRequest,
                                  );
                                }}
                              >
                                {Number(data.financeiro_situation_id) === 3
                                  ? 'Voltar para em aberto'
                                  : 'Pagar'}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => deleteEventSweetAlert(data.id)}
                              >
                                Deletar
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ minHeight: '420px' }}>
          <div className="col-xl-4">
            <div className="card-header">
              <h6 className="surtitle">Patrimônio</h6>
              <h5 className="h3 mb-0">Evolução Mensal</h5>
            </div>
            <div
              className="card-body"
              style={{ height: '300px', backgroundColor: '#fff' }}
            >
              <MyResponsiveLine data={saldos} />
            </div>
          </div>

          <div className="col-xl-8">
            <div className="card">
              <LoadingCard show={proximosPagarLoading} />
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Próximas contas a pagar</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="btn btn-sm btn-primary btn-ver-mais"
                      color="primary"
                      onClick={() => togleModal(!financeiroModal)}
                      size="sm"
                    >
                      <span className="btn-inner--text">+ Novo pagamento</span>
                    </Button>
                  </div>
                  <br clear="all" />
                </div>
              </div>

              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Data</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Situação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {proximosPagar.data.map((data) => (
                      <tr key={data.id}>
                        <th className="table-user" scope="row">
                          <b>
                            {dateFormat(
                              data.data_vencimento,
                              ['YYYY-MM-DD'],
                              'DD/MM/YYYY',
                            )}
                          </b>
                        </th>

                        <td>
                          <span className="text-muted">{data.descricao}</span>
                        </td>
                        <td>
                          <span
                            className={
                              Number(data.tipo) === 1 ? 'positivo' : 'negativo'
                            }
                          >
                            {transformMoeda(data.valor_base, 2)}
                          </span>
                        </td>
                        <td>{situacaoAtual(data.financeiro_situation_id)}</td>
                        <td>
                          <UncontrolledDropdown group>
                            <DropdownToggle
                              caret
                              color="secondary"
                              className="table-action"
                            >
                              <i
                                className="fas fa-ellipsis-v"
                                color="secondary"
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => handlerEdit(data.id)}
                              >
                                Editar
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  quickPay(
                                    data.id,
                                    data.financeiro_situation_id,
                                    1,
                                    5,
                                    proximosPagarRequest,
                                  );
                                }}
                              >
                                {Number(data.financeiro_situation_id) === 3
                                  ? 'Voltar para em aberto'
                                  : 'Pagar'}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => deleteEventSweetAlert(data.id)}
                              >
                                Deletar
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ minHeight: '420px' }}>
          <div className="col-xl-12">
            <div className="card">
              <LoadingCard show={ultimosLoading} />
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Ultimas Movimentações</h3>
                    <br clear="all" />
                  </div>
                  <div className="col text-right">
                    <a
                      href="#verExtrato"
                      className="btn btn-sm btn-primary btn-ver-mais"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push('/terapeuta/financeiro/movimentacoes');
                      }}
                    >
                      Ver o extrato completo
                    </a>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Criação</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Situação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ultimos.data.map((data) => (
                      <tr key={data.id}>
                        <th className="table-user" scope="row">
                          <b>
                            {dateFormat(
                              data.created_at,
                              ['YYYY-MM-DD'],
                              'DD/MM/YYYY',
                            )}
                          </b>
                        </th>
                        <td>
                          <span className="text-muted">
                            {data.financeiroCategoria &&
                              data.financeiroCategoria.name
                              ? data.financeiroCategoria.name
                              : 'Fatura cartão'}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">{data.descricao}</span>
                        </td>
                        <td>
                          <span
                            className={
                              Number(data.tipo) === 1 ? 'positivo' : 'negativo'
                            }
                          >
                            {transformMoeda(data.valor_base, 2)}
                          </span>
                        </td>
                        <td>{situacaoAtual(data.financeiro_situation_id)}</td>
                        <td>
                          <UncontrolledDropdown group>
                            <DropdownToggle
                              caret
                              color="secondary"
                              className="table-action"
                            >
                              <i
                                className="fas fa-ellipsis-v"
                                color="secondary"
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => handlerEdit(data.id)}
                              >
                                Editar
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  quickPay(
                                    data.id,
                                    data.financeiro_situation_id,
                                    1,
                                    7,
                                    ultimosRequest,
                                  );
                                }}
                              >
                                {Number(data.financeiro_situation_id) === 3
                                  ? 'Voltar para em aberto'
                                  : 'Pagar'}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => deleteEventSweetAlert(data.id)}
                              >
                                Deletar
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <RegistroFinanceiro fromDashboard />
    </>
  );
}

export default Dashboard;
