import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  financeiroCard: {
    id: null,
    name: null,
    limite_total: null,
    limite_disponivel: null,
    dia_vencimento: null,
  },
  financeiroCards: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  faturas: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  fatura: {},
  addModal: false,
};

export default function financeiroCards(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroCards/INDEX_REQUEST_SUCCESS': {
        draft.financeiroCards.total = action.payload.total;
        draft.financeiroCards.perPage = action.payload.perPage;
        draft.financeiroCards.page = action.payload.page;
        draft.financeiroCards.lastPage = action.payload.lastPage;
        draft.financeiroCards.data = action.payload.data;
        draft.loading = false;

        break;
      }
      case '@financeiroCards/CARD_REQUEST_SUCCESS': {
        draft.financeiroCard.id = action.payload.data.id;
        draft.financeiroCard.name = action.payload.data.name;
        draft.financeiroCard.limite_total = action.payload.data.limite_total;
        draft.financeiroCard.limite_disponivel =
          action.payload.data.limite_disponivel;
        draft.financeiroCard.dia_vencimento =
          action.payload.data.dia_vencimento;
        draft.loading = false;
        break;
      }
      case '@financeiroCards/FATURAS_REQUEST_SUCCESS': {
        draft.faturas.data = action.payload.data.data;
        draft.faturas.total = action.payload.data.total;
        draft.faturas.perPage = action.payload.data.perPage;
        draft.faturas.page = action.payload.data.page;
        draft.faturas.lastPage = action.payload.data.lastPage;
        draft.loading = false;
        break;
      }
      case '@financeiroCards/FATURA_REQUEST_SUCCESS': {
        draft.fatura = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@financeiroCards/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        break;
      }
      case '@financeiroCards/INDEX_REQUEST':
      case '@financeiroCards/DELETE_REQUEST':
      case '@financeiroCards/CARD_REQUEST':
      case '@financeiroCards/FATURAS_REQUEST':
      case '@financeiroCards/FATURA_REQUEST':
      case '@financeiroCards/STORE_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
