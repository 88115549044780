import React, { useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';

// reactstrap components
import Ckeditor from '../../../components/Ckeditor';
import MainHeader from '../../../components/Headers/MainHeader';
import Input, { Select } from '../../../components/Input';
import { storeRequest } from '../../../store/modules/registrosAtendimentos/actions';
import { selectRequest as selectRequestPacientes } from '../../../store/modules/pacientes/actions';
import { selectRequest as selectRequestCategoriasRegistro } from '../../../store/modules/categoriasRegistro/actions';
import Upload from '../../../components/Upload';

export default function Validation() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const pacientes = useSelector((state) => state.pacientes.pacientes.data);
  const categoriasRegistros = useSelector(
    (state) => state.categoriasRegistro.categoriasRegistro.data,
  );

  useEffect(() => {
    dispatch(selectRequestPacientes());
    dispatch(selectRequestCategoriasRegistro());
  }, [dispatch]);

  async function handleCadastro(data) {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('Titulo é obrigatório'),
        paciente_id: Yup.number().required('Selecione um cliente'),
        prontuario_categoria_id: Yup.number().required(
          'Selecione uma categoria',
        ),
        conteudo: Yup.string().required('Conteúdo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        paciente_id,
        title,
        prontuario_categoria_id,
        conteudo,
        files,
      } = data;
      dispatch(
        storeRequest(
          paciente_id,
          prontuario_categoria_id,
          title,
          conteudo,
          files,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <MainHeader
        name="Novo Registro"
        parentName="Registros de atendimento"
        parentPath="/terapeuta/registros-atendimentos"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Novo Registro</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    ref={formRef}
                    onSubmit={handleCadastro}
                    noValidate
                  >
                    <Row>
                      <Col lg="8">
                        <label
                          className="form-control-label"
                          htmlFor="input-name"
                        >
                          Cliente
                        </label>
                        <Select
                          name="paciente_id"
                          className="form-control"
                          options={{
                            placeholder: 'Selecione um cliente',
                          }}
                          data={pacientes}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="8">
                        <label
                          className="form-control-label"
                          htmlFor="input-name"
                        >
                          Categoria de Registro
                        </label>
                        <Select
                          name="prontuario_categoria_id"
                          className="form-control"
                          options={{
                            placeholder: 'Selecione uma categoria de registro',
                          }}
                          data={categoriasRegistros}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="8">
                        <label
                          className="form-control-label"
                          htmlFor="input-titulo"
                        >
                          Titulo
                        </label>
                        <Input
                          name="title"
                          id="input-titulo"
                          placeholder="Titulo"
                          type="text"
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="12">
                        <label className="form-control-label">Conteudo</label>
                        <Ckeditor name="conteudo" />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <Upload />
                      </Col>
                    </Row>
                    <br />
                    <Button color="primary" type="submit">
                      Cadastrar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
