import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  email: '',
  terapeutaTipos: [
    {
      id: null,
      name: 'Carregando...',
    },
  ],
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/TERAPEUTA_TIPO_SUCCESS': {
        draft.terapeutaTipos = action.payload.tipos;
        draft.loading = false;
        break;
      }
      case '@auth/TERAPEUTA_TIPO_REQUEST':
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.email = action.payload.email;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        break;
      }
      default:
    }
  });
}
