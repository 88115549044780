export function storeRequest(
  name,
  nascimento,
  email,
  celular,
  telefone,
  cep,
  cidade,
  uf,
  endereco,
  numero,
  complemento,
  bairro,
  forceAdd,
) {
  return {
    type: '@pacientes/STORE_REQUEST',
    payload: {
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      forceAdd,
    },
  };
}

export function quickStoreRequest(name, nascimento, email, celular, telefone) {
  return {
    type: '@pacientes/QUICK_STORE_REQUEST',
    payload: {
      name,
      nascimento,
      email,
      celular,
      telefone,
    },
  };
}

export function quickStoreRequestSuccess(
  id,
  name,
  nascimento,
  email,
  celular,
  telefone,
) {
  return {
    type: '@pacientes/QUICK_STORE_SUCCESS',
    payload: {
      id,
      name,
      nascimento,
      email,
      celular,
      telefone,
    },
  };
}

export function updateRequest(
  id,
  name,
  nascimento,
  email,
  celular,
  telefone,
  cep,
  cidade,
  uf,
  endereco,
  numero,
  complemento,
  bairro,
  guardians,
) {
  return {
    type: '@pacientes/UPDATE_REQUEST',
    payload: {
      id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      guardians,
    },
  };
}

export function indexRequest(page, perPage, filters) {
  return {
    type: '@pacientes/INDEX_REQUEST',
    payload: { page, perPage, filters },
  };
}

export function selectRequest() {
  return {
    type: '@pacientes/SELECT_REQUEST',
  };
}

export function pacienteRequest(id) {
  return {
    type: '@pacientes/PACIENTE_REQUEST',
    payload: { id },
  };
}

export function indexRequestSuccess(
  total,
  perPage,
  page,
  lastPage,
  data,
  filters,
) {
  return {
    type: '@pacientes/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data, filters },
  };
}

export function pacienteRequestSuccess(
  id,
  responsavel_id,
  name,
  nascimento,
  email,
  celular,
  telefone,
  cep,
  cidade,
  uf,
  bairro,
  endereco,
  numero,
  complemento,
  guardians,
) {
  return {
    type: '@pacientes/PACIENTE_REQUEST_SUCCESS',
    payload: {
      id,
      responsavel_id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      bairro,
      endereco,
      numero,
      complemento,
      guardians,
    },
  };
}

export function pacienteFound(pacientesFound, pacientePosted) {
  return {
    type: '@pacientes/PACIENTE_FOUND',
    payload: { pacientesFound, pacientePosted },
  };
}

export function toglePacienteFound(modal) {
  return {
    type: '@pacientes/FECHA_MODAL_FOUND',
    payload: { modal },
  };
}

export function pacienteAttach(paciente, quickStore) {
  return {
    type: '@pacientes/PACIENTE_ATTACH',
    payload: { paciente, quickStore },
  };
}

export function togleModalAdd(modal, paciente) {
  return {
    type: '@pacientes/PACIENTE_TOGLE_MODAL_ADD',
    payload: { modal, paciente },
  };
}

export function deleteRequest(id, page, perPage, filters) {
  return {
    type: '@pacientes/DELETE_REQUEST',
    payload: {
      id,
      page,
      perPage,
      filters,
    },
  };
}

export function failure() {
  return {
    type: '@pacientes/FAILURE',
  };
}
