/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import MainHeader from '../../../components/Headers/MainHeader';
import RegistroComments from '../../../components/RegistroComments';
import { registroAtendimentoRequest } from '../../../store/modules/registrosCompartilhados/actions';
import loadingGlobal from '../../../components/loadingGlobal';

export default function RegistrosAtendimentosUpdate() {
  const registrosLoadingData = useSelector(
    (state) => state.registrosCompartilhados.loading,
  );
  const registroAtendimento = useSelector(
    (state) => state.registrosCompartilhados.registroAtendimento,
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    loadingGlobal(registrosLoadingData);
  }, [registrosLoadingData]);

  useEffect(() => {
    dispatch(registroAtendimentoRequest(id));
  }, [dispatch, id]);

  return (
    <>
      <MainHeader
        name="Registro Compartilhado"
        parentName="Registros Compartilhados comigo"
        parentPath="/terapeuta/registros-compartilhados"
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Dados do Registro</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label paciente-name"
                          htmlFor="input-name"
                        >
                          Paciente:
                        </label>
                        <span>{registroAtendimento.paciente_name}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8">
                      <label
                        className="form-control-label"
                        htmlFor="input-name"
                      >
                        Categoria de Registro:&nbsp;
                      </label>
                      <span>{registroAtendimento.prontuario_categoria_id}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="8">
                      <label
                        className="form-control-label"
                        htmlFor="input-titulo"
                      >
                        Titulo:&nbsp;
                      </label>
                      <span>{registroAtendimento.title}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12">
                      <label className="form-control-label">Conteudo</label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: registroAtendimento.conteudo,
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {id && <RegistroComments prontuario_id={id} />}
    </>
  );
}
