/* eslint-disable no-param-reassign */
import produce from 'immer';

const INITIAL_STATE = {
  proximosReceberLoading: false,
  proximosReceber: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  proximosPagarLoading: false,
  proximosPagar: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  ultimosLoading: false,
  ultimos: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  bancosLoading: false,
  bancos: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  resumesLoading: false,
  resumes: {
    saldos: [],
    saldoMes: null,
    variacaoSaldo: null,
    gastosMes: null,
    variacaoGastosMes: null,
    entradasMes: null,
    variacaoEntradasMes: null,
  },
};

export default function financeiroDashboard(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroDashboard/PROXIMOS_RECEBER_REQUEST': {
        draft.proximosReceberLoading = true;
        break;
      }
      case '@financeiroDashboard/PROXIMOS_RECEBER_SUCCESS': {
        draft.proximosReceber.total = action.payload.total;
        draft.proximosReceber.perPage = action.payload.perPage;
        draft.proximosReceber.page = action.payload.page;
        draft.proximosReceber.lastPage = action.payload.lastPage;
        draft.proximosReceber.data = action.payload.data;
        draft.proximosReceberLoading = false;
        break;
      }
      case '@financeiroDashboard/PROXIMOS_PAGAR_REQUEST': {
        draft.proximosPagarLoading = true;
        break;
      }
      case '@financeiroDashboard/PROXIMOS_PAGAR_SUCCESS': {
        draft.proximosPagar.total = action.payload.total;
        draft.proximosPagar.perPage = action.payload.perPage;
        draft.proximosPagar.page = action.payload.page;
        draft.proximosPagar.lastPage = action.payload.lastPage;
        draft.proximosPagar.data = action.payload.data;
        draft.proximosPagarLoading = false;
        break;
      }
      case '@financeiroDashboard/ULTIMOS_REQUEST': {
        draft.ultimosLoading = true;
        break;
      }
      case '@financeiroDashboard/ULTIMOS_SUCCESS': {
        draft.ultimos.total = action.payload.total;
        draft.ultimos.perPage = action.payload.perPage;
        draft.ultimos.page = action.payload.page;
        draft.ultimos.lastPage = action.payload.lastPage;
        draft.ultimos.data = action.payload.data;
        draft.ultimosLoading = false;
        break;
      }
      case '@financeiroDashboard/DASHBOARD_REQUEST': {
        draft.resumesLoading = true;
        break;
      }
      case '@financeiroDashboard/DASHBOARD_SUCCESS': {
        draft.resumes.saldos = action.payload.saldos;
        draft.resumes.saldoMes = action.payload.saldoMes;
        draft.resumes.variacaoSaldo = action.payload.variacaoSaldo;
        draft.resumes.gastosMes = action.payload.gastosMes;
        draft.resumes.variacaoGastosMes = action.payload.variacaoGastosMes;
        draft.resumes.entradasMes = action.payload.entradasMes;
        draft.resumes.variacaoEntradasMes = action.payload.variacaoEntradasMes;
        draft.resumesLoading = false;
        break;
      }
      case '@financeiroDashboard/BANCOS_REQUEST': {
        draft.bancosLoading = true;
        break;
      }
      case '@financeiroDashboard/BANCOS_SUCCESS': {
        draft.bancos.total = action.payload.total;
        draft.bancos.perPage = action.payload.perPage;
        draft.bancos.page = action.payload.page;
        draft.bancos.lastPage = action.payload.lastPage;
        draft.bancos.data = action.payload.data;
        draft.bancosLoading = false;
        break;
      }
      case '@financeiroDashboard/FAILURE': {
        draft.loading = false;

        switch (action.payload.tp) {
          case 'bancos': {
            draft.bancosLoading = false;
            break;
          }
          case 'resumes': {
            draft.resumesLoading = false;
            break;
          }
          case 'ultimos': {
            draft.ultimosLoading = false;
            break;
          }
          case 'proximosRec': {
            draft.proximosReceberLoading = false;
            break;
          }
          case 'proximosPag': {
            draft.proximosPagarLoading = false;
            break;
          }
          default:
        }
        break;
      }
      default:
    }
  });
}
