/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// reactstrap components
import { Button, Modal, Row, Col, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import loadingGlobal from '~/components/loadingGlobal';

// import * as Yup from 'yup';
import { Select, FileInput } from '~/components/Input';

import {
  importRequest,
  togleImportModal,
} from '~/store/modules/financeiroRegistros/actions';

import { selectRequest as selectRequestContas } from '~/store/modules/financeiroContas/actions';

export default function ImportRegistros() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const importModal = useSelector(
    (state) => state.financeiroRegistros.importModal,
  );
  const importLoading = useSelector(
    (state) => state.financeiroRegistros.importLoading,
  );
  const selectContas = useSelector(
    (state) => state.financeiroContas.selectContas,
  );

  function togleModal(value) {
    dispatch(togleImportModal(value));
  }

  useEffect(() => {
    dispatch(selectRequestContas());
  }, [dispatch]);

  useEffect(() => {
    loadingGlobal(importLoading);
  }, [importLoading]);

  async function handleCadastro(data) {
    try {
      const { conta_id, upload } = data;

      const schema = Yup.object().shape({
        conta_id: Yup.string().required('Selecione uma conta'),
        upload: Yup.string().required('Selecione um arquivo'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(importRequest(conta_id, upload));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={importModal}
        toggle={() => togleModal(!importModal)}
      >
        <Form ref={formRef} onSubmit={handleCadastro}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Importação por planilha
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!importModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <p>
                  Você pode fazer a importação de seus registros através de um
                  modelo padrão de planilha, os registros tem que seguir o mesmo
                  padrão da planilha. O sistema irá procurar se a categoria já
                  existe e caso não exista criará uma nova. Obs.: Somente é
                  aceito arquivo .xlsx
                  <a
                    href="https://www.wanessaaraujo.com.br/wp-content/uploads/2020/08/planilhamodelo.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Baixar planilha modelo
                  </a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="financeiroContas"
                  >
                    Conta
                  </label>
                  <Select
                    id="financeiroContas"
                    name="conta_id"
                    className="form-control"
                    options={{
                      placeholder: 'Selecione uma conta',
                    }}
                    data={selectContas}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="upload">
                    Categoria
                  </label>
                  <FileInput
                    name="upload"
                    id="upload"
                    className="form-control"
                    placeholder="Descrição do registro"
                    type="file"
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!importModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Importar dados
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
