import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { indexRequestSuccess, failure } from './actions';

export function* indexRequest() {
  try {
    const response = yield call(api.get, 'financeiro-contas-tipos');

    yield put(indexRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest('@financeiroContasTipos/INDEX_REQUEST', indexRequest),
]);
