import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  addModal: false,
  editModal: false,
  hasGuardian: false,
  guardiansFound: [],
  guardianPosted: null,
  guardian: {
    id: null,
    responsavel_id: null,
    name: null,
    nascimento: null,
    email: null,
    celular: null,
    telefone: null,
    cep: null,
    cidade: null,
    uf: null,
    bairro: null,
    endereco: null,
    numero: null,
    complemento: null,
  },
};

export default function guardians(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@guardians/GUARDIAN_REQUEST_SUCCESS': {
        draft.guardian.id = action.payload.id;
        draft.guardian.responsavel_id = action.payload.responsavel_id;
        draft.guardian.name = action.payload.name;
        draft.guardian.nascimento = action.payload.nascimento;
        draft.guardian.email = action.payload.email;
        draft.guardian.celular = action.payload.celular;
        draft.guardian.telefone = action.payload.telefone;
        draft.guardian.cep = action.payload.cep;
        draft.guardian.cidade = action.payload.cidade;
        draft.guardian.uf = action.payload.uf;
        draft.guardian.bairro = action.payload.bairro;
        draft.guardian.endereco = action.payload.endereco;
        draft.guardian.numero = action.payload.numero;
        draft.guardian.complemento = action.payload.complemento;
        draft.loading = false;
        draft.editModal = true;
        break;
      }
      case '@guardians/GUARDIAN_STORE_SUCCESS': {
        draft.loading = false;
        draft.addModal = false;
        break;
      }
      case '@guardians/GUARDIAN_FOUND': {
        draft.guardiansFound = action.payload.guardiansFound;
        draft.guardianPosted = action.payload.guardianPosted;
        draft.hasGuardian = true;
        break;
      }
      case '@guardians/FECHA_MODAL_FOUND': {
        draft.hasGuardian = false;
        break;
      }
      case '@guardians/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        break;
      }
      case '@guardians/FECHA_EDIT_MODAL': {
        draft.editModal = action.payload.modal;
        break;
      }
      case '@guardians/GUARDIAN_REQUEST':
      case '@guardians/STORE_REQUEST':
      case '@guardians/UPDATE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@guardians/FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
