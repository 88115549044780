import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { failure } from './actions';

import api from '../../../services/api';
import history from '../../../services/history';

export function* recoverPassword({ payload }) {
  try {
    const { email } = payload;
    const response = yield call(api.post, '/terapeuta-forgot-password', {
      email,
    });

    if (response.status === 204) {
      toast.success(
        'Email de recuperação enviado com sucesso, verifique sua caixa de entrada!',
      );
      history.push('/terapeuta-login');
    } else {
      toast.error(
        'Usuário não encontrado, verifique o email digitado e tente novamente',
      );
    }
  } catch (err) {
    yield put(failure());
    toast.error(
      'Usuário não encontrado, verifique o email digitado e tente novamente',
    );
  }
}

export default all([
  takeLatest('@recoverPassword/INDEX_REQUEST', recoverPassword),
]);
