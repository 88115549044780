import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  indexRequest as indexRequestRegistros,
  indexRequestSuccess,
  showRequestSuccess,
  togleFechaModalRegistro,
  togleImportModal,
  failure,
} from './actions';

import {
  faturaRequest,
  cardRequest,
} from '~/store/modules/financeiroCards/actions';

import {
  proximosReceberRequest,
  proximosPagarRequest,
  ultimosRequest,
  bancosRequest,
  dashboardRequest,
} from '~/store/modules/financeiroDashboard/actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage, filter } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-registros', {
      params: {
        page: tPage,
        perPage: tPerPage,
        ...filter,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(
      indexRequestSuccess(total, perPage, page, lastPage, data, filter),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      parent_id,
      financeiro_categoria_id,
      financeiro_conta_id,
      financeiro_card_id,
      financeiro_metodo_id,
      financeiro_situation_id,
      agendamento_id,
      tipo,
      total_parcelas,
      descricao,
      data_vencimento,
      data_pagamento,
      data_competencia,
      registroModo,
      tp_repeticao,
      desconto,
      juros,
      page,
      perPage,
      filter,
      fromDashboard,
    } = payload;
    // se for saída deixa negativo
    const valor_base =
      Number(tipo) === 1 ? payload.valor_base : -1 * payload.valor_base;

    let valor_efetivo = null;
    if (payload.valor_efetivo) {
      valor_efetivo =
        Number(tipo) === 1 ? payload.valor_efetivo : -1 * payload.valor_efetivo;
    }

    let situation = null;
    if (typeof financeiro_situation_id !== 'number') {
      situation = Number(financeiro_situation_id);
    } else {
      situation = financeiro_situation_id;
    }
    if (situation === 0) {
      situation = 1;
    }

    const newRegistro = yield call(
      api.post,
      'terapeutas/financeiro-registros',
      {
        parent_id,
        financeiro_categoria_id,
        financeiro_conta_id,
        financeiro_card_id,
        financeiro_metodo_id,
        financeiro_situation_id: situation,
        agendamento_id,
        tipo,
        total_parcelas,
        descricao,
        data_vencimento,
        data_pagamento,
        data_competencia,
        valor_base,
        valor_efetivo,
        registroModo,
        tp_repeticao,
        desconto,
        juros,
      },
    );

    if (newRegistro) {
      if (fromDashboard) {
        if (Number(situation) !== 3) {
          if (Number(tipo) === 0) {
            yield put(proximosPagarRequest(1, 5, null));
          } else {
            yield put(proximosReceberRequest(1, 5, null));
          }
        } else {
          yield put(proximosReceberRequest(1, 5, null));
          yield put(proximosPagarRequest(1, 5, null));
          yield put(bancosRequest(1, 20));
          yield put(ultimosRequest(1, 7));
          yield put(dashboardRequest());
        }
      } else {
        yield put(indexRequestRegistros(page, perPage, filter));
      }
      toast.success('Registro cadastrado com sucesso');
    }
  } catch (err) {
    yield put(failure());

    if (err.response.data.error) {
      toast.error(err.response.data.error.message);
    } else if (err.response.data.message) {
      toast.error(err.response.data.message);
    } else {
      toast.error('Algo não deu certo.');
    }
  }
}

export function* quickUpdateRequest({ payload }) {
  try {
    const {
      id,
      financeiro_situation_id,
      page,
      perPage,
      filter,
      funcCallback,
    } = payload;

    yield call(api.put, `terapeutas/financeiro-registro-quickpay/${id}`, {
      financeiro_situation_id,
    });

    if (funcCallback) {
      yield put(funcCallback(page, perPage, filter));
    } else {
      yield put(indexRequestRegistros(page, perPage, filter));
    }

    toast.success('Registro alterado com sucesso!');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* updateFaturaRequest({ payload }) {
  try {
    const {
      id,
      cardId,
      financeiro_situation_id,
      data_pagamento,
      juros,
      desconto,
      financeiro_conta_id,
    } = payload;

    let valor_efetivo = null;

    valor_efetivo =
      Number(payload.valor_efetivo) < 0
        ? valor_efetivo
        : -1 * payload.valor_efetivo;

    yield call(api.put, `terapeutas/financeiro-registros/${id}`, {
      financeiro_situation_id,
      valor_efetivo,
      data_pagamento,
      juros: Number(juros),
      desconto: Number(desconto),
      financeiro_conta_id: Number(financeiro_conta_id),
    });

    yield put(faturaRequest(id));
    yield put(cardRequest(cardId));

    toast.success('Fatura alterada com sucesso!');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* showRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(
      api.get,
      `terapeutas/financeiro-registros/${id}`,
    );

    yield put(showRequestSuccess(response.data));
    yield put(togleFechaModalRegistro(true));
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error(err.response.data);
    }
    yield put(failure());
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      parent_id,
      financeiro_categoria_id,
      financeiro_conta_id,
      financeiro_metodo_id,
      financeiro_situation_id,
      agendamento_id,
      tipo,
      total_parcelas,
      descricao,
      data_vencimento,
      data_pagamento,
      data_competencia,
      tp_repeticao,
      desconto,
      juros,
      page,
      perPage,
      filter,
    } = payload;

    // se for saída deixa negativo
    const valor_base =
      Number(tipo) === 1 ? payload.valor_base : -1 * payload.valor_base;

    let valor_efetivo = null;
    if (payload.valor_efetivo) {
      valor_efetivo =
        Number(tipo) === 1 ? payload.valor_efetivo : -1 * payload.valor_efetivo;
    }

    let situation = null;
    if (typeof financeiro_situation_id !== 'number') {
      situation = Number(financeiro_situation_id);
    } else {
      situation = financeiro_situation_id;
    }
    if (situation === 0) {
      situation = 1;
    }

    const newRegistro = yield call(
      api.put,
      `terapeutas/financeiro-registros/${id}`,
      {
        parent_id,
        financeiro_categoria_id,
        financeiro_conta_id,
        financeiro_metodo_id: Number(financeiro_metodo_id),
        financeiro_situation_id: situation,
        agendamento_id,
        tipo,
        total_parcelas,
        descricao,
        data_vencimento,
        data_pagamento,
        data_competencia,
        valor_base,
        valor_efetivo,
        tp_repeticao,
        desconto,
        juros,
      },
    );

    if (newRegistro) {
      yield put(indexRequestRegistros(page, perPage, filter));
      toast.success('Registro atualizado com sucesso');
    }
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error(err.response.data);
    }
    yield put(failure());
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id, page, perPage, filter } = payload;

    yield call(api.delete, `terapeutas/financeiro-registros/${id}`);

    yield put(indexRequestRegistros(page, perPage, filter));

    toast.success('Registro excluído com sucesso');
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error(err.response.data);
    }
    yield put(failure());
  }
}

export function* importRequest({ payload }) {
  try {
    const { conta_id, upload } = payload;

    const data = new FormData();
    data.append('conta_id', conta_id);
    data.append('upload', upload);

    const resp = yield call(api.post, 'terapeutas/financeiro-importacao', data);
    if (resp) {
      yield put(indexRequestRegistros(1, 20));
      yield put(togleImportModal(false));
    }

    toast.success('Arquivo importado com sucesso');
  } catch (err) {
    if (err.response) {
      if (Array.isArray(err.response.data)) {
        err.response.data.map((error) => toast.error(error.message));
      } else {
        toast.error(err.response.data);
      }
    } else {
      toast.error('Ops algo deu errado.');
    }
    yield put(failure());
  }
}

export default all([
  takeLatest('@financeiroRegistros/INDEX_REQUEST', indexRequest),
  takeLatest('@financeiroRegistros/STORE_REQUEST', storeRequest),
  takeLatest('@financeiroRegistros/QUICK_UPDATE_REQUEST', quickUpdateRequest),
  takeLatest('@financeiroRegistros/DELETE_REQUEST', deleteRequest),
  takeLatest('@financeiroRegistros/SHOW_REQUEST', showRequest),
  takeLatest('@financeiroRegistros/UPDATE_REQUEST', updateRequest),
  takeLatest('@financeiroRegistros/UPDATE_FATURA_REQUEST', updateFaturaRequest),
  takeLatest('@financeiroRegistros/IMPORT_REQUEST', importRequest),
]);
