/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal, Button, Col, Row, Tooltip } from 'reactstrap';

import Input, {
  InputMasked,
  CheckboxInput,
  Select,
} from '../../../components/Input';
import { transformMoeda } from '~/helpers/transform';

import {
  storeRequest,
  updateRequest,
  togleFechaModalAdd,
} from '../../../store/modules/categoriasAgendamento/actions';
import { selectRequest as selectRequestContas } from '~/store/modules/financeiroContas/actions';

export default function StoreAgendamento() {
  const formRef = useRef(null);
  const [addFinanceiroDisable, setAddFinanceiroDisable] = useState({
    disabled: 'disabled',
  });
  const [tooltipFin, setTooltipFin] = useState(false);
  const [tooltipDays, setTooltipDays] = useState(false);

  const addModalCategoria = useSelector(
    (state) => state.categoriasAgendamento.addModal,
  );
  const ag = useSelector(
    (state) => state.categoriasAgendamento.categoriaAgendamento,
  );
  const selectContas = useSelector(
    (state) => state.financeiroContas.selectContas,
  );
  const dispatch = useDispatch();

  function togleModal(modal, agendamento) {
    dispatch(togleFechaModalAdd(modal, agendamento));
  }

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        duracao: Yup.string().required('Duração é obrigatório'),
        intervalo: Yup.string().required('Intervalo é obrigatório'),
        financeiro_conta_id: Yup.string().when('add_registro', {
          is: (r) => Number(r) > 0,
          then: Yup.string().required('Selecione uma conta'),
          otherwise: Yup.string().nullable(),
        }),
        due_days: Yup.string().when('add_registro', {
          is: (r) => Number(r) > 0,
          then: Yup.string().required('Informe o número de dias'),
          otherwise: Yup.string().nullable(),
        }),
        valor: Yup.string().when('add_registro', {
          is: (r) => Number(r) > 0,
          then: Yup.string()
            .matches(/^(?!R\$?.0,00)(R\$?..+)$/, 'Valor é obrigatório')
            .required('Informe o valor da sessão'),
          otherwise: Yup.string().nullable(),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const add_registro = Number(data.add_registro) !== 1 ? 0 : data.add_registro;

      const {
        name,
        color,
        duracao,
        intervalo,
        valor,
        financeiro_conta_id,
        due_days,
      } = data;

      if (ag.id) {
        dispatch(
          updateRequest(
            ag.id,
            name,
            color,
            duracao,
            intervalo,
            transformMoeda(valor, 1),
            financeiro_conta_id,
            add_registro,
            due_days,
          ),
        );
      } else {
        dispatch(
          storeRequest(
            name,
            color,
            duracao,
            intervalo,
            transformMoeda(valor, 1),
            financeiro_conta_id,
            add_registro,
            due_days,
          ),
        );
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  useEffect(() => {
    if (ag.add_registro) {
      setAddFinanceiroDisable(null);
    }
  }, [ag]);

  const togleAddFinanceiro = useCallback((e) => {
    if (e.checked && Number(e.value) === 1) {
      setAddFinanceiroDisable(null);
    } else {
      setAddFinanceiroDisable({ disabled: 'disabled' });
    }
  }, []);

  useEffect(() => {
    dispatch(selectRequestContas());
  }, [dispatch]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={addModalCategoria}
        toggle={() => togleModal(!addModalCategoria, null)}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {ag.id ? 'Editar Categoria' : 'Criar Nova Categoria'}
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModalCategoria, null)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="6">
                <label className="form-control-label" htmlFor="name">
                  Nome da sua categoria
                </label>
                <Input
                  name="name"
                  id="name"
                  placeholder="Nome da categoria"
                  type="text"
                  defaultValue={ag.name}
                />
              </Col>
              <Col md="6">
                <label className="form-control-label" htmlFor="color">
                  Defina uma cor
                </label>
                <Input
                  name="color"
                  id="color"
                  defaultValue={ag.color}
                  type="color"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="6">
                <label className="form-control-label" htmlFor="duracao">
                  Duração da sessão
                </label>
                <Input
                  name="duracao"
                  id="duracao"
                  placeholder="Duração da sessão"
                  type="number"
                  defaultValue={ag.duracao}
                />
              </Col>
              <Col xs="6">
                <label className="form-control-label" htmlFor="intervalo">
                  Intervalo entre sessões?
                </label>
                <Input
                  name="intervalo"
                  id="intervalo"
                  placeholder="Intervalo entre sessões"
                  type="number"
                  defaultValue={ag.intervalo}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <CheckboxInput
                  name="add_registro"
                  checked={ag.add_registro}
                  options={[
                    {
                      id: 'addRegistro',
                      value: 1,
                      label: 'Adicionar financeiro',
                    },
                  ]}
                  onClick={(e) => togleAddFinanceiro(e.target)}
                />
                <a
                  className="table-action"
                  href="#empoderaTerapeuta"
                  id="tooltipFin"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fa fa-info-circle" />
                </a>
                <Tooltip
                  placement="right"
                  isOpen={tooltipFin}
                  target="tooltipFin"
                  toggle={() => setTooltipFin(!tooltipFin)}
                >
                  Se você marcar essa opção todo agendamento realizado para essa
                  categoria automáticamente irá lançar um valor a receber no
                  financeiro
                </Tooltip>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="12">
                <label
                  className="form-control-label"
                  htmlFor="financeiroContas"
                >
                  Onde você irá receber esse valor?
                </label>
                <Select
                  id="financeiroContas"
                  name="financeiro_conta_id"
                  className="form-control"
                  defaultValue={ag.financeiro_conta_id}
                  options={{
                    placeholder: 'Selecione uma conta',
                  }}
                  data={selectContas}
                  {...addFinanceiroDisable}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="6">
                <label className="form-control-label" htmlFor="valor">
                  Qual o Valor da sessão?
                </label>
                <InputMasked
                  name="valor"
                  id="valor"
                  className="form-control"
                  placeholder="Valor da sessão"
                  kind="money"
                  defaultValue={transformMoeda(ag.valor, 2)}
                  {...addFinanceiroDisable}
                />
              </Col>
              <Col xs="6">
                <label className="form-control-label" htmlFor="intervalo">
                  Qnts para receber?
                  <a
                    className="table-action"
                    href="#empoderaTerapeuta"
                    id="tooltipDays"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fa fa-info-circle" />
                  </a>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipDays}
                    target="tooltipDays"
                    toggle={() => setTooltipDays(!tooltipDays)}
                  >
                    Você pode definir quantos dias após a consulta será feito o
                    pagamento, para o vencimento ficar no mesmo dia da consulta
                    deixe o número 0
                  </Tooltip>
                </label>
                <Input
                  name="due_days"
                  id="due_days"
                  defaultValue={ag.due_days}
                  placeholder="Quantos dias para receber"
                  type="number"
                  {...addFinanceiroDisable}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModalCategoria, null)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
