import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  indexRequest as indexRequestContas,
  indexRequestSuccess,
  failure,
  contaRequestSuccess,
  selectRequestSuccess,
  extratoRequestSuccess,
} from './actions';

import { indexRequest as indexRequestCards } from '../financeiroCards/actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-contas', {
      params: {
        tipoId: [1, 2, 3, 5],
        page: tPage,
        perPage: tPerPage,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(indexRequestSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      name,
      saldo_inicial,
      data_saldo_inicial,
      conta_tipo_id,
      institution_id,
    } = payload;

    yield call(api.post, 'terapeutas/financeiro-contas', {
      name,
      saldo_inicial,
      data_saldo_inicial,
      conta_tipo_id,
      institution_id,
    });

    yield put(indexRequestContas(1, 20));

    toast.success('Conta cadastrada com sucesso');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const { id, name } = payload;

    yield call(api.put, `terapeutas/financeiro-contas/${id}`, {
      name,
    });

    toast.success('Conta atualizada com sucesso');
  } catch (err) {
    yield put(failure());

    toast.error('Algo não deu certo.');
  }
}

export function* contaRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/financeiro-contas/${id}`);

    yield put(contaRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* extratoRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, 'terapeutas/financeiro-extrato', {
      params: {
        contaId: id,
      },
    });

    yield put(extratoRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Não foi possível buscar as faturas.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `terapeutas/financeiro-contas/${id}`);

    yield put(indexRequestContas(1, 20));
    yield put(indexRequestCards(1, 20));

    toast.success('Conta excluida com sucesso');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* selectRequest() {
  try {
    const response = yield call(
      api.get,
      'terapeutas/listagem-financeiro-contas',
    );

    yield put(selectRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest('@financeiroContas/INDEX_REQUEST', indexRequest),
  takeLatest('@financeiroContas/STORE_REQUEST', storeRequest),
  takeLatest('@financeiroContas/UPDATE_REQUEST', updateRequest),
  takeLatest('@financeiroContas/DELETE_REQUEST', deleteRequest),
  takeLatest('@financeiroContas/CONTA_REQUEST', contaRequest),
  takeLatest('@financeiroContas/SELECT_REQUEST', selectRequest),
  takeLatest('@financeiroContas/EXTRATO_REQUEST', extratoRequest),
]);
