import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';

import RegistroCommentsModal from './pacienteRegistroComments';
import ModalPaciente from './modalRegistroPaciente';
import {
  indexRequest,
  registroAtendimentoRequest,
  togleFechaModalComments,
} from '../../../store/modules/pacientesRegistro/actions';

export default function PacienteRegistros({ id }) {
  const pacienteRegistros = useSelector(
    (state) => state.pacientesRegistro.registros,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexRequest(id));
  }, [dispatch, id]);

  return (
    <Card>
      <RegistroCommentsModal />
      <CardHeader className="bg-transparent">
        <Row>
          <Col xs="6">
            <h3 className="mb-0">Registros do cliente</h3>
          </Col>
          <Col className="text-right" xs="6">
            <ModalPaciente />
          </Col>
        </Row>
      </CardHeader>

      <CardBody>
        <div
          className="timeline timeline-one-side"
          data-timeline-axis-style="dashed"
          data-timeline-content="axis"
        >
          {pacienteRegistros.map((data) => (
            <div className="timeline-block" key={data.id}>
              {data.prontuarioCategoria.color ? (
                <span
                  className="timeline-step badge-success"
                  style={{
                    backgroundColor: data.prontuarioCategoria.color,
                    color: '#fff',
                  }}
                >
                  <i className={`fa fa-${data.prontuarioCategoria.icon}`} />
                </span>
              ) : (
                  <span className="timeline-step badge-success">
                    <i className="ni ni-bell-55" />
                  </span>
                )}
              <div className="timeline-content">
                <div className="d-flex w-100 justify-content-between">
                  <small className="text-muted font-weight-bold">
                    {moment(data.created_at).format('D/M/Y H:m:s')}
                  </small>
                  <div className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(registroAtendimentoRequest(data.id));
                      }}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(togleFechaModalComments(true, data.id));
                      }}
                    >
                      <i className="fas fa-comments" />
                    </a>
                  </div>
                </div>
                <h5 className="mt-3 mb-0">{data.title}</h5>
                <p className="text-sm mt-1 mb-0">
                  <span dangerouslySetInnerHTML={{ __html: data.conteudo }} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
}

PacienteRegistros.propTypes = {
  id: PropTypes.string.isRequired,
};
