import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import {
  calendarRequestSuccess,
  agendamentoShowSuccess,
  deleteRequestSuccess,
  indexRequestSuccess,
  atendimentoRequestSuccess,
  failure,
  calendarRequest as calendarRequestAgendamentos,
  indexRequest as indexRequestCalendar,
  storeAgendamentoConflict,
  storeRequestSuccess,
} from './actions';

import api from '../../../services/api';

export function* calendarRequest() {
  try {
    const response = yield call(api.get, 'terapeutas/agendamentos');

    const dados = response.data.map((dd) => {
      return {
        id: dd.id,
        title: dd.paciente.name,
        start: dd.agendamento_start,
        end: dd.agendamento_end,
        color: dd.agendamentoCategoria.color || '#9f70ed',
      };
    });

    yield put(calendarRequestSuccess(dados));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* indexRequest({ payload }) {
  try {
    const { date } = payload;
    const response = yield call(
      api.get,
      `terapeutas/agendamentos?date=${date}`,
    );

    yield put(indexRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      agendamentoModo,
      paciente_id,
      agendamento_categoria_id,
      observation,
      initial_date,
      final_date,
      tp_repeticao,
    } = payload;

    const response = yield call(api.post, 'terapeutas/agendamentos', {
      agendamentoModo,
      paciente_id,
      agendamento_categoria_id,
      observation,
      tp_repeticao,
      initial_date,
      final_date,
    });

    if (response.status === 201) {
      toast.success('Agendamento cadastrado.');
      yield put(calendarRequestAgendamentos());
      yield put(indexRequestCalendar(moment().format('YYYY-MM-DD')));
      yield put(storeRequestSuccess());
    }
  } catch (err) {
    if (err.response && err.response.status === 409) {
      toast.error('Oops! Por favor, verifique o conflito de horário');
      yield put(storeAgendamentoConflict(err.response.data));
    } else {
      toast.error(
        'Oops! algo deu errado, verifique os erros e tente novamente',
      );
    }

    yield put(failure());
  }
}

export function* agendamentoRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/agendamentos/${id}`);

    const {
      paciente_id,
      agendamento_categoria_id,
      agendamento_start,
      observation,
    } = response.data;

    yield put(
      agendamentoShowSuccess(
        id,
        paciente_id,
        agendamento_categoria_id,
        agendamento_start,
        observation,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* atendimentoRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/agendamentos/${id}`);

    const {
      paciente,
      agendamento_situation_id,
      agendamento_start,
      observation,
      agendamento_categoria_id,
    } = response.data;

    yield put(
      atendimentoRequestSuccess(
        id,
        paciente,
        agendamento_situation_id,
        moment
          .tz(agendamento_start, moment.tz.guess())
          .format('DD/MM/YYYY HH:mm'),
        observation,
        agendamento_categoria_id,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      paciente_id,
      agendamento_categoria_id,
      agendamento_start,
      observation,
      agendamento_situation_id,
    } = payload;
    yield call(api.put, `terapeutas/agendamentos/${id}`, {
      paciente_id,
      agendamento_categoria_id,
      agendamento_start,
      observation,
      agendamento_situation_id,
    });
    toast.success('Agendamento atualizado.');
    yield put(calendarRequestAgendamentos());
    yield put(indexRequestCalendar(moment().format('YYYY-MM-DD')));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `terapeutas/agendamentos/${id}`);
    toast.success('Agendamento deletado com sucesso!  ');
    yield put(deleteRequestSuccess());
    yield put(calendarRequestAgendamentos());
    yield put(indexRequestCalendar(moment().format('YYYY-MM-DD')));
  } catch (err) {
    if (err.response) {
      if (err.response.status === 403) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        }
      }
    } else {
      toast.error('Algo não deu certo.');
    }
    yield put(failure());
  }
}

export default all([
  takeLatest('@agendamentos/CALENDAR_REQUEST', calendarRequest),
  takeLatest('@agendamentos/STORE_REQUEST', storeRequest),
  takeLatest('@agendamentos/UPDATE_REQUEST', updateRequest),
  takeLatest('@agendamentos/AGENDAMENTO_REQUEST', agendamentoRequest),
  takeLatest('@agendamentos/DELETE_REQUEST', deleteRequest),
  takeLatest('@agendamentos/INDEX_REQUEST', indexRequest),
  takeLatest('@agendamentos/ATENDIMENTO_REQUEST', atendimentoRequest),
]);
