export function newPasswordRequest(token, password, password_confirmation) {
  return {
    type: '@newPassword/STORE_REQUEST',
    payload: {token, password, password_confirmation}
  };
}

export function failure() {
  return {
    type: '@newPassword/FAILURE'
  };
}
