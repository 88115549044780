import React, { useEffect, useRef } from 'react';
// reactstrap components
import { Button, FormGroup, Row, Col } from 'reactstrap';
// core components
import { useSelector, useDispatch } from 'react-redux';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import moment from 'moment';
import Input, { InputMasked, BuscaEndereco } from '../../../components/Input';
import {
  pacienteRequest,
  updateRequest,
} from '../../../store/modules/pacientes/actions';

export default function PacienteProfile() {
  const formRef = useRef(null);

  const paciente = useSelector((state) => state.pacientes.paciente);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { guardians } = paciente;

  useEffect(() => {
    dispatch(pacienteRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    formRef.current.reset();
    formRef.current.setData(paciente);
  }, [paciente]);

  async function handlePacienteUpdate(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        nascimento: Yup.string()
          .matches(
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            'Data inválida',
          )
          .required('Data de nasciemnto é obrigatória'),
        email: Yup.string().when('nascimento', {
          is: (nascimento) =>
            moment().diff(moment(nascimento, ['DD-MM-YYYY']), 'years', false) >=
            18,
          then: Yup.string()
            .email('Insira um e-mail valido')
            .required('E-mail é obrigatório'),
          otherwise: Yup.string(),
        }),
        cep: Yup.string(),
        uf: Yup.string(),
        cidade: Yup.string(),
        endereco: Yup.string(),
        numero: Yup.string(),
        bairro: Yup.string(),
        complemento: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        name,
        nascimento,
        email,
        celular,
        telefone,
        cep,
        cidade,
        uf,
        endereco,
        numero,
        complemento,
        bairro,
      } = data;
      dispatch(
        updateRequest(
          id,
          name,
          nascimento,
          email,
          celular,
          telefone,
          cep,
          cidade,
          uf,
          endereco,
          numero,
          complemento,
          bairro,
          guardians,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <Form ref={formRef} onSubmit={handlePacienteUpdate}>
        {guardians.lenght > 0 && (
          <Input
            value={guardians.map((guardian) => guardian.id)}
            type="hidden"
          />
        )}
        <div className="pl-lg-4">
          <Row>
            <Col className="mb-0" md="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-name">
                  Nome
                </label>
                <Input
                  name="name"
                  defaultValue={paciente.name}
                  id="input-name"
                  placeholder="Nome completo"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-nascimento"
                >
                  Data de nasicmento
                </label>
                <InputMasked
                  defaultValue={paciente.nascimento}
                  name="nascimento"
                  className="form-control"
                  placeholder="Nascimento"
                  kind="custom"
                  options={{
                    mask: '99/99/9999',
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  E-mail
                </label>
                <Input
                  name="email"
                  defaultValue={paciente.email}
                  id="input-email"
                  placeholder="E-mail"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-celular">
                  Celular
                </label>
                <InputMasked
                  defaultValue={paciente.celular}
                  name="celular"
                  className="form-control"
                  placeholder="Fone ex: (99) 99999-9999"
                  kind="cel-phone"
                  options={{
                    maskType: 'BRL',
                    withDDD: true,
                    dddMask: '(99) ',
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-telefone">
                  Telefone
                </label>
                <InputMasked
                  name="telefone"
                  defaultValue={paciente.telefone}
                  className="form-control"
                  placeholder="Fone ex: (99) 99999-9999"
                  kind="cel-phone"
                  options={{
                    maskType: 'BRL',
                    withDDD: true,
                    dddMask: '(99) ',
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-0" md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-cep">
                  Cep
                </label>
                <BuscaEndereco
                  name="cep"
                  defaultValue={paciente.cep}
                  formRef={formRef}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-0" md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-endereco">
                  Endereço
                </label>
                <Input
                  name="endereco"
                  defaultValue={paciente.endereco}
                  id="input-endereco"
                  placeholder="Endereço"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="2">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-numero">
                  Numero
                </label>
                <Input
                  name="numero"
                  defaultValue={paciente.numero}
                  id="input-numero"
                  placeholder="Número"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-complemento"
                >
                  Complemento
                </label>
                <Input
                  name="complemento"
                  defaultValue={paciente.complemento}
                  id="input-complemento"
                  placeholder="Complemento"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-bairro">
                  Bairro
                </label>
                <Input
                  name="bairro"
                  defaultValue={paciente.bairro}
                  id="input-bairro"
                  placeholder="Bairro"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-cidade">
                  Cidade
                </label>
                <Input
                  name="cidade"
                  defaultValue={paciente.cidade}
                  id="input-cidade"
                  placeholder="Cidade"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="mb-0" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-uf">
                  UF
                </label>
                <Input
                  name="uf"
                  defaultValue={paciente.uf}
                  id="input-uf"
                  placeholder="Estado"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </div>
      </Form>
    </>
  );
}
