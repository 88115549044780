import { takeLatest, all, call, put } from 'redux-saga/effects';

import moment from 'moment';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  indexRequest as indexRequestCards,
  indexRequestSuccess,
  cardRequestSuccess,
  failure,
  faturasRequestSuccess,
  faturaRequestSuccess,
  cardRequest as showCard,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-contas', {
      params: {
        tipoId: [4],
        page: tPage,
        perPage: tPerPage,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(indexRequestSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      name,
      conta_tipo_id,
      conta_pagamento_id,
      dia_fechamento,
      dia_vencimento,
      limite_total,
      institution_id,
    } = payload;

    yield call(api.post, 'terapeutas/financeiro-contas', {
      name,
      conta_tipo_id,
      conta_pagamento_id,
      dia_fechamento,
      dia_vencimento,
      limite_total,
      institution_id,
    });

    yield put(indexRequestCards(1, 20));

    toast.success('Cartão cadastrado com sucesso');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* cardRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/financeiro-contas/${id}`);

    yield put(cardRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const { id, name, limite_total } = payload;

    yield call(api.put, `terapeutas/financeiro-contas/${id}`, {
      name,
      limite_total,
    });

    yield put(showCard(id));

    toast.success('Cartão atualizado com sucesso');
  } catch (err) {
    yield put(failure());

    toast.error('Algo não deu certo.');
  }
}

export function* faturasRequest({ payload }) {
  try {
    const { id, dia_vencimento } = payload;

    let vencimentoIni = null;
    let vencimentoEnd = null;

    if (dia_vencimento) {
      vencimentoIni = moment()
        .set('date', dia_vencimento)
        .subtract(10, 'months')
        .format('YYYY-MM-DD');

      vencimentoEnd = moment()
        .set('date', dia_vencimento)
        .add(1, 'months')
        .format('YYYY-MM-DD');
    }

    const response = yield call(api.get, 'terapeutas/financeiro-faturas', {
      params: {
        contasIds: [id],
        vencimentoIni,
        vencimentoEnd,
      },
    });

    yield put(faturasRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Não foi possível buscar as faturas.');
  }
}

export function* faturaRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/financeiro-faturas/${id}`);

    yield put(faturaRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Não foi possível buscar as faturas.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `terapeutas/financeiro-contas/${id}`);

    yield put(indexRequestCards(1, 20));

    toast.success('Cartão excluido com sucesso');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}
export default all([
  takeLatest('@financeiroCards/INDEX_REQUEST', indexRequest),
  takeLatest('@financeiroCards/STORE_REQUEST', storeRequest),
  takeLatest('@financeiroCards/UPDATE_REQUEST', updateRequest),
  takeLatest('@financeiroCards/DELETE_REQUEST', deleteRequest),
  takeLatest('@financeiroCards/CARD_REQUEST', cardRequest),
  takeLatest('@financeiroCards/FATURAS_REQUEST', faturasRequest),
  takeLatest('@financeiroCards/FATURA_REQUEST', faturaRequest),
]);
