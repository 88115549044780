import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal
} from "reactstrap";

import RegistroComments from "../../../components/RegistroComments";

import { togleFechaModalComments } from '../../../store/modules/pacientesRegistro/actions';

export default function RegistroCommentsModal() {
  const registroAtendimento = useSelector(state => state.pacientesRegistro.registroAtendimento);
  const modal = useSelector(state => state.pacientesRegistro.commentModal);
  const dispatch = useDispatch();

  function togleModal(value) {
    dispatch(togleFechaModalComments(value, null));
  };


  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={modal}
        toggle={() => togleModal(!modal)}
      >

        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => togleModal(!modal)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {registroAtendimento.id && <RegistroComments prontuario_id={registroAtendimento.id} />}
        </div>


      </Modal>
    </>
  );

}
