import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { failure } from './actions';

import api from '../../../services/api';

export function* sendMessage({ payload }) {
  try {
    const { subject, message } = payload;

    const response = yield call(api.post, '/terapeutas/send-message', {
      subject,
      message,
    });
    if (response.status === 201) {
      toast.success('Mensagem enviada com sucesso!');
    }
  } catch (err) {
    yield put(failure());
    toast.error('Ooops, não foi possível enviar sua mensagem.');
  }
}

export default all([takeLatest('@sendMessage/STORE_REQUEST', sendMessage)]);
