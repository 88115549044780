import React from 'react';
import PropTypes from 'prop-types';

export default function ValidationError({ error }) {
  return (
    <>
      {error && (
        <div className="invalid-feedback" style={{ display: 'inline' }}>
          {error}
        </div>
      )}
    </>
  );
}

ValidationError.propTypes = {
  error: PropTypes.string,
};

ValidationError.defaultProps = {
  error: undefined,
};
