import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  agendamentoSituation: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: []
  }
};

export default function agendamentoSituation(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@agendamentoSituation/INDEX_REQUEST_SUCCESS': {
        draft.agendamentoSituation.total = action.payload.total;
        draft.agendamentoSituation.perPage = action.payload.perPage;
        draft.agendamentoSituation.page = action.payload.page;
        draft.agendamentoSituation.lastPage = action.payload.lastPage;
        draft.agendamentoSituation.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@agendamentoSituation/SELECT_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
