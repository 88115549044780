import React, { useState, useRef } from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import { Form } from '@unform/web';
import Input from '../../../components/Input'
import * as Yup from 'yup'
import api from '../../../services/api';

// core components
import RegisterHeader from "components/Headers/RegisterHeader";
import { toast } from "react-toastify";

export default function Register() {
  const [focusedName, setFocusedName] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Preecha seu nome')
          .matches(/[a-zA-Z]/, 'O campo nome só deve conter letras'),
        email: Yup.string().email('Insira um e-mail valido').required('E-mail é obrigatório')
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      const { name, email } = data;
      await api.post('lista-espera', {
        name,
        email
      })
      setLoading(false)
      toast.success('E-mail cadastrado, agora é só esperar')
    } catch (err) {
      setLoading(false)
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }else{
        toast.error('Ops, algo não deu certo')
      }
    }
  }
  return (
    <>
      <RegisterHeader
        title="Lista de espera"
        beforeLink="Nossa ferramenta ainda está em desenvolvimento, e para oferecer o melhor suporte a
        todos usuários os cadastros ainda estão restritos a convites.
        Se você tem interesse em participar desta comunidade faça seu cadastro na lista de espera!"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" ref={formRef} onSubmit={handleSubmit} className="needs-validation">
                  <FormGroup
                    className={classnames({
                      focused: focusedName
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Nome"
                        className="form-control"
                        type="text"
                        onFocus={() => setFocusedName(true)}
                        onBlur={() => setFocusedName(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        type="email"
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button className="mt-4" color="info" type="submit">
                      {loading ? '... carregando' : 'Cadastrar'}
                    </Button>
                  </div>
                  <br />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );

}
