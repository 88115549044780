import React from 'react';
// reactstrap components
import { Container, Row } from 'reactstrap';
import CardsHeader from '../../../components/Headers/CardsHeader';
import DayCaldendar from '../Calendar/DayCalendar';
import IndexAgendamentos from '../Calendar/indexAgendamentos';

export default function Dashboard() {
  return (
    <>
      <CardsHeader name="Meus Atendimentos" parentName="Atendimentos" />
      <Container className="mt--6" fluid>
        <Row>
          <IndexAgendamentos />
        </Row>
        <Row>
          <DayCaldendar />
        </Row>
      </Container>
    </>
  );
}
