import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from 'react-redux';

// reactstrap components
import { Container } from "reactstrap";

// core components
import AuthHeader from "components/Headers/AuthHeader";

import { indexRequest } from '../../../store/modules/activation/actions';

export default function Activation(props) {
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    dispatch(indexRequest(token));
  }, [dispatch, token]);

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5" />
    </>
  );
}


