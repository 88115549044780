import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// reactstrap components
import { Button, Modal, Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Input, { Select } from '../../../components/Input';

import Ckeditor from '../../../components/Ckeditor';
import Upload from '../../../components/Upload';

import { selectRequest } from '../../../store/modules/categoriasRegistro/actions';
import {
  storeRequest,
  togleFechaModalAdd,
} from '../../../store/modules/pacientesRegistro/actions';

export default function Modals() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const paciente = useSelector((state) => state.pacientes.paciente);
  const addModal = useSelector((state) => state.pacientesRegistro.addModal);
  const categoriaRegistros = useSelector(
    (state) => state.categoriasRegistro.categoriasRegistro.data,
  );

  async function handleCadastro(data) {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('Titulo é obrigatório'),
        prontuario_categoria_id: Yup.string().required(
          'Selecione uma categoria',
        ),
        conteudo: Yup.string().required('Conteúdo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { prontuario_categoria_id, title, conteudo, files } = data;
      dispatch(
        storeRequest(
          null,
          paciente.id,
          prontuario_categoria_id,
          title,
          conteudo,
          files,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  useEffect(() => {
    dispatch(selectRequest());
  }, [dispatch]);

  function togleModal(value) {
    dispatch(togleFechaModalAdd(value));
  }

  return (
    <>
      <Button
        className="btn-round btn-icon"
        color="primary"
        href="#empoderaTerapeuta"
        onClick={(e) => {
          e.preventDefault();
          togleModal(!addModal);
        }}
        size="sm"
      >
        <span className="btn-inner--icon mr-1">
          <i className="fas fa-notes-medical" />
        </span>
        <span className="btn-inner--text">Adicionar</span>
      </Button>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addModal}
        toggle={() => togleModal(!addModal)}
      >
        <Form ref={formRef} onSubmit={handleCadastro}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Novo Registro
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="6">
                <label className="form-control-label" htmlFor="input-titulo">
                  Titulo
                </label>
                <Input
                  name="title"
                  id="input-titulo"
                  placeholder="Titulo"
                  type="text"
                />
              </Col>
              <Col lg="6">
                <label className="form-control-label" htmlFor="input-name">
                  Categoria de Registro
                </label>
                <Select
                  name="prontuario_categoria_id"
                  className="form-control"
                  options={{
                    placeholder: 'Selecione uma categoria de registro',
                  }}
                  data={categoriaRegistros}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label">Conteudo</label>
                <Ckeditor name="conteudo" />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Upload />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
