import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Gravatar from 'react-gravatar';
import ReactBSAlert from 'react-bootstrap-sweetalert';

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import { Form } from '@unform/web';
import { TextArea } from '../Input';
import moment from 'moment';

import {
  indexRequest,
  storeRequest,
  deleteRequest,
} from '../../store/modules/registroComments/actions';

export default function RegistroComments({ prontuario_id }) {
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const comenatios = useSelector(
    (state) => state.registroComments.registroComments,
  );
  const terapeuta = useSelector((state) => state.terapeuta.terapeuta);

  useEffect(() => {
    dispatch(indexRequest(prontuario_id));
  }, [dispatch, prontuario_id]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors([]);
      const schema = Yup.object().shape({
        comment: Yup.string().required('Comentário é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { comment } = data;
      dispatch(storeRequest(prontuario_id, comment));
      formRef.current.setData({
        comment: '',
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error(
        'Não foi possivel registrar o comentário, verifique os erros e tente novamente.',
      );
    }
  }

  const deleteEventSweetAlert = (comment_id) => {
    setAlert(() => {
      return (
        <ReactBSAlert
          warning
          style={{ display: 'block' }}
          title="Você tem certeza?"
          onConfirm={() => {
            setAlert(false);
          }}
          onCancel={() => deleteAgendamento(comment_id)}
          confirmBtnCssClass="btn-secondary"
          cancelBtnBsStyle="danger"
          confirmBtnText="Cancelar"
          cancelBtnText="Sim, delete isso"
          showCancel
          btnSize=""
        >
          Essa exclusão não poderá ser desfeita, tem certeza?
        </ReactBSAlert>
      );
    });
  };

  const deleteAgendamento = (comment_id) => {
    dispatch(deleteRequest(comment_id, prontuario_id));
    setAlert(false);
  };

  return (
    <>
      {alert}
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">Comentários</h5>
        </CardHeader>
        <CardBody className="p-0">
          <ListGroup flush>
            {comenatios.map((data) => (
              <ListGroupItem
                key={data.id}
                className="list-group-item-action flex-column align-items-start py-4 px-4"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                tag="span"
              >
                <div className="d-flex w-100 justify-content-between">
                  <div>
                    <div className="d-flex w-100 align-items-center">
                      {data.terapeuta && (
                        <Gravatar
                          email={
                            data.terapeuta.email ||
                            '00000000000000000000000000000000'
                          }
                          className="avatar avatar-xs mr-2"
                          default="mp"
                        />
                      )}
                      {data.paciente && (
                        <Gravatar
                          email={
                            data.paciente.email ||
                            '00000000000000000000000000000000'
                          }
                          className="avatar avatar-xs mr-2"
                          default="mp"
                        />
                      )}
                      <h5 className="mb-1">
                        {data.terapeuta && data.terapeuta.name}
                        {data.paciente && data.paciente.name}
                      </h5>
                    </div>
                  </div>
                  <br />
                  <br />
                  <small>
                    {moment
                      .tz(data.created_at, moment.tz.guess())
                      .format('DD/MM/YYYY HH:mm:ss')}
                  </small>
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <div
                    className="text-sm mb-0"
                    dangerouslySetInnerHTML={{ __html: data.comment }}
                  />
                  {data.terapeuta && data.terapeuta.id === terapeuta.id && (
                    <a
                      href="#empoderaTerapeuta"
                      onClick={() => deleteEventSweetAlert(data.id)}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  )}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>

      <Container className="search-header">
        <Row>
          <Col>
            <Form
              role="form"
              ref={formRef}
              onSubmit={handleSubmit}
              className="needs-validation"
            >
              <Row>
                <Col xl="12">
                  <TextArea name="comment" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="text-left btn-padding" xs="12">
                  <Button
                    className="btn-neutral btn-round btn-icon"
                    color="default"
                    href=""
                    type="submit"
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-notes-medical" />
                    </span>
                    <span className="btn-inner--text">Comentar</span>
                  </Button>
                </Col>
              </Row>
              <br />
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
