import axios from 'axios';

const config =
  process.env.NODE_ENV === 'development'
    ? 'https://backend-vanessaterapeuta.herokuapp.com'
    : 'https://backend-vanessaterapeuta.herokuapp.com';

const api = axios.create({
  baseURL: config,
});

export default api;
