import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';

import TextInputMask from 'react-masked-text';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import moment from 'moment';
import history from '../../../services/history';
import Input from '../../../components/Input';
import { indexRequest } from '../../../store/modules/pacientes/actions';

export default function Search({ defaultFilters }) {
  const [dateValue, setDateValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const formRef = useRef(null);
  const dispatch = useDispatch();

  function handleClean() {
    setDateValue('');
    setPhoneValue('');
    setNameValue('');
    setEmailValue('');

    if (history.location.state) {
      history.location.state = null;
    }
    dispatch(indexRequest(1, 20));
  }

  useEffect(() => {
    if (defaultFilters) {
      if (defaultFilters.email) {
        setEmailValue(defaultFilters.email);
      }
      if (defaultFilters.name) {
        setNameValue(defaultFilters.name);
      }
      if (defaultFilters.telefone) {
        setPhoneValue(defaultFilters.telefone);
      }
      if (defaultFilters.nascimento) {
        const nasc = moment(
          defaultFilters.nascimento,
          ['YYYY-MM-DD'],
          'pt-br',
        ).format('DD/MM/YYYY');
        setDateValue(nasc);
      }
    }
  }, [defaultFilters]);

  async function handleSubmit(data) {
    const name = nameValue;
    const telefone = phoneValue || null;
    const nascimento = dateValue
      ? moment(dateValue, ['DD/MM/YYYY'], 'pt-br').format('YYYY-MM-DD')
      : null;

    try {
      const schema = Yup.object().shape({
        name: Yup.string(),
        email: Yup.string(),
        telefone: Yup.string(),
        nascimento: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      const { email } = data;

      const filters = {
        name,
        email,
        telefone,
        nascimento,
      };

      dispatch(indexRequest(1, 20, filters));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <Container className="search-header">
        <Row>
          <Col>
            <Form
              role="form"
              ref={formRef}
              onSubmit={handleSubmit}
              className="needs-validation"
            >
              <Row>
                <Col xs="3">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Nome"
                        className="form-control"
                        type="text"
                        value={nameValue}
                        onChange={(e) => setNameValue(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        type="email"
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="nascimento" id="inputDate" type="hidden" />
                      <TextInputMask
                        name="date_input"
                        className="form-control"
                        placeholder="Nascimento"
                        kind="datetime"
                        options={{
                          format: 'DD/MM/YYYY',
                        }}
                        onChangeText={(e) => setDateValue(e)}
                        value={dateValue}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="phone" id="inputPhone" type="hidden" />
                      <TextInputMask
                        name="phone_input"
                        className="form-control"
                        placeholder="Fone ex: (99) 99999-9999"
                        kind="cel-phone"
                        options={{
                          maskType: 'BRL',
                          withDDD: true,
                          dddMask: '(99) ',
                        }}
                        onChangeText={(e) => setPhoneValue(e)}
                        value={phoneValue}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-left btn-padding" xs="12">
                  <Button
                    className="btn-neutral btn-round btn-icon"
                    color="default"
                    href=""
                    onClick={() => {
                      handleClean();
                    }}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-notes-medical" />
                    </span>
                    <span className="btn-inner--text">Limpar Filtros</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-round btn-icon"
                    color="default"
                    href=""
                    type="submit"
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-notes-medical" />
                    </span>
                    <span className="btn-inner--text">Buscar</span>
                  </Button>
                </Col>
              </Row>
              <br />
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Search.propTypes = {
  defaultFilters: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    nascimento: PropTypes.string,
    telefone: PropTypes.string,
  }),
};

Search.defaultProps = {
  defaultFilters: {},
};
