import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

import {
  indexRequest as indexRequestCategorias,
  indexRequestSuccess,
  categoriaAgendamentoRequestSuccess,
  togleFechaModalAdd,
  failure,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage, date } = payload;
    const response = yield call(api.get, 'terapeutas/agendamento-categorias', {
      params: {
        page: tPage,
        perPage: tPerPage,
        date,
      },
    });
    const { total, perPage, page, lastPage, data } = response.data;

    yield put(indexRequestSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* selectRequest() {
  try {
    const response = yield call(
      api.get,
      'terapeutas/listagem-categoria-agendamento',
    );
    yield put(
      indexRequestSuccess(response.data.length, 1, 1, 1, response.data),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    } = payload;
    const response = yield call(api.post, 'terapeutas/agendamento-categorias', {
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    });

    const { id } = response.data;
    if (id) {
      yield put(indexRequestCategorias());
      yield put(
        togleFechaModalAdd(false, {
          id,
          name,
          color,
          duracao,
          intervalo,
          valor,
          financeiro_conta_id,
          add_registro,
          due_days,
        }),
      );
    } else {
      throw new Error('Algo não rolou');
    }
    toast.success('Categoria de agendamento criada com sucesso!');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    } = payload;
    yield call(api.put, `terapeutas/agendamento-categorias/${id}`, {
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    });

    yield put(indexRequestCategorias(1, 20));
    yield put(togleFechaModalAdd(false));
    toast.success('Dados atualizados com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* categoriaAgendamentoRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(
      api.get,
      `terapeutas/agendamento-categorias/${id}`,
    );

    const {
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    } = response.data;

    if (!name) {
      yield put(failure());
      toast.error('Algo não deu certo.');
      history.push('/terapeuta/categorias-agendamentos');
    }

    yield put(
      categoriaAgendamentoRequestSuccess(
        id,
        name,
        color,
        duracao,
        intervalo,
        valor,
        financeiro_conta_id,
        add_registro,
        due_days,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
    history.push('/terapeuta/pacientes');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(
      api.delete,
      `terapeutas/agendamento-categorias/${id}`,
    );
    if (response) {
      toast.success('Categoria deletada com sucesso');
      yield put(indexRequestCategorias(1, 20));
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error('Algo não deu certo.');
    }
    yield put(failure());
  }
}

export default all([
  takeLatest('@categoriasAgendamento/INDEX_REQUEST', indexRequest),
  takeLatest('@categoriasAgendamento/STORE_REQUEST', storeRequest),
  takeLatest('@categoriasAgendamento/UPDATE_REQUEST', updateRequest),
  takeLatest(
    '@categoriasAgendamento/CATEGORIA_AGENDAMENTO_REQUEST',
    categoriaAgendamentoRequest,
  ),
  takeLatest('@categoriasAgendamento/DELETE_REQUEST', deleteRequest),
  takeLatest('@categoriasAgendamento/SELECT_REQUEST', selectRequest),
]);
