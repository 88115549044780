import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// reactstrap components
import { Button, Modal } from 'reactstrap';
import '~/assets/vendor/quill/dist/quill.core.css';

import { togleFechaModalAdd } from '~/store/modules/modal/actions';

export default function Modals({ page, perPage, deleteRequest, filter }) {
  const dispatch = useDispatch();
  const addModal = useSelector((state) => state.modal.addModal);
  const { id } = useSelector((state) => state.modal);

  function togleModal(value) {
    dispatch(togleFechaModalAdd(value));
  }

  function handleDelete() {
    dispatch(deleteRequest(id, page, perPage, filter));
    togleModal(!addModal);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={addModal}
        toggle={() => togleModal(!addModal)}
      >
        <div className="bg-gradient-danger">
          <div className="modal-header">
            <h6
              className="modal-danger-title modal-title"
              id="modal-title-notification"
            >
              Atenção!
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body modal-delete-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading heading-delete mt-4">
                Tem certeza que deseja excluir?
              </h4>
              <p>
                Esta ação não pode ser desfeita, confirme se você deseja
                excluir.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={() => handleDelete(id)}
            >
              Confirmar exclusão
            </Button>
            <Button
              className=" btn-white ml-auto "
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

Modals.propTypes = {
  deleteRequest: PropTypes.func.isRequired,
  page: PropTypes.number,
  perPage: PropTypes.number,
  filter: PropTypes.objectOf(PropTypes.any),
};

Modals.defaultProps = {
  page: null,
  perPage: null,
  filter: {},
};
