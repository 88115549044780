import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { failure, selectRequestSuccess } from './actions';

export function* selectRequest() {
  try {
    const response = yield call(
      api.get,
      'terapeutas/listagem-financeiro-metodos',
    );

    yield put(selectRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest('@financeiroMetodos/SELECT_REQUEST', selectRequest),
]);
