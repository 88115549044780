import produce from 'immer';

const INITIAL_STATE = {
  addModal: false,
  loading: false,
  categoriaAgendamento: {
    id: undefined,
    color: '#9f70ed',
    name: '',
    duracao: '',
    intervalo: '',
    valor: 0,
    financeiro_conta_id: 0,
    add_registro: 0,
    due_days: 0,
  },
  categoriasAgendamento: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
};

export default function categoriasAgendamento(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@categoriasAgendamento/INDEX_REQUEST_SUCCESS': {
        draft.categoriasAgendamento.total = Number(action.payload.total);
        draft.categoriasAgendamento.perPage = Number(action.payload.perPage);
        draft.categoriasAgendamento.page = Number(action.payload.page);
        draft.categoriasAgendamento.lastPage = Number(action.payload.lastPage);
        draft.categoriasAgendamento.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@categoriasAgendamento/CATEGORIA_AGENDAMENTO_REQUEST_SUCCESS': {
        draft.categoriaAgendamento.id = action.payload.id;
        draft.categoriaAgendamento.name = action.payload.name;
        draft.categoriaAgendamento.color = action.payload.color;
        draft.categoriaAgendamento.duracao = action.payload.duracao;
        draft.categoriaAgendamento.intervalo = action.payload.intervalo;
        draft.categoriaAgendamento.valor = action.payload.valor;
        draft.categoriaAgendamento.financeiro_conta_id =
          action.payload.financeiro_conta_id;
        draft.categoriaAgendamento.add_registro = Number(
          action.payload.add_registro,
        );
        draft.categoriaAgendamento.due_days = action.payload.due_days;
        draft.loading = false;
        break;
      }
      case '@categoriasAgendamento/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        if (action.payload.agendamento) {
          draft.categoriaAgendamento.id = action.payload.agendamento.id;

          draft.categoriaAgendamento.name = action.payload.agendamento.name;
          draft.categoriaAgendamento.duracao =
            action.payload.agendamento.duracao;
          draft.categoriaAgendamento.intervalo =
            action.payload.agendamento.intervalo;
          draft.categoriaAgendamento.valor = action.payload.agendamento.valor;
          draft.categoriaAgendamento.financeiro_conta_id =
            action.payload.agendamento.financeiro_conta_id;
          draft.categoriaAgendamento.color = action.payload.agendamento.color;
          draft.categoriaAgendamento.add_registro = Number(
            action.payload.agendamento.add_registro,
          );
          draft.categoriaAgendamento.due_days =
            action.payload.agendamento.due_days;
        } else {
          draft.categoriaAgendamento = INITIAL_STATE.categoriaAgendamento;
        }
        break;
      }
      case '@categoriasAgendamento/FAILURE': {
        draft.loading = false;
        break;
      }
      case '@categoriasAgendamento/CATEGORIA_AGENDAMENTO_REQUEST':
      case '@categoriasAgendamento/STORE_REQUEST':
      case '@categoriasAgendamento/INDEX_REQUEST':
      case '@categoriasAgendamento/UPDATE_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
