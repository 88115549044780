import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
/**
 *
 * @param {string} valor  string ou float
 * @param {integer} tipo  1 - Float to BRL / 2 BRL - Float
 */
export function transformMoeda(valor, tipo) {
  let nValor;
  switch (tipo) {
    case 1: {
      nValor = valor.replace('R$', '');
      nValor = nValor.replace('.', '');
      nValor = nValor.replace(',', '.');
      nValor = nValor.replace(' ', '');
      nValor = Number(nValor);

      break;
    }
    case 2: {
      nValor = Number(valor).toLocaleString('pt-Br', {
        style: 'currency',
        currency: 'BRL',
      });
      break;
    }
    default:
      nValor = valor;
  }
  return nValor;
}

export function exibeVariacao(valor) {
  const classe = Number(valor) > 0 ? 'text-success' : 'text-warning';
  return (
    <p className="mt-3 mb-0 text-sm">
      <span className={`${classe} mr-2`}>
        <i className="fa fa-arrow-up" />
        &nbsp;
        {parseFloat(valor || 0).toLocaleString('pt-Br', {
          style: 'decimal',
          maximumFractionDigits: 2,
        })}
        %
      </span>
      <br />
      <span className="text-nowrap">Em comparação ao mês passado</span>
    </p>
  );
}

export function dateFormat(
  date,
  entradaFormat = ['DD-MM-YYYY'],
  saidaFormat = 'YYYY-MM-DD',
  tmz = false,
) {
  if (date) {
    return moment(date, entradaFormat, 'pt-br').format(saidaFormat);
  }
  return null;
}

export function situacaoAtual(situation) {
  let label = '';
  let cor = '';
  switch (Number(situation)) {
    case 1: {
      label = 'Em aberto';
      cor = 'bg-warning';
      break;
    }
    case 2: {
      label = 'Em atraso';
      cor = 'bg-danger';
      break;
    }
    case 3: {
      label = 'Pago';
      cor = 'bg-success';
      break;
    }
    default:
  }
  return (
    <span className="badge badge-dot mr-4">
      <i className={cor} />
      <span className="status">{label}</span>
    </span>
  );
}
