import React, { useRef } from 'react';
import moment from 'moment';
import { FormGroup, Button, Col, Modal } from 'reactstrap';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Input, { InputMasked } from '~/components/Input';
import PacientesExistentes from './pacientesExistentes';

import {
  quickStoreRequest,
  togleModalAdd,
} from '~/store/modules/pacientes/actions';

export default function PacienteQuickStore() {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const addModalPaciente = useSelector((state) => state.pacientes.addModal);
  const paciente = useSelector((state) => state.pacientes.paciente);

  function togleModal(modal) {
    dispatch(togleModalAdd(modal));
  }

  async function handleSubmit(data) {
    try {
      if (!data.celular) {
        data.celular = undefined;
      }
      if (!data.telefone) {
        data.telefone = undefined;
      }
      const schema = Yup.object().shape({
        name: Yup.string()
          .matches(
            /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
            'O campo nome só deve conter letras',
          )
          .required('Nome é obrigatório'),
        nascimento: Yup.string()
          .matches(
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            'Data inválida',
          )
          .required('Data de nascimento é obrigatória'),
        email: Yup.string().when('nascimento', {
          is: (nascimento) =>
            moment().diff(moment(nascimento, ['DD-MM-YYYY']), 'years', false) >=
            18,
          then: Yup.string()
            .email('Insira um e-mail valido')
            .required('E-mail é obrigatório'),
          otherwise: Yup.string(),
        }),
        celular: Yup.string().matches(
          /\(\d{2,}\) \d{4,}-\d{4}/,
          'Insira um número válido',
        ),
        telefone: Yup.string().matches(
          /\(\d{2,}\) \d{4,}-\d{4}/,
          'Insira um número válido',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { name, nascimento, email, celular, telefone } = data;
      dispatch(quickStoreRequest(name, nascimento, email, celular, telefone));

      togleModal(!addModalPaciente);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }
  return (
    <>
      <PacientesExistentes quickStore />
      <Modal
        className="modal-dialog-centered"
        isOpen={addModalPaciente}
        toggle={() => togleModal(!addModalPaciente, null)}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Cadastrar novo paciente
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModalPaciente, null)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-row">
              <Col xs="12">
                <label className="form-control-label" htmlFor="input-name">
                  Nome
                </label>
                <Input
                  name="name"
                  id="input-name"
                  placeholder="Nome completo"
                  type="text"
                  defaultValue={paciente.name}
                />
                <br />
              </Col>
              <Col className="mb-0" xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    E-mail
                  </label>
                  <Input
                    name="email"
                    id="input-email"
                    placeholder="E-mail"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col className="mb-0" md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-nascimento"
                  >
                    Data de nasicmento
                  </label>
                  <InputMasked
                    name="nascimento"
                    className="form-control"
                    placeholder="Nascimento"
                    kind="custom"
                    options={{
                      mask: '99/99/9999',
                    }}
                  />
                </FormGroup>
              </Col>

              <Col className="mb-0" md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-celular">
                    Celular
                  </label>
                  <InputMasked
                    name="celular"
                    defaultValue={undefined}
                    className="form-control"
                    placeholder="Fone ex: (99) 99999-9999"
                    kind="cel-phone"
                    options={{
                      maskType: 'BRL',
                      withDDD: true,
                      dddMask: '(99) ',
                    }}
                  />
                </FormGroup>
              </Col>
              <Col className="mb-0" md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-telefone"
                  >
                    Telefone
                  </label>
                  <InputMasked
                    name="telefone"
                    className="form-control"
                    placeholder="Fone ex: (99) 99999-9999"
                    kind="cel-phone"
                    options={{
                      maskType: 'BRL',
                      withDDD: true,
                      dddMask: '(99) ',
                    }}
                  />
                </FormGroup>
              </Col>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModalPaciente, null)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
