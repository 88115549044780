import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import Login from '../views/pages/Login';
import Register from '../views/pages/Register';
import RegisterSuccess from '../views/pages/RegisterSuccess';
import Profile from '../views/pages/Terapeuta';
import Dashboard from '../views/pages/Dashboard';
import Pacientes from '../views/pages/Pacientes';
import PacientesStore from '../views/pages/Pacientes/pacienteStore';
import PacientesProfile from '../views/pages/Pacientes/pacienteProfile';

import CategoriasRegistro from '../views/pages/categoriasRegistro';
import CategoriaRegistroStore from '../views/pages/categoriasRegistro/store';
import CategoriaRegistroUpdate from '../views/pages/categoriasRegistro/update';

import CategoriasAgendamento from '../views/pages/categoriasAgendamento';
import CategoriaAgendamentoStore from '../views/pages/categoriasAgendamento/store';
import CategoriaAgendamentoUpdate from '../views/pages/categoriasAgendamento/update';

import RegistrosAtendimentos from '../views/pages/registrosAtendimentos';
import RegistrosAtendimentoStore from '../views/pages/registrosAtendimentos/store';
import RegistrosAtendimentoUpdate from '../views/pages/registrosAtendimentos/update';

import RegistrosCompartilhados from '../views/pages/registrosCompartilhados';
import RegistrosCompartilhadosView from '../views/pages/registrosCompartilhados/view';

import FinanceiroDashboard from '~/views/pages/Financeiro/Dashboard';
import FinanceiroContas from '~/views/pages/Financeiro/Contas';
import FinanceiroContasUpdate from '~/views/pages/Financeiro/Contas/update';

import FinanceiroCardsStore from '~/views/pages/Financeiro/Cards/store';
import FinanceiroCardsUpdate from '~/views/pages/Financeiro/Cards/update';

import FinanceiroCategorias from '~/views/pages/Financeiro/Categorias';
import FinanceiroCategoriasUpdate from '~/views/pages/Financeiro/Categorias/update';

import FinanceiroRegistros from '~/views/pages/Financeiro/Registros';

import Activation from '../views/pages/Activation';

import ForgotPassword from '../views/pages/forgotPassword';
import NewPassword from '../views/pages/newPassword';

import ListaEspera from '../views/pages/listaEspera';

import Support from '../views/pages/Support';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/terapeuta-login" component={Login} />
      <Route path="/terapeuta-cadastro" component={Register} />
      <Route path="/cadastro-success" component={RegisterSuccess} />

      <Route path="/terapeuta-profile/:id" isPrivate component={Profile} />

      <Route path="/terapeuta/dashboard" isPrivate component={Dashboard} />

      <Route path="/terapeuta/pacientes/" isPrivate component={Pacientes} />
      <Route
        path="/terapeuta/paciente/novo"
        isPrivate
        component={PacientesStore}
      />
      <Route
        path="/terapeuta/paciente/perfil/:id"
        isPrivate
        component={PacientesProfile}
      />

      <Route
        path="/terapeuta/categorias-registros"
        isPrivate
        component={CategoriasRegistro}
      />
      <Route
        path="/terapeuta/categorias-registro/novo"
        isPrivate
        component={CategoriaRegistroStore}
      />
      <Route
        path="/terapeuta/categorias-registro/editar/:id"
        isPrivate
        component={CategoriaRegistroUpdate}
      />

      <Route
        path="/terapeuta/categorias-agendamentos"
        isPrivate
        component={CategoriasAgendamento}
      />
      <Route
        path="/terapeuta/categorias-agendamento/novo"
        isPrivate
        component={CategoriaAgendamentoStore}
      />
      <Route
        path="/terapeuta/categorias-agendamento/editar/:id"
        isPrivate
        component={CategoriaAgendamentoUpdate}
      />

      <Route
        path="/terapeuta/registros-atendimentos"
        isPrivate
        component={RegistrosAtendimentos}
      />
      <Route
        path="/terapeuta/registros-atendimento/novo"
        isPrivate
        component={RegistrosAtendimentoStore}
      />
      <Route
        path="/terapeuta/registros-atendimento/editar/:id"
        isPrivate
        component={RegistrosAtendimentoUpdate}
      />

      <Route
        path="/terapeuta/registros-compartilhados"
        exact
        isPrivate
        component={RegistrosCompartilhados}
      />
      <Route
        path="/terapeuta/registro-compartilhado/visualizar/:id"
        isPrivate
        component={RegistrosCompartilhadosView}
      />

      <Route
        path="/terapeuta/financeiro"
        exact
        isPrivate
        component={FinanceiroDashboard}
      />

      <Route
        path="/terapeuta/financeiro/contas"
        exact
        isPrivate
        component={FinanceiroContas}
      />
      <Route
        path="/terapeuta/financeiro/contas/editar/:id"
        isPrivate
        component={FinanceiroContasUpdate}
      />

      <Route
        path="/terapeuta/financeiro/cards/novo"
        isPrivate
        component={FinanceiroCardsStore}
      />
      <Route
        path="/terapeuta/financeiro/cards/editar/:id"
        isPrivate
        component={FinanceiroCardsUpdate}
      />

      <Route
        path="/terapeuta/financeiro-categorias"
        exact
        isPrivate
        component={FinanceiroCategorias}
      />
      <Route
        path="/terapeuta/financeiro-categorias/editar/:id"
        isPrivate
        component={FinanceiroCategoriasUpdate}
      />

      <Route
        path="/terapeuta/financeiro/movimentacoes"
        exact
        isPrivate
        component={FinanceiroRegistros}
      />

      <Route path="/terapeuta/support" exact isPrivate component={Support} />

      <Route path="/terapeuta-activation/:token" component={Activation} />

      <Route path="/terapeuta-forgot-password" component={ForgotPassword} />
      <Route
        path="/terapeuta-new-password/:recover_password_token"
        component={NewPassword}
      />

      <Route path="/terapeuta-lista-espera" component={ListaEspera} />

      <Redirect from="*" to="/terapeuta-login" />
    </Switch>
  );
}
