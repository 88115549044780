import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  selectCategorias: [],
  selectCatsPais: [],
  financeiroCategoria: {
    id: null,
    name: null,
    color: null,
    closeModal: false,
  },
  financeiroCategorias: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  addModal: false,
  categoriaId: null,
  categoriaName: null,
};

export default function financeiroCategorias(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroCategorias/INDEX_REQUEST_SUCCESS': {
        draft.financeiroCategorias.total = action.payload.total;
        draft.financeiroCategorias.perPage = action.payload.perPage;
        draft.financeiroCategorias.page = action.payload.page;
        draft.financeiroCategorias.lastPage = action.payload.lastPage;
        draft.financeiroCategorias.data = action.payload.data;
        draft.loading = false;

        break;
      }
      case '@financeiroCategorias/CATEGORIA_REQUEST_SUCCESS': {
        draft.financeiroCategoria.id = action.payload.data.id;
        draft.financeiroCategoria.name = action.payload.data.name;
        draft.financeiroCategoria.color = action.payload.data.color;
        draft.loading = false;
        break;
      }
      case '@financeiroCategorias/STORE_SUCCESS': {
        draft.financeiroCategoria.id = action.payload.data.id;
        draft.financeiroCategoria.name = action.payload.data.name;
        draft.financeiroCategoria.color = action.payload.data.color;
        draft.financeiroCategoria.closeModal = action.payload.closeModal;
        draft.loading = false;
        break;
      }
      case '@financeiroCategorias/SELECT_REQUEST_SUCCESS': {
        draft.selectCategorias = action.payload.data;
        break;
      }
      case '@financeiroCategorias/SELECT_PAIS_SUCCESS': {
        draft.selectCatsPais = action.payload.data;
        break;
      }
      case '@financeiroCategorias/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        draft.categoriaId = action.payload.categoriaId;
        draft.categoriaName = action.payload.categoriaName;
        break;
      }
      case '@financeiroCategorias/INDEX_REQUEST':
      case '@financeiroCategorias/DELETE_REQUEST':
      case '@financeiroCategorias/CATEGORIA_REQUEST':
      case '@financeiroCategorias/UPDATE_REQUEST':
      case '@financeiroCategorias/STORE_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
