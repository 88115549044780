import produce from 'immer';

const INITIAL_STATE = {
  addModal: false,
  loading: false,
  categoriaRegistro: {
    id: null,
    name: null,
    color: null,
    icon: null,
    compartilhamento_id: null,
  },
  categoriasRegistro: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
};

export default function categoriasRegistro(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@categoriasRegistro/INDEX_REQUEST_SUCCESS': {
        draft.categoriasRegistro.total = Number(action.payload.total);
        draft.categoriasRegistro.perPage = Number(action.payload.perPage);
        draft.categoriasRegistro.page = Number(action.payload.page);
        draft.categoriasRegistro.lastPage = Number(action.payload.lastPage);
        draft.categoriasRegistro.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@categoriasRegistro/CATEGORIA_REGISTRO_REQUEST_SUCCESS': {
        draft.categoriaRegistro.id = action.payload.id;
        draft.categoriaRegistro.name = action.payload.name;
        draft.categoriaRegistro.color = action.payload.color;
        draft.categoriaRegistro.icon = action.payload.icon;
        draft.categoriaRegistro.compartilhamento_id =
          action.payload.compartilhamento_id;
        draft.loading = false;
        break;
      }
      case '@categoriasRegistro/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        break;
      }
      case '@categoriasRegistro/FAILURE': {
        draft.loading = false;
        break;
      }
      case '@categoriasRegistro/CATEGORIA_REGISTRO_REQUEST':
      case '@categoriasRegistro/STORE_REQUEST':
      case '@categoriasRegistro/INDEX_REQUEST':
      case '@categoriasRegistro/UPDATE_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
