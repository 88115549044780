import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';
import ValidationError from '~/components/ValidationError';

import { togleModalAdd as togleFechaModalAdd } from '~/store/modules/pacientes/actions';

export default function SelectPacientes({ defaultValue }) {
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(
    'paciente_id',
  );

  /** Variaveis para montar o select create */
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [value, setValue] = useState(null);
  const [pacienteIdSel, setpacienteIdSel] = useState(null);

  const selectPacientes = useSelector(
    (state) => state.pacientes.pacientes.data,
  );
  const addModal = useSelector((state) => state.pacientes.addModal);

  useEffect(() => {
    if (defaultValue && defaultValue.id && defaultValue.name) {
      setpacienteIdSel(defaultValue.id);
      setValue({ label: defaultValue.name, value: defaultValue.id });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!addModal) {
      setIsLoading(false);
    }
  }, [addModal]);

  const togleModal = useCallback(
    (v, tp) => {
      dispatch(togleFechaModalAdd(v, tp));
      if (tp === 1) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setValue(null);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const nArray = [];
    selectPacientes.forEach((e) => {
      nArray.push({ label: e.name, value: e.id });
    });
    setOption(nArray);
  }, [selectPacientes]);

  const handleChange = useCallback(
    (newValue) => {
      clearError();
      setValue(newValue || null);
      setpacienteIdSel(newValue ? newValue.value : null);
    },
    [clearError],
  );

  const handleCreate = useCallback(
    (inputValue) => {
      togleModal(true, { name: inputValue });
      setIsLoading(true);
    },
    [togleModal],
  );

  return (
    <>
      <input ref={inputRef} defaultValue={pacienteIdSel} type="hidden" />
      <CreatableSelect
        placeholder="Selecione um cliente"
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={option}
        value={value}
        formatCreateLabel={(i) => `Novo: "${i}"`}
      />
      <ValidationError error={error} />
    </>
  );
}

SelectPacientes.propTypes = {
  defaultValue: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

SelectPacientes.defaultProps = {
  defaultValue: null,
};
