import { takeLatest, all, call, put } from 'redux-saga/effects';
import { failure } from './actions';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

export function* indexRequest({payload}) {
  try {
    const { token } = payload;
    const response = yield call(api.put, '/terapeuta-activation', {
      token
    });
    if(response.status === 204){
      toast.success("Ativação realizada com sucesso, você ja pode realizar login na plataforma!");
      history.push('/terapeuta-login');
    }else{
      toast.error("Token inválido");
      history.push('/terapeuta-cadastro');
    }
  } catch (err) {
    yield put(failure());
    toast.error("Token inválido");
    history.push('/terapeuta-cadastro');
  }
}

export default all([
  takeLatest('@activation/INDEX_REQUEST', indexRequest)
]);
