/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// reactstrap components
import { Button, Modal, Row, Col, Tooltip } from 'reactstrap';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  DatePicker,
  TextArea,
  Select,
  CheckboxInput,
} from '../../../components/Input';

import SelectCategoriasAgendamento from './components/selectCategoriasAgendamento';
import SelectPacientes from './components/selectPacientes';
import StoreCatAgendamento from '../categoriasAgendamento/store';
import PacienteQuickStore from '../Pacientes/pacienteQuickStore';
import {
  togleFechaModalAdd as togleFechaModalCategoria,
  storeRequest,
} from '../../../store/modules/agendamentos/actions';

export default function NovoAgendamento() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [tooltipCat, setTooltipCat] = useState(false);
  const [tooltipPac, setTooltipPac] = useState(false);
  const addModal = useSelector((state) => state.agendamentos.addModal);
  const newDate = useSelector((state) => state.agendamentos.newDate);
  const [repDisable, setRepDisable] = useState({ disabled: 'disabled' });

  const agendamentoCategoria = useSelector(
    (state) => state.categoriasAgendamento.categoriaAgendamento,
  );
  const paciente = useSelector((state) => state.pacientes.pacienteSelected);

  async function handleCadastroAgendamento(data) {
    try {
      const schema = Yup.object().shape({
        initial_date: Yup.string().required('Data é obrigatória').nullable(),
        paciente_id: Yup.string().required('Selecione um cliente'),
        agendamento_categoria_id: Yup.string().required(
          'Selecione uma categoria',
        ),
        observation: Yup.string(),
        agendamentoModo: Yup.string().required(
          'Selecione um modo de agendamento',
        ),
        final_date: Yup.string().when('agendamentoModo', {
          is: '2' || 2,
          then: Yup.string().required('Selecione a data final.').nullable(),
          otherwise: Yup.string().nullable(),
        }),
        tp_repeticao: Yup.string().when('agendamentoModo', {
          is: '2' || 2,
          then: Yup.string().required('Selecione um modo de recorrência.'),
          otherwise: Yup.string().nullable(),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        paciente_id,
        agendamento_categoria_id,
        observation,
        agendamentoModo,
        initial_date,
        final_date,
        tp_repeticao,
      } = data;

      dispatch(
        storeRequest(
          tp_repeticao,
          agendamentoModo,
          paciente_id,
          agendamento_categoria_id,
          observation,
          initial_date,
          final_date,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  function togleModal(value) {
    setRepDisable({ disabled: 'disabled' });
    dispatch(togleFechaModalCategoria(value));
  }

  function togleRecorrente(e) {
    if (e.checked && Number(e.value) === 2) {
      setRepDisable(null);
    } else {
      setRepDisable({ disabled: 'disabled' });
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={addModal}
        toggle={() => togleModal(!addModal)}
      >
        <Form ref={formRef} onSubmit={handleCadastroAgendamento}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Criar novo agendamento
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-name">
                  Categoria &nbsp;
                  <a
                    className="table-action"
                    href="#empoderaTerapeuta"
                    id="tooltipCat"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fa fa-info-circle" />
                  </a>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipCat}
                    target="tooltipCat"
                    toggle={() => setTooltipCat(!tooltipCat)}
                  >
                    Para criar uma nova categoria basta digitar o nome dela e
                    clicar em Nova:
                  </Tooltip>
                </label>
                <SelectCategoriasAgendamento
                  defaultValue={agendamentoCategoria}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-name">
                  Cliente &nbsp;
                  <a
                    className="table-action"
                    href="#empoderaTerapeuta"
                    id="tooltipPac"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fa fa-info-circle" />
                  </a>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipPac}
                    target="tooltipPac"
                    toggle={() => setTooltipPac(!tooltipPac)}
                  >
                    Para cadastrar um novo cliente basta digitar o nome e
                    clicar em Novo:
                  </Tooltip>
                </label>
                <SelectPacientes defaultValue={paciente} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="initial_date">
                  Dia e horário
                </label>

                <br />
                <DatePicker
                  name="initial_date"
                  defaultValue={newDate}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="horarios"
                  dateFormat="dd/MM/yyyy HH:mm"
                />
              </Col>
            </Row>
            <br />
            <label className="form-control-label " htmlFor="input-titulo">
              Modo de agendamento
            </label>
            <hr className="mt-0" />
            <Row>
              <Col lg="12">
                <CheckboxInput
                  name="agendamentoModo"
                  checked={1}
                  options={[
                    { id: 'rec1', value: 1, label: 'Único' },
                    { id: 'rec2', value: 2, label: 'Recorrente' },
                  ]}
                  onClick={(e) => togleRecorrente(e.target)}
                />
              </Col>
            </Row>
            {!repDisable && (
              <Row>
                <Col lg="6">
                  <label
                    className="form-control-label"
                    htmlFor="selectPeriod"
                    style={{ visibility: 'hidden' }}
                  >
                    &nbsp;
                  </label>

                  <Select
                    id="selectPeriod"
                    name="tp_repeticao"
                    className="form-control "
                    options={{
                      placeholder: 'Recorrência',
                    }}
                    data={[
                      { id: 'Diario', name: 'Diariamente' },
                      { id: 'Semanalmente', name: 'Semanalmente' },
                      { id: 'Quinzenal', name: 'Quinzenalmente' },
                      { id: 'Mensal', name: 'Mensalmente' },
                      { id: 'Anual', name: 'Anualmente' },
                    ]}
                  />
                </Col>
                <Col lg="6">
                  <label
                    className="form-control-label"
                    htmlFor="input-num_repeticao"
                  >
                    Data final
                  </label>

                  <DatePicker name="final_date" dateFormat="dd/MM/yyyy" />
                </Col>
                <br />
              </Row>
            )}
            <hr />
            <Row>
              <Col lg="12">
                <label>Notas</label>
                <TextArea name="observation" />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </div>
        </Form>
      </Modal>
      <StoreCatAgendamento />
      <PacienteQuickStore />
    </>
  );
}
