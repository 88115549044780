import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

// reactstrap components
import { Button, Modal, Row, Col, FormGroup } from 'reactstrap';

import { Select, InputMasked } from '~/components/Input';

import { transformMoeda } from '~/helpers/transform';
import { togleFechaModalAdd as togleModalCard } from '~/store/modules/financeiroCards/actions';
import { indexRequest as indexContas } from '~/store/modules/financeiroContas/actions';
import { updateFaturaRequest } from '~/store/modules/financeiroRegistros/actions';

export default function UpdateFaturas({ fatura }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const updateModal = useSelector((state) => state.financeiroCards.addModal);
  const contas = useSelector(
    (state) => state.financeiroContas.financeiroContas.data,
  );
  const { id: cardId } = useParams();
  useEffect(() => {
    dispatch(indexContas());
  }, [dispatch]);

  function togleModal(value) {
    dispatch(togleModalCard(value));
  }

  async function handleSubmit(data) {
    try {
      if (data.data_pagamento) {
        data.data_pagamento = moment(
          data.data_pagamento,
          ['DD/MM/YYYY HH:mm'],
          'pt-br',
        ).format('YYYY-MM-DD HH:mm:ss');
      }

      const schema = Yup.object().shape({
        valor_efetivo: Yup.string().required(),
        data_pagamento: Yup.date().required(),
        desconto: Yup.string(),
        juros: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const financeiro_situation_id = 3;
      const { juros, desconto, financeiro_conta_id, data_pagamento } = data;

      const { valor_efetivo } = data;
      const { id } = fatura;

      await dispatch(
        updateFaturaRequest(
          id,
          cardId,
          data_pagamento,
          financeiro_situation_id,
          transformMoeda(valor_efetivo, 1),
          juros,
          desconto,
          financeiro_conta_id,
        ),
      );

      togleModal(!updateModal);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={updateModal}
        toggle={() => togleModal(!updateModal)}
        size="lg"
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Pagar Fatura
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!updateModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="8">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="financeiroContas"
                  >
                    Conta
                  </label>
                  <Select
                    id="financeiroContas"
                    name="financeiro_conta_id"
                    className="form-control"
                    options={{
                      placeholder: 'Selecione uma conta para pagamento',
                    }}
                    data={contas}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <label className="form-control-label" htmlFor="input-pagto">
                  Data Pagamento
                </label>
                <InputMasked
                  id="input-pagto"
                  name="data_pagamento"
                  className="form-control"
                  placeholder="Dia para pagamento"
                  defaultValue={moment(
                    fatura ? fatura.data_vencimento : new Date(),
                  ).format('DD/MM/YYYY')}
                  kind="custom"
                  options={{
                    mask: '99/99/9999',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <label className="form-control-label" htmlFor="input-desconto">
                  Desconto / Taxas
                </label>
                <InputMasked
                  id="input-desconto"
                  name="desconto"
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                  defaultValue={null}
                />
              </Col>
              <Col lg="4">
                <label className="form-control-label" htmlFor="input-juros">
                  Juros / Multa
                </label>
                <InputMasked
                  id="input-juros"
                  name="juros"
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                  defaultValue={null}
                />
              </Col>
              <Col lg="4">
                <label
                  className="form-control-label"
                  htmlFor="input-valor-efetivo"
                >
                  Valor efetivo
                </label>
                <InputMasked
                  id="input-valor-efetivo"
                  name="valor_efetivo"
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                  defaultValue={
                    fatura
                      ? transformMoeda(Math.abs(fatura.valor_base), 2)
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!updateModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Pagar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

UpdateFaturas.propTypes = {
  fatura: PropTypes.objectOf(PropTypes.any).isRequired,
};
