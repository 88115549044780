import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  editModal: false,
  compartilhamento: {
    id: null,
    compartilhamento_id: null,
    terapeutas: [],
    invites: []
  },
  compartilhamentos: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: []
  }
};

export default function compartilhamentos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@compartilhamentos/INDEX_REQUEST_SUCCESS': {
        draft.compartilhamentos.total = action.payload.total;
        draft.compartilhamentos.perPage = action.payload.perPage;
        draft.compartilhamentos.page = action.payload.page;
        draft.compartilhamentos.lastPage = action.payload.lastPage;
        draft.compartilhamentos.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@compartilhamentos/COMPARTILHAMENTO_REQUEST_SUCCESS': {
        draft.compartilhamento.id = action.payload.id;
        draft.compartilhamento.compartilhamento_id = action.payload.compartilhamento_id;
        draft.compartilhamento.terapeutas = action.payload.terapeutas;
        draft.compartilhamento.invites = action.payload.invites;
        draft.editModal = true;
        break;
      }
      case '@compartilhamentos/FECHA_EDIT_MODAL': {
        draft.editModal = action.payload.modal;
        break;
      }
      case '@compartilhamentos/FAILURE': {
        draft.loading = false;
        break;
      }
      case '@compartilhamentos/COMPARTILHAMENTO_UPDATE_REQUEST':
      case '@compartilhamentos/COMPARTILHAMENTO_DELETE_REQUEST':
      case '@compartilhamentos/INVITE_DELETE_REQUEST':
      case '@compartilhamentos/INVITE_REQUEST':
      case '@compartilhamentos/COMPARTILHAMENTO_REQUEST':
      case '@compartilhamentos/SELECT_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
