import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import RegisterHeader from '../../../components/Headers/RegisterHeader';
import {
  signUpRequest,
  terapeutaTiposRequest,
} from '../../../store/modules/auth/actions';
// core components
import Input, { Select } from '../../../components/Input';

export default function Register() {
  const [tipoSelected, setTipoSelected] = useState(null);
  const [focusedOutro, setFocusedOutro] = useState(false);
  const [focusedName, setFocusedName] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [
    focusedPasswordConfirmation,
    setFocusedPasswordConfirmation,
  ] = useState(false);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const terapeutaTipos = useSelector((state) => state.auth.terapeutaTipos);

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        terapeuta_tipo_id: Yup.string().required(
          'Selecione uma área de atuação',
        ),
        tipo_outro: Yup.string().when('terapeuta_tipo_id', {
          is: '1' || 1,
          then: Yup.string().required('Informe a área de atuação.').nullable(),
          otherwise: Yup.string().nullable(),
        }),
        name: Yup.string()
          .required('Preecha seu nome')
          .matches(/[a-zA-Z]/, 'O campo nome só deve conter letras'),
        email: Yup.string()
          .email('Insira um e-mail valido')
          .required('E-mail é obrigatório'),
        password: Yup.string()
          .required('Senha é obrigatória.')
          .min(8, 'Passowrd muito curto - precisa pelo menos 8 caracteres.'),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Senha e confirmação não conferem',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      const {
        terapeuta_tipo_id,
        tipo_outro,
        name,
        email,
        password,
        password_confirmation,
      } = data;
      dispatch(
        signUpRequest(
          terapeuta_tipo_id,
          tipo_outro,
          name,
          email,
          password,
          password_confirmation,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const handleChageTipo = (e) => {
    setTipoSelected(Number(e));
  };

  useEffect(() => {
    dispatch(terapeutaTiposRequest());
  }, [dispatch]);

  return (
    <>
      <RegisterHeader title="Crie sua conta" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form
                  role="form"
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="needs-validation"
                >
                  <FormGroup>
                    <Select
                      name="terapeuta_tipo_id"
                      className="form-control"
                      options={{
                        placeholder: 'Selecione uma área de atuação',
                      }}
                      onChange={(e) => {
                        handleChageTipo(e);
                      }}
                      data={terapeutaTipos}
                    />
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedOutro,
                    })}
                    style={{
                      display: tipoSelected === 1 ? 'inline' : 'none',
                    }}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-paper-diploma" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="tipo_outro"
                        placeholder="informe sua área de atuação"
                        className="form-control"
                        type="text"
                        onFocus={() => setFocusedOutro(true)}
                        onBlur={() => setFocusedOutro(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Nome"
                        className="form-control"
                        type="text"
                        onFocus={() => setFocusedName(true)}
                        onBlur={() => setFocusedName(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        type="email"
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Senha"
                        className="form-control"
                        type="password"
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPasswordConfirmation,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password_confirmation"
                        placeholder="Confirmação de senha"
                        className="form-control"
                        type="password"
                        onFocus={() => setFocusedPasswordConfirmation(true)}
                        onBlur={() => setFocusedPasswordConfirmation(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="mt-4" color="info" type="submit">
                      {loading ? '... carregando' : 'Cadastrar'}
                    </Button>
                  </div>
                  <br />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
