export function indexRequest(prontuario_id) {
  return {
    type: '@registroComments/INDEX_REQUEST',
    payload: { prontuario_id }
  };
}

export function indexRequestSuccess(data) {
  return {
    type: '@registroComments/INDEX_REQUEST_SUCCESS',
    payload: { data }
  };
}

export function deleteRequest(comment_id, prontuario_id) {
  return {
    type: '@registroComments/DELETE_REQUEST',
    payload: { comment_id, prontuario_id }
  };
}

export function storeRequest(prontuario_id, comment) {
  return {
    type: '@registroComments/STORE_REQUEST',
    payload: { prontuario_id, comment }
  };
}

export function failure() {
  return {
    type: '@registroComments/FAILURE'
  };
}
