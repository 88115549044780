import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import * as Yup from 'yup';

// reactstrap components
import {
  Button,
  FormGroup,
  Row,
  Col,
  CardBody,
  Container,
  Card,
  CardHeader,
} from 'reactstrap';
import { Form } from '@unform/web';

import MainHeader from '~/components/Headers/MainHeader';
import Input, { InputMasked, BuscaEndereco } from '~/components/Input';
import UpdatePassword from './components/UpdatePassword';

import { updateRequest, showRequest } from '~/store/modules/terapeuta/actions';

export default function Profile() {
  const { id } = useParams();
  const terapeuta = useSelector((state) => state.terapeuta.terapeuta);
  const loading = useSelector((state) => state.terapeuta.loading);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showRequest(id));
  }, [dispatch, id]);

  async function handleSubmit(data) {
    if (data.nascimento) {
      data.nascimento = moment(
        data.nascimento,
        ['MM-DD-YYYY', 'DD-MM-YYYY'],
        'pt-br',
      ).format('YYYY-MM-DD');
    }
    try {
      const schema = Yup.object().shape({
        name: Yup.string(),
        email: Yup.string().email(),
        celular: Yup.string(),
        telefone: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        name,
        email,
        celular,
        telefone,
        nascimento,
        cep,
        uf,
        cidade,
        bairro,
        endereco,
        numero,
        complemento,
      } = data;

      dispatch(
        updateRequest(
          terapeuta.id,
          name,
          email,
          celular,
          telefone,
          nascimento,
          cep,
          uf,
          cidade,
          bairro,
          endereco,
          numero,
          complemento,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }
  return (
    <>
      <MainHeader
        name="Meus Dados"
        parentName="Perfil"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="card-header">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Meus Dados</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="needs-validation"
            >
              <Row>
                <Col lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Nome
                    </label>
                    <Input
                      name="name"
                      id="input-name"
                      placeholder="Nome completo"
                      type="text"
                      defaultValue={terapeuta ? terapeuta.name : null}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-nascimento"
                    >
                      Data de nasicmento
                    </label>
                    <InputMasked
                      defaultValue={
                        terapeuta.nascimento
                          ? moment(terapeuta.nascimento).format('DD/MM/YYYY')
                          : null
                      }
                      name="nascimento"
                      className="form-control"
                      placeholder="Nascimento"
                      kind="custom"
                      options={{
                        mask: '99/99/9999',
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      E-mail
                    </label>
                    <Input
                      name="email"
                      id="input-email"
                      placeholder="E-mail"
                      type="text"
                      defaultValue={terapeuta.email ? terapeuta.email : null}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-celular"
                    >
                      Celular
                    </label>
                    <InputMasked
                      defaultValue={terapeuta ? terapeuta.celular : null}
                      name="celular"
                      className="form-control"
                      placeholder="Fone ex: (99) 99999-9999"
                      kind="cel-phone"
                      options={{
                        maskType: 'BRL',
                        withDDD: true,
                        dddMask: '(99) ',
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-telefone"
                    >
                      Telefone
                    </label>
                    <InputMasked
                      defaultValue={terapeuta ? terapeuta.telefone : null}
                      name="telefone"
                      className="form-control"
                      placeholder="Fone ex: 99999-9999"
                      kind="cel-phone"
                      options={{
                        maskType: 'BRL',
                        withDDD: true,
                        dddMask: '(99) ',
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-cep">
                      Cep
                    </label>
                    <BuscaEndereco
                      name="cep"
                      defaultValue={terapeuta.cep ? terapeuta.cep : null}
                      formRef={formRef}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-0" md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-endereco"
                    >
                      Endereço
                    </label>
                    <Input
                      defaultValue={
                        terapeuta.endereco ? terapeuta.endereco : null
                      }
                      name="endereco"
                      id="input-endereco"
                      placeholder="Endereço"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-0" md="2">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-numero"
                    >
                      Numero
                    </label>
                    <Input
                      defaultValue={terapeuta.numero ? terapeuta.numero : null}
                      name="numero"
                      id="input-numero"
                      placeholder="Número"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-complemento"
                    >
                      Complemento
                    </label>
                    <Input
                      defaultValue={
                        terapeuta.complemento ? terapeuta.complemento : null
                      }
                      name="complemento"
                      id="input-complemento"
                      placeholder="Complemento"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-bairro"
                    >
                      Bairro
                    </label>
                    <Input
                      defaultValue={terapeuta.bairro ? terapeuta.bairro : null}
                      name="bairro"
                      id="input-bairro"
                      placeholder="Bairro"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-cidade"
                    >
                      Cidade
                    </label>
                    <Input
                      defaultValue={terapeuta.cidade ? terapeuta.cidade : null}
                      name="cidade"
                      id="input-cidade"
                      placeholder="Cidade"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-0" md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-uf">
                      UF
                    </label>
                    <Input
                      defaultValue={terapeuta.uf ? terapeuta.uf : null}
                      name="uf"
                      id="input-uf"
                      placeholder="Estado"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-center">
                <Button className="my-4" color="info" type="submit">
                  {loading ? 'Carregando...' : 'Salvar'}
                </Button>
              </div>
            </Form>
            <UpdatePassword />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
