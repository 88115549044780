import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  selectContas: [],
  financeiroConta: {
    name: null,
    conta_tipo_id: null,
    saldo_atual: null,
  },
  financeiroContas: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
  },
  extrato: [],
  addModal: false,
};

export default function financeiroContas(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroContas/INDEX_REQUEST_SUCCESS': {
        draft.financeiroContas.total = action.payload.total;
        draft.financeiroContas.perPage = action.payload.perPage;
        draft.financeiroContas.page = action.payload.page;
        draft.financeiroContas.lastPage = action.payload.lastPage;
        draft.financeiroContas.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@financeiroContas/EXTRATO_REQUEST_SUCCESS': {
        draft.extrato = action.payload.data.data;
        draft.loading = false;
        break;
      }
      case '@financeiroContas/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        break;
      }

      case '@financeiroContas/SELECT_REQUEST_SUCCESS': {
        draft.selectContas = action.payload.data;
        break;
      }
      case '@financeiroContas/CONTA_REQUEST_SUCCESS': {
        draft.financeiroConta.name = action.payload.data.name;
        draft.financeiroConta.conta_tipo_id = action.payload.data.conta_tipo_id;
        draft.financeiroConta.saldo_atual = action.payload.data.saldo_atual;
        break;
      }
      case '@financeiroContas/INDEX_REQUEST':
      case '@financeiroContas/DELETE_REQUEST':
      case '@financeiroContas/FAILURE':
      case '@financeiroContas/EXTRATO_REQUEST':
      case '@financeiroContas/STORE_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
