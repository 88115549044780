import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import {
  indexRequestSuccess,
  storeRequestSuccess,
  registroAtendimentoRequestSuccess,
  failure,
  indexRequest as indexRequestRegistros,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/paciente-registros/${id}`);

    if (response.data) {
      yield put(indexRequestSuccess(response.data));
    }
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      agendamento_id,
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    } = payload;

    yield call(api.post, 'terapeutas/prontuarios', {
      agendamento_id,
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    });

    yield put(storeRequestSuccess());
    yield put(indexRequestRegistros(paciente_id));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* registroAtendimentoRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `terapeutas/prontuarios/${id}`);
    const {
      prontuario_categoria_id,
      paciente_id,
      title,
      conteudo,
      files,
    } = response.data;

    yield put(
      registroAtendimentoRequestSuccess(
        id,
        paciente_id,
        prontuario_categoria_id,
        title,
        conteudo,
        files,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    } = payload;
    yield call(api.put, `terapeutas/prontuarios/${id}`, {
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    });
    yield put(indexRequestRegistros(paciente_id));

    toast.success('Registro atualizado com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest(
    '@pacientesRegistro/REGISTRO_ATENDIMENTO_REQUEST',
    registroAtendimentoRequest,
  ),
  takeLatest('@pacientesRegistro/INDEX_REQUEST', indexRequest),
  takeLatest('@pacientesRegistro/STORE_REQUEST', storeRequest),
  takeLatest('@pacientesRegistro/UPDATE_REQUEST', updateRequest),
]);
