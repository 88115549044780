import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
// reactstrap components
import { Button, Modal, Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { DatePicker, TextArea, Select } from '../../../components/Input';

import {
  updateRequest,
  togleFechaModalEdit,
  deleteRequest,
} from '../../../store/modules/agendamentos/actions';

export default function NovoAgendamento() {
  const formRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const editModal = useSelector((state) => state.agendamentos.editModal);

  const pacientes = useSelector((state) => state.pacientes.pacientes.data);
  const categoriasAgendamento = useSelector(
    (state) => state.categoriasAgendamento.categoriasAgendamento.data,
  );
  const agendamento = useSelector((state) => state.agendamentos.agendamento);

  async function handleCadastro(data) {
    try {
      if (data.agendamento_start) {
        data.agendamento_start = moment(
          data.agendamento_start,
          ['DD/MM/YYYY HH:mm'],
          'pt-br',
        ).format('YYYY-MM-DD HH:mm:ss');
        data.agendamento_start = moment
          .tz(data.agendamento_start, moment.tz.guess())
          .format();
      }
      const schema = Yup.object().shape({
        paciente_id: Yup.number().required('Selecione um paciente'),
        agendamento_categoria_id: Yup.number().required(
          'Selecione uma categoria',
        ),
        agendamento_start: Yup.date().required(
          'Data do agendamento é obrigatória',
        ),
        observation: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        paciente_id,
        agendamento_categoria_id,
        agendamento_start,
        observation,
      } = data;
      dispatch(
        updateRequest(
          agendamento.id,
          paciente_id,
          agendamento_categoria_id,
          agendamento_start,
          observation,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  function togleModal(value) {
    dispatch(togleFechaModalEdit(value));
  }

  const deleteAgendamento = () => {
    dispatch(deleteRequest(agendamento.id));
    setAlert(false);
  };

  const deleteEventSweetAlert = () => {
    setAlert(() => {
      return (
        <ReactBSAlert
          warning
          style={{ display: 'block' }}
          title="Você tem certeza?"
          onConfirm={() => {
            setAlert(false);
          }}
          onCancel={() => deleteAgendamento()}
          confirmBtnCssClass="btn-secondary"
          cancelBtnBsStyle="danger"
          confirmBtnText="Cancelar"
          cancelBtnText="Sim, delete isso"
          showCancel
          btnSize=""
        >
          Essa exclusão não poderá ser desfeita, tem certeza?
        </ReactBSAlert>
      );
    });
  };

  return (
    <>
      {alert}
      <Modal
        className="modal-dialog-centered"
        isOpen={editModal}
        toggle={() => togleModal(!editModal)}
      >
        <Form ref={formRef} onSubmit={handleCadastro}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar agendamento
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!editModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-name">
                  Categoria
                </label>
                <Select
                  name="agendamento_categoria_id"
                  className="form-control"
                  defaultValue={agendamento.agendamento_categoria_id}
                  options={{
                    placeholder: 'Selecione uma categoria',
                  }}
                  data={categoriasAgendamento}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-name">
                  Cliente
                </label>
                <Select
                  name="paciente_id"
                  className="form-control"
                  defaultValue={agendamento.paciente_id}
                  options={{
                    placeholder: 'Selecione um paciente',
                  }}
                  data={pacientes}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-titulo">
                  Dia e horário
                </label>
                <br />
                <DatePicker
                  defaultValue={agendamento.agendamento_start}
                  name="agendamento_start"
                  placeholder="Dia e horário"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="horarios"
                  dateFormat="dd/MM/yyyy HH:mm"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label>Notas</label>
                <TextArea
                  name="observation"
                  defaultValue={agendamento.observation}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!editModal)}
            >
              Fechar
            </Button>
            <Button color="danger" onClick={() => deleteEventSweetAlert()}>
              Deletar
            </Button>
            <Button color="primary" type="submit">
              Atualizar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
