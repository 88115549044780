import React, { useRef } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import { Form } from '@unform/web';
import Input from '../../../components/Input'

// core components
import AuthHeader from "components/Headers/AuthHeader";
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux';
import { recoverPassword } from '../../../store/modules/recuperarSenha/actions';

export default function Login() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading)

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email('Insira um e-mail valido').required('E-mail é obrigatório')
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const { email } = data;
      dispatch(recoverPassword(email));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form ref={formRef} onSubmit={handleSubmit} className="needs-validation">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      {loading ? 'Carregando...' : 'Enviar'}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}


