import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// reactstrap components
import { Card, CardHeader, Table, Row, Col, Button } from 'reactstrap';
import Gravatar from 'react-gravatar';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  toglePacienteFound,
  pacienteAttach,
  storeRequest,
} from '../../../store/modules/pacientes/actions';

export default function PacientesExistentes({ quickStore }) {
  const pacientes = useSelector((state) => state.pacientes.pacientesFound);
  const modal = useSelector((state) => state.pacientes.hasPaciente);
  const pacientePosted = useSelector((state) => state.pacientes.pacientePosted);
  const dispatch = useDispatch();

  function handlerSelecione(paciente) {
    dispatch(pacienteAttach(paciente, quickStore));
  }

  function handlerForceAdd() {
    const {
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
    } = pacientePosted;
    dispatch(
      storeRequest(
        name,
        nascimento,
        email,
        celular,
        telefone,
        cep,
        cidade,
        uf,
        endereco,
        numero,
        complemento,
        bairro,
        true,
      ),
    );
  }
  return (
    <div
      className="body-overlay"
      style={{ display: modal ? 'inline' : 'none' }}
    >
      <div className="main-content" id="panel">
        <div className="container-paciente">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="12">
                      <a
                        href="#empoderaTerapeuta"
                        className="voltar"
                        onClick={() => dispatch(toglePacienteFound(!modal))}
                      >
                        <i className="ni ni-bold-left" />
                        <span>VOLTAR</span>
                      </a>

                      <h3 className="mb-0">
                        <i className="ni ni-bell-55" />{' '}
                        <span>Atenção Terapeuta!</span>
                      </h3>
                      <p>
                        Parece que este paciente que você tentou cadastrar já
                        existe no Empodera Terapeuta!
                      </p>

                      <p>
                        Verifique se algum dos pacientes abaixo é o mesmo que
                        você está tentando cadastrar, caso seja apenas selecione
                        ele.
                        <br />
                        Caso não seja nenhum paciente da lista abaixo clique no
                        botão para efetivar o cadastro de um novo paciente no
                        sistema.
                      </p>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Nome</th>
                      <th>Nascimento</th>
                      <th>E-mail</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {pacientes.map((data) => (
                      <tr key={data.id}>
                        <td className="table-user">
                          <Gravatar
                            email={data.email}
                            className="avatar rounded-circle mr-3"
                            default="mp"
                          />
                          <b>{data.name}</b>
                        </td>
                        <td>
                          <span className="text-muted">
                            {moment(data.nascimento).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">{data.email}</span>
                        </td>
                        <td className="table-actions">
                          <Button
                            className="btn-icon btn-3"
                            size="sm"
                            color="secondary"
                            type="button"
                            onClick={() => handlerSelecione(data)}
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-add" />
                            </span>
                            <span className="btn-inner--text">Usar esse</span>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => handlerForceAdd()}
                >
                  Cadastrar como novo paciente
                </Button>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

PacientesExistentes.propTypes = {
  quickStore: PropTypes.bool,
};

PacientesExistentes.defaultProps = {
  quickStore: false,
};
