import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// reactstrap components
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
} from 'reactstrap';
import moment from 'moment-timezone';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import TextInputMask from 'react-masked-text';
import Input, { Select, InputMasked } from '~/components/Input';
import { transformMoeda } from '~/helpers/transform';

import {
  storeRequest as contaStoreRequest,
  togleFechaModalAdd,
} from '~/store/modules/financeiroContas/actions';

import {
  storeRequest as cardStoreRequest,
  togleFechaModalAdd as togleModalCard,
} from '~/store/modules/financeiroCards/actions';

import { indexRequest as indexContasTipos } from '~/store/modules/financeiroContasTipos/actions';
import { indexRequest as indexInstitutions } from '~/store/modules/financeiroInstitutions/actions';

export default function StoreContas({ contas }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const addContaModal = useSelector((state) => state.financeiroContas.addModal);
  const addCardModal = useSelector((state) => state.financeiroCards.addModal);
  const contasTipos = useSelector(
    (state) => state.financeiroContasTipos.financeiroContasTipos.data,
  );
  const institutions = useSelector(
    (state) => state.financeiroInstitutions.financeiroInstitutions.data,
  );

  const [contaTipoValue, setContaTipoValue] = useState(0);
  const [contaValue, setContaValue] = useState(null);
  const [dateValue, setDateValue] = useState('');
  const [institutionValue, setInstitutionValue] = useState(0);
  useEffect(() => {
    setContaTipoValue(4);
  }, [addCardModal]);

  useEffect(() => {
    setContaTipoValue(0);
  }, [addContaModal]);

  useEffect(() => {
    dispatch(indexContasTipos());
    dispatch(indexInstitutions());
  }, [dispatch]);

  function togleModal(value) {
    if (addContaModal) {
      dispatch(togleFechaModalAdd(value));
    } else {
      dispatch(togleModalCard(value));
    }
  }

  function onSelectContaTipo(value) {
    setContaTipoValue(Number(value));
  }

  function onSelectConta(value) {
    setContaValue(Number(value));
  }

  function onSelectInstitution(value) {
    setInstitutionValue(Number(value));
  }

  const onChangeDate = (value) => {
    setDateValue(value);
  };

  async function handleCadastro(data) {
    try {
      if (dateValue) {
        data.data_saldo_inicial = moment(
          dateValue,
          ['DD/MM/YYYY HH:mm'],
          'pt-br',
        ).format('YYYY-MM-DD HH:mm:ss');
      }
      if (data.saldo_inicial) {
        data.saldo_inicial = transformMoeda(data.saldo_inicial, 1);
      }
      if (data.limite_total) {
        data.limite_total = transformMoeda(data.limite_total, 1);
      }

      const schema = Yup.object().shape({
        name: Yup.string().required(
          'Informar um nome para a conta é obrigatório',
        ),
        saldo_inicial: Yup.number(),
        data_saldo_inicial: Yup.date(),
        dia_vencimento: Yup.number(),
        dia_fechamento: Yup.number(),
        limite_total: Yup.number(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        name,
        saldo_inicial,
        data_saldo_inicial,
        dia_fechamento,
        dia_vencimento,
        limite_total,
      } = data;

      const conta_tipo_id = contaTipoValue;
      const conta_pagamento_id = contaValue;
      const institution_id = institutionValue;

      if (conta_tipo_id === 4) {
        dispatch(
          cardStoreRequest(
            name,
            conta_tipo_id,
            conta_pagamento_id,
            dia_fechamento,
            dia_vencimento,
            limite_total,
            institution_id,
          ),
        );
      } else {
        dispatch(
          contaStoreRequest(
            name,
            saldo_inicial,
            data_saldo_inicial,
            conta_tipo_id,
            institution_id,
          ),
        );
      }

      togleModal(addContaModal ? !addContaModal : !addCardModal);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={addContaModal || addCardModal}
        toggle={() => {
          togleModal(addContaModal ? !addContaModal : !addCardModal);
        }}
      >
        <Form ref={formRef} onSubmit={handleCadastro}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Criar nova conta
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                togleModal(addContaModal ? !addContaModal : !addCardModal);
              }}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col xs="12">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="name"
                      id="name"
                      placeholder="Nome da conta"
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group mb-3">
                    <Select
                      name="institution_id"
                      className="form-control select-institution"
                      onChange={onSelectInstitution}
                      options={{
                        placeholder: 'Selecione uma instituição bancária',
                      }}
                      data={institutions}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group mb-3">
                    <Select
                      name="conta_tipo_id"
                      className="form-control select-contas"
                      onChange={onSelectContaTipo}
                      options={{
                        placeholder: 'Selecione um tipo de conta',
                      }}
                      data={contasTipos}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            {contaTipoValue !== 4 && (
              <>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-money-bill" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputMasked
                          name="saldo_inicial"
                          id="saldo"
                          className="form-control"
                          placeholder="Saldo da conta"
                          kind="money"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-calendar-check" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <TextInputMask
                          name="date_input"
                          className="form-control"
                          placeholder="Data do saldo"
                          kind="datetime"
                          options={{
                            format: 'DD/MM/YYYY',
                          }}
                          onChangeText={onChangeDate}
                          value={dateValue}
                        />
                        <Input
                          name="data_saldo_inicial"
                          id="data_saldo"
                          type="hidden"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            {contaTipoValue === 4 && (
              <>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="financeiroContas"
                      />
                      <Select
                        id="financeiroContas"
                        name="financeiro_conta_id"
                        className="form-control"
                        onChange={onSelectConta}
                        options={{
                          placeholder:
                            'Selecione uma conta padrão para pagamento',
                        }}
                        data={contas}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-money-bill" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputMasked
                          name="limite_total"
                          id="limite"
                          className="form-control"
                          placeholder="Limite total"
                          kind="money"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-calendar-check" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          name="dia_fechamento"
                          id="fechamento"
                          placeholder="Dia de fechamento"
                          type="number"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-calendar-check" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          name="dia_vencimento"
                          id="vencimento"
                          placeholder="Dia de vencimento"
                          type="number"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                togleModal(addContaModal ? !addContaModal : !addCardModal);
              }}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

StoreContas.propTypes = {
  contas: PropTypes.arrayOf(PropTypes.object).isRequired,
};
