import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import loadingGlobal from './components/loadingGlobal';
import './config/ReactotronConfig';
import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded

import './assets/vendor/sweetalert2/dist/sweetalert2.min.css';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
// eslint-disable-next-line import/no-unresolved
import './assets/scss/argon-dashboard-pro-react.scss?v1.0.0';
import './assets/css/custom.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => loadingGlobal(0), []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <ToastContainer autoClose={3000} style={{ zIndex: 9999 }} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
