export function indexRequest(page, perPage) {
  return {
    type: '@financeiroCards/INDEX_REQUEST',
    payload: { page, perPage },
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroCards/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function storeRequest(
  name,
  conta_tipo_id,
  conta_pagamento_id,
  dia_fechamento,
  dia_vencimento,
  limite_total,
  institution_id,
) {
  return {
    type: '@financeiroCards/STORE_REQUEST',
    payload: {
      name,
      conta_tipo_id,
      conta_pagamento_id,
      dia_fechamento,
      dia_vencimento,
      limite_total,
      institution_id,
    },
  };
}

export function updateRequest(id, name, limite_total) {
  return {
    type: '@financeiroCards/UPDATE_REQUEST',
    payload: {
      id,
      name,
      limite_total,
    },
  };
}

export function deleteRequest(id) {
  return {
    type: '@financeiroCards/DELETE_REQUEST',
    payload: {
      id,
    },
  };
}

export function cardRequest(id) {
  return {
    type: '@financeiroCards/CARD_REQUEST',
    payload: { id },
  };
}

export function cardRequestSuccess(data) {
  return {
    type: '@financeiroCards/CARD_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function faturasRequest(id, dia_vencimento) {
  return {
    type: '@financeiroCards/FATURAS_REQUEST',
    payload: { id, dia_vencimento },
  };
}

export function faturasRequestSuccess(data) {
  return {
    type: '@financeiroCards/FATURAS_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function faturaRequest(id) {
  return {
    type: '@financeiroCards/FATURA_REQUEST',
    payload: { id },
  };
}

export function faturaRequestSuccess(data) {
  return {
    type: '@financeiroCards/FATURA_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function togleFechaModalAdd(modal) {
  return {
    type: '@financeiroCards/FECHA_ADD_MODAL',
    payload: { modal },
  };
}

export function failure() {
  return {
    type: '@financeiroCards/FAILURE',
  };
}
