import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import {
  indexRequestSuccess,
  registroAtendimentoRequestSuccess,
  failure,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage, filters } = payload;
    const response = yield call(
      api.get,
      'terapeutas/registros-compartilhados',
      {
        params: {
          page: tPage,
          perPage: tPerPage,

          ...filters,
        },
      },
    );
    const { total, perPage, page, lastPage, data } = response.data;

    yield put(
      indexRequestSuccess(total, perPage, page, lastPage, data, filters),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* registroAtendimentoRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(
      api.get,
      `terapeutas/registros-compartilhados/${id}`,
    );
    const {
      prontuarioCategoria,
      paciente_id,
      title,
      conteudo,
      paciente,
      files,
    } = response.data;

    yield put(
      registroAtendimentoRequestSuccess(
        prontuarioCategoria.name,
        paciente_id,
        title,
        conteudo,
        paciente,
        files,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export default all([
  takeLatest('@registrosCompartilhados/INDEX_REQUEST', indexRequest),
  takeLatest(
    '@registrosCompartilhados/REGISTRO_ATENDIMENTO_REQUEST',
    registroAtendimentoRequest,
  ),
]);
