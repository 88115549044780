export function storeRequest(
  name,
  nascimento,
  email,
  celular,
  telefone,
  cep,
  cidade,
  uf,
  endereco,
  numero,
  complemento,
  bairro,
  pacienteId,
  forceAdd,
) {
  return {
    type: '@guardians/STORE_REQUEST',
    payload: {
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      pacienteId,
      forceAdd,
    },
  };
}

export function updateRequest(
  id,
  name,
  nascimento,
  email,
  celular,
  telefone,
  cep,
  cidade,
  uf,
  endereco,
  numero,
  complemento,
  bairro,
) {
  return {
    type: '@guardians/UPDATE_REQUEST',
    payload: {
      id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
    },
  };
}

export function guardianRequest(id) {
  return {
    type: '@guardians/GUARDIAN_REQUEST',
    payload: { id },
  };
}

export function guardianRequestSuccess(
  id,
  responsavel_id,
  name,
  nascimento,
  email,
  celular,
  telefone,
  cep,
  cidade,
  uf,
  bairro,
  endereco,
  numero,
  complemento,
) {
  return {
    type: '@guardians/GUARDIAN_REQUEST_SUCCESS',
    payload: {
      id,
      responsavel_id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      bairro,
      endereco,
      numero,
      complemento,
    },
  };
}

export function guardianStoreSuccess(data) {
  return {
    type: '@guardians/GUARDIAN_STORE_SUCCESS',
    payload: { data },
  };
}

export function deleteRequest(id, pacienteId) {
  return {
    type: '@guardians/DELETE_REQUEST',
    payload: { id, pacienteId },
  };
}

export function togleFechaModalAdd(modal) {
  return {
    type: '@guardians/FECHA_ADD_MODAL',
    payload: { modal },
  };
}

export function togleFechaModalEdit(modal) {
  return {
    type: '@guardians/FECHA_EDIT_MODAL',
    payload: { modal },
  };
}

export function guardianFound(guardiansFound, guardianPosted) {
  return {
    type: '@guardians/GUARDIAN_FOUND',
    payload: { guardiansFound, guardianPosted },
  };
}

export function togleGuardianFound(modal) {
  return {
    type: '@guardians/FECHA_MODAL_FOUND',
    payload: { modal },
  };
}

export function guardianAttach(pacienteId, guardian) {
  return {
    type: '@guardians/GUARDIAN_ATTACH',
    payload: { pacienteId, guardian },
  };
}

export function failure() {
  return {
    type: '@guardians/FAILURE',
  };
}
