import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

// reactstrap components
import {
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
} from 'reactstrap';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import { togleFechaModalAdd } from '~/store/modules/modal/actions';
import { UpdatePasswordRequest } from '~/store/modules/terapeuta/actions';

export default function UpdatePassword() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.modal.addModal);
  const terapeuta = useSelector((state) => state.terapeuta.terapeuta);

  function togleModal(value) {
    dispatch(togleFechaModalAdd(value));
  }

  async function handleSubmitPassword(data) {
    try {
      const { oldPassword, newPassword, passwordConfirmation } = data;
      const schema = Yup.object().shape({
        oldPassword: Yup.string().required('Senha atual é obrigatória'),
        newPassword: Yup.string().required('Nova senha é obrigatória'),
        passwordConfirmation: Yup.string().required(
          'Confirmação de senha é obrigatória',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(
        UpdatePasswordRequest(
          terapeuta.id,
          oldPassword,
          newPassword,
          passwordConfirmation,
          showModal,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <Button
        onClick={() => togleModal(!showModal)}
        type="button"
        color="secondary"
      >
        <i className="ni ni-settings-gear-65 align-middle" />
        <span className="align-middle"> Alterar informações de login</span>
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => togleModal(!showModal)}
      >
        <Form ref={formRef} onSubmit={handleSubmitPassword}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Alterar senha
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!showModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col className="mb-0" md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="oldPassword">
                    Senha atual *
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="oldPassword"
                      placeholder="Senha atual"
                      type="password"
                      className="form-control"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="mb-0" md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="newPassword">
                    Nova senha *
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="newPassword"
                      placeholder="Nova senha"
                      type="password"
                      className="form-control"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="mb-0" md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="passwordConfirmation"
                  >
                    Confirmação de senha *
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="passwordConfirmation"
                      placeholder="Confirmação de senha"
                      type="password"
                      className="form-control"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!showModal)}
            >
              Fechar
            </Button>
            <Button
              color="primary"
              onClick={() => handleSubmitPassword(formRef.current.getData())}
            >
              Salvar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
