/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import { dateFormat, transformMoeda, situacaoAtual } from '~/helpers/transform';
import RegistroFinanceiro from './store';
import ImportRegistros from './import';
import Search from './search';
import MainHeader from '~/components/Headers/MainHeader';
import {
  indexRequest,
  togleFechaModalRegistro,
  deleteRequest,
  showRequest,
  quickUpdateRequest,
  togleImportModal,
} from '~/store/modules/financeiroRegistros/actions';
import Paginations from '~/components/Pagination/Paginations';
import loadingGlobal from '~/components/loadingGlobal';

export default function RegistrosFinanceiros() {
  const [alert, setAlert] = useState(null);
  const financeiroModal = useSelector(
    (state) => state.financeiroRegistros.financeiroModal,
  );
  const importModal = useSelector(
    (state) => state.financeiroRegistros.importModal,
  );
  const financeiroRegistrosLoading = useSelector(
    (state) => state.financeiroRegistros.loading,
  );
  const {
    total,
    perPage,
    page: pageAtual,
    data: financeiroRegistros,
    filters,
  } = useSelector((state) => state.financeiroRegistros.financeiroRegistros);

  const dispatch = useDispatch();

  useEffect(() => {
    loadingGlobal(financeiroRegistrosLoading);
  }, [financeiroRegistrosLoading]);

  useEffect(() => {
    dispatch(indexRequest(1, 20));
  }, [dispatch]);

  function handlerEdit(id) {
    dispatch(showRequest(id));
  }

  function togleModal(value) {
    dispatch(togleFechaModalRegistro(value, 1));
  }

  function quickPay(id, atualSituation) {
    const situation = Number(atualSituation) === 1 ? 3 : 1;
    dispatch(quickUpdateRequest(id, situation, pageAtual, perPage, filters));
  }

  const deleteAgendamento = (id) => {
    dispatch(deleteRequest(id, pageAtual, filters));
    setAlert(false);
  };

  const deleteEventSweetAlert = (id) => {
    setAlert(() => {
      return (
        <ReactBSAlert
          warning
          style={{ display: 'block' }}
          title="Você tem certeza?"
          onConfirm={() => {
            setAlert(false);
          }}
          onCancel={() => deleteAgendamento(id)}
          confirmBtnCssClass="btn-secondary"
          cancelBtnBsStyle="danger"
          confirmBtnText="Cancelar"
          cancelBtnText="Sim, delete isso"
          showCancel
          btnSize=""
        >
          Essa exclusão não poderá ser desfeita, tem certeza?
        </ReactBSAlert>
      );
    });
  };

  return (
    <>
      {alert}
      <MainHeader
        name="Movimentações Financeiras"
        parentName="Financeiro"
        backwardHome="/terapeuta/dashboard"
        parentPath="/terapeuta/financeiro"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Filtros</h3>
              </Col>
            </Row>
          </CardHeader>
          <Search />
        </Card>
      </Container>
      <Container fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Registros financeiros</h3>
              </Col>
              <Col className="text-right" xs="3">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  onClick={() => dispatch(togleImportModal(!importModal))}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                  <span className="btn-inner--text">Importar registros</span>
                </Button>
              </Col>
              <Col className="text-right" xs="3">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  onClick={() => togleModal(!financeiroModal)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                  <span className="btn-inner--text">Novo Registro</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Categoria</th>
                <th scope="col">Descrição</th>
                <th scope="col">Valor</th>
                <th scope="col">Situação</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {financeiroRegistros.map((data) => (
                <tr key={data.id}>
                  <th className="table-user" scope="row">
                    <b>
                      {dateFormat(
                        data.data_vencimento,
                        ['YYYY-MM-DD'],
                        'DD/MM/YYYY',
                      )}
                    </b>
                  </th>
                  <td>
                    <span className="text-muted">
                      {data.financeiroCategoria && data.financeiroCategoria.name
                        ? data.financeiroCategoria.name
                        : 'Fatura cartão'}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted">{data.descricao}</span>
                  </td>
                  <td>
                    <span
                      className={
                        Number(data.tipo) === 1 ? 'positivo' : 'negativo'
                      }
                    >
                      {transformMoeda(data.valor_base, 2)}
                    </span>
                  </td>
                  <td>{situacaoAtual(data.financeiro_situation_id)}</td>
                  <td>
                    <UncontrolledDropdown group>
                      <DropdownToggle
                        caret
                        color="secondary"
                        className="table-action"
                      >
                        <i className="fas fa-ellipsis-v" color="secondary" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => handlerEdit(data.id)}>
                          Editar
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            quickPay(data.id, data.financeiro_situation_id);
                          }}
                        >
                          {Number(data.financeiro_situation_id) === 3
                            ? 'Voltar para em aberto'
                            : 'Pagar'}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => deleteEventSweetAlert(data.id)}
                        >
                          Deletar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginations
            page={pageAtual}
            total={Number(total)}
            perPage={perPage}
            funcaoIndex={indexRequest}
            filter={filters}
          />
        </Card>
      </Container>
      <RegistroFinanceiro />
      <ImportRegistros />
    </>
  );
}
