import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import pacientes from './pacientes/sagas';
import terapeutas from './terapeuta/sagas';
import guardians from './guardians/sagas';
import categoriasRegistro from './categoriasRegistro/sagas';
import categoriasAgendamento from './categoriasAgendamento/sagas';
import pacientesRegistro from './pacientesRegistro/sagas';
import agendamentos from './agendamentos/sagas';
import activation from './activation/sagas';
import recoverLostPassword from './recuperarSenha/sagas';
import newPassword from './novaSenha/sagas';
import registrosAtendimentos from './registrosAtendimentos/sagas';
import registrosCompartilhados from './registrosCompartilhados/sagas';
import registroComments from './registroComments/sagas';
import agendamentoSituation from './agendamentoSituation/sagas';
import compartilhamentos from './compartilhamentos/sagas';
import financeiroContas from './financeiroContas/sagas';
import financeiroContasTipos from './financeiroContasTipos/sagas';
import financeiroCards from './financeiroCards/sagas';
import financeiroRegistros from './financeiroRegistros/sagas';
import financeiroCategorias from './financeiroCategorias/sagas';
import financeiroMetodos from './financeiroMetodos/sagas';
import financeiroDashboard from './financeiroDashboard/sagas';
import financeiroInstitutions from './financeiroInstitutions/sagas';
import supportMessage from './support/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    terapeutas,
    pacientes,
    guardians,
    categoriasRegistro,
    categoriasAgendamento,
    pacientesRegistro,
    agendamentos,
    activation,
    recoverLostPassword,
    newPassword,
    registrosAtendimentos,
    registrosCompartilhados,
    registroComments,
    agendamentoSituation,
    compartilhamentos,
    financeiroContas,
    financeiroContasTipos,
    financeiroCards,
    financeiroRegistros,
    financeiroCategorias,
    financeiroMetodos,
    financeiroDashboard,
    financeiroInstitutions,
    supportMessage,
  ]);
}
