/* eslint-disable no-param-reassign */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  selectMetodos: [],
};

export default function financeiroMetodos(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroMetodos/SELECT_REQUEST_SUCCESS': {
        draft.selectMetodos = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@financeiroMetodos/SELECT_REQUEST': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
