import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  agendamento: {
    id: null,
    paciente_id: null,
    agendamento_categoria_id: null,
    agendamento_start: null,
    observation: null,
  },
  atendimento: {
    id: null,
    paciente: null,
    agendamento_situation_id: null,
    agendamento_start: null,
    observation: null,
    agendamento_categoria_id: null,
  },
  editModal: false,
  addModal: false,
  newDate: null,
  atendimentoModal: false,
  agendamentos: [],
  todayAgendamentos: [],
};

export default function agendamentos(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@agendamentos/CALENDAR_REQUEST_SUCCESS': {
        draft.agendamentos = action.payload.data;
        draft.addModal = false;
        draft.editModal = false;
        draft.loading = false;
        break;
      }
      case '@agendamentos/STORE_REQUEST_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@agendamentos/INDEX_REQUEST_SUCCESS': {
        draft.todayAgendamentos = action.payload.data;
        draft.atendimentoModal = false;
        draft.loading = false;
        break;
      }
      case '@agendamentos/AGENDAMENTO_SHOW_SUCCESS': {
        draft.agendamento.id = action.payload.id;
        draft.agendamento.paciente_id = action.payload.paciente_id;
        draft.agendamento.agendamento_categoria_id =
          action.payload.agendamento_categoria_id;
        draft.agendamento.agendamento_start = action.payload.agendamento_start;
        draft.agendamento.observation = action.payload.observation;
        draft.editModal = true;
        draft.loading = false;
        break;
      }
      case '@agendamentos/ATENDIMENTO_REQUEST_SUCCESS': {
        draft.atendimento.id = action.payload.id;
        draft.atendimento.paciente = action.payload.paciente;
        draft.atendimento.agendamento_situation_id =
          action.payload.agendamento_situation_id;
        draft.atendimento.agendamento_start = action.payload.agendamento_start;
        draft.atendimento.observation = action.payload.observation;
        draft.atendimento.agendamento_categoria_id =
          action.payload.agendamento_categoria_id;
        draft.atendimentoModal = true;
        draft.loading = false;
        break;
      }
      case '@agendamentos/DELETE_REQUEST_SUCCESS': {
        draft.agendamento.id = null;
        draft.agendamento.paciente_id = null;
        draft.agendamento.agendamento_categoria_id = null;
        draft.agendamento.agendamento_start = null;
        draft.agendamento.observation = null;
        draft.loading = false;
        break;
      }
      case '@agendamentos/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        draft.newDate = action.payload.newDate;
        break;
      }
      case '@agendamentos/FECHA_ATENDIMENTO_MODAL': {
        draft.atendimentoModal = action.payload.modal;
        break;
      }
      case '@agendamentos/FECHA_EDIT_MODAL': {
        draft.editModal = action.payload.modal;
        break;
      }
      case '@agendamentos/CALENDAR_REQUEST':
      case '@agendamentos/INDEX_REQUEST':
      case '@agendamentos/STORE_REQUEST':
      case '@agendamentos/AGENDAMENTO_REQUEST':
      case '@agendamentos/UPDATE_REQUEST':
      case '@agendamentos/DELETE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@agendamentos/FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
