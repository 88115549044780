import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Input,
} from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ValidationError from '~/components/ValidationError';

import {
  togleFechaModalAdd,
  storeRequest,
  selectRequest as selectRequestCategorias,
  selectPaisRequest,
  storeSuccess,
} from '~/store/modules/financeiroCategorias/actions';

export default function CreatableAdvanced({ defaultValue }) {
  const dispatch = useDispatch();

  /** @todo Criei o Select Create para usar com o unform e já setei o nome do campo, podemos deixar ele mais generico, mas não vale perder tempo agora */
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(
    'financeiro_categoria_id',
  );

  /** Variaveis para cadastrar uma nova categoria */
  const [formParentId, setFormParentId] = useState(null);
  const [formName, setFormName] = useState(false);
  const [formColor, setFormColor] = useState('#2dce89');
  const [selected, setSelected] = useState(null);
  const [err, setErr] = useState(undefined);

  /** Variaveis para montar o select create */
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [value, setValue] = useState(null);
  const [catIdSel, setCatIdSel] = useState(null);

  const selectCategorias = useSelector(
    (state) => state.financeiroCategorias.selectCategorias,
  );
  const selectCatsPais = useSelector(
    (state) => state.financeiroCategorias.selectCatsPais,
  );
  const addCategoriaModal = useSelector(
    (state) => state.financeiroCategorias.addModal,
  );
  const financeiroCategoria = useSelector(
    (state) => state.financeiroCategorias.financeiroCategoria,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const togleModal = useCallback(
    (v, tp) => {
      dispatch(togleFechaModalAdd(v));
      if (tp === 1) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setValue(null);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(selectPaisRequest());
    dispatch(selectRequestCategorias());
  }, [dispatch]);

  useEffect(() => {
    const nArray = [];
    selectCategorias.forEach((e) => {
      nArray.push({ label: e.name, value: e.id });
    });
    setOption(nArray);
  }, [selectCategorias]);

  useEffect(() => {
    const nArray = [];
    selectCategorias.forEach((e) => {
      nArray.push({ label: e.name, value: e.id });
    });
    setOption(nArray);
    let sel = [];

    if (!value && defaultValue && !financeiroCategoria.id) {
      sel = nArray.filter((vO) => Number(vO.value) === Number(defaultValue));
    } else if (
      !value ||
      (value && Number(financeiroCategoria.id) !== Number(value.value))
    ) {
      sel = nArray.filter(
        (vO) => Number(vO.value) === Number(financeiroCategoria.id),
      );
    }

    if (sel.length === 1) {
      setCatIdSel(sel[0].value);
      setValue(sel[0]);
    }
    if (financeiroCategoria.closeModal) {
      togleModal(false, 1);
      dispatch(storeSuccess(financeiroCategoria, false));
    }
  }, [
    financeiroCategoria,
    selectCategorias,
    dispatch,
    togleModal,
    value,
    defaultValue,
  ]);

  const handleChange = useCallback(
    (newValue) => {
      clearError();
      setValue(newValue || null);
      setCatIdSel(newValue ? newValue.value : null);
      dispatch(storeSuccess({ id: null, name: null, color: null }, false));
    },
    [dispatch, clearError],
  );

  const handleCreate = useCallback(
    (inputValue) => {
      togleModal(true);
      setIsLoading(true);
      setFormName(inputValue);
    },
    [togleModal],
  );

  const handleSubmitCat = useCallback(
    async (data) => {
      try {
        const schema = Yup.object().shape({
          formName: Yup.string().required('Nome é obrigatório'),
          formColor: Yup.string().required('Você precisa escolher uma cor'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(
          storeRequest(data.formName, data.formColor, data.formParentId, 2),
        );
      } catch (e) {
        const validationErrors = {};
        if (e instanceof Yup.ValidationError) {
          e.inner.forEach((ee) => {
            validationErrors[ee.path] = ee.message;
          });
          setErr(validationErrors);
        }
        toast.error(
          'Algo não deu certo, verifique os erros e tente novamente.',
        );
      }
    },
    [dispatch],
  );

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={addCategoriaModal}
        toggle={() => togleModal(!addCategoriaModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Criar Nova Categoria
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => togleModal(!addCategoriaModal)}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col xs="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="categoria-name">
                  Categoria principal
                </label>
                <ReactSelect
                  placeholder="Selecione a categoria principal"
                  value={selected}
                  classNamePrefix="form-control-sm"
                  options={selectCatsPais}
                  onChange={(e) => {
                    setFormParentId(e.value);
                    setSelected(e);
                  }}
                />
                <ValidationError
                  error={err && err.formParentId ? err.formParentId : null}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <InputGroup className="input-group-merge input-group mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="name"
                    id="name"
                    defaultValue={formName}
                    placeholder="Nome da categoria"
                    onChange={(e) => setFormName(e.target.value)}
                    type="text"
                  />
                  <ValidationError
                    error={err && err.formName ? err.formName : null}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <label className="form-control-label" htmlFor="color">
                Cor
              </label>
              <Input
                name="color"
                id="color"
                defaultValue="#2dce89"
                onChange={(e) => setFormColor(e.target.value)}
                type="color"
              />
              <ValidationError
                error={err && err.formColor ? err.formColor : null}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => togleModal(!addCategoriaModal)}
          >
            Fechar
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleSubmitCat({
                formParentId,
                formName,
                formColor,
              });
            }}
          >
            Cadastrar
          </Button>
        </div>
      </Modal>
      <input ref={inputRef} defaultValue={catIdSel} type="hidden" />
      <CreatableSelect
        placeholder="Selecione"
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={option}
        value={value}
        formatCreateLabel={(i) => `Nova: "${i}"`}
      />
      <ValidationError error={error} />
    </>
  );
}

CreatableAdvanced.propTypes = {
  defaultValue: PropTypes.number,
};

CreatableAdvanced.defaultProps = {
  defaultValue: null,
};
