import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types'

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar";

export default function DefaultLayout({ children }) {
  const mainContent = useRef(null);
  useEffect(() => {
    document.body.classList.add("bg-default");
    return function cleanup() {
      document.body.classList.remove("bg-default");
    }
  });

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <IndexNavbar />
        {children}
      </div>
    </>
  );

}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
}
