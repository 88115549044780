export function proximosReceberRequest(page, perPage) {
  return {
    type: '@financeiroDashboard/PROXIMOS_RECEBER_REQUEST',
    payload: {
      page,
      perPage,
    },
  };
}

export function proximosReceberSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroDashboard/PROXIMOS_RECEBER_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function proximosPagarRequest(page, perPage) {
  return {
    type: '@financeiroDashboard/PROXIMOS_PAGAR_REQUEST',
    payload: {
      page,
      perPage,
    },
  };
}

export function proximosPagarSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroDashboard/PROXIMOS_PAGAR_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function bancosRequest(page, perPage) {
  return {
    type: '@financeiroDashboard/BANCOS_REQUEST',
    payload: {
      page,
      perPage,
    },
  };
}

export function bancosSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroDashboard/BANCOS_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function ultimosRequest(page, perPage, filter) {
  return {
    type: '@financeiroDashboard/ULTIMOS_REQUEST',
    payload: {
      page,
      perPage,
      filter,
    },
  };
}

export function dashboardRequest() {
  return {
    type: '@financeiroDashboard/DASHBOARD_REQUEST',
  };
}

export function dashboardSuccess(
  saldos,
  saldoMes,
  variacaoSaldo,
  gastosMes,
  variacaoGastosMes,
  entradasMes,
  variacaoEntradasMes,
) {
  return {
    type: '@financeiroDashboard/DASHBOARD_SUCCESS',
    payload: {
      saldos,
      saldoMes,
      variacaoSaldo,
      gastosMes,
      variacaoGastosMes,
      entradasMes,
      variacaoEntradasMes,
    },
  };
}

export function ultimosSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@financeiroDashboard/ULTIMOS_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function failure(tp) {
  return {
    type: '@financeiroDashboard/FAILURE',
    payload: { tp },
  };
}
