import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Table,
  CardFooter,
} from 'reactstrap';

import { transformMoeda } from '~/helpers/transform';
import MainHeader from '~/components/Headers/MainHeader';
import Input, { Select } from '~/components/Input';

import {
  contaRequest,
  extratoRequest,
  updateRequest,
} from '~/store/modules/financeiroContas/actions';

export default function Conta() {
  const formRef = useRef(null);
  const financeiroConta = useSelector(
    (state) => state.financeiroContas.financeiroConta,
  );
  const extrato = useSelector((state) => state.financeiroContas.extrato);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(contaRequest(id));
    dispatch(extratoRequest(id));
  }, [dispatch, id]);

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({});
      await schema.validate(data, {
        abortEarly: false,
      });
      const { name } = data;
      dispatch(updateRequest(id, name));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <MainHeader
        name="Editar Conta"
        parentName="Financeiro"
        parentPath="/terapeuta/financeiro/contas"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Conta Financeira</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    ref={formRef}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="name">
                          Nome
                        </label>
                        <Input
                          name="name"
                          id="name"
                          defaultValue={financeiroConta.name}
                          placeholder="Nome"
                          type="text"
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="conta_tipo_id"
                        >
                          Tipo de conta
                        </label>
                        <Select
                          name="conta_tipo_id"
                          className="form-control select-contas"
                          defaultValue={financeiroConta.conta_tipo_id}
                          options={{
                            placeholder: 'Selecione um tipo de conta',
                          }}
                          data={[
                            {
                              id: 1,
                              name: 'Carteira',
                            },
                            {
                              id: 2,
                              name: 'Conta corrente',
                            },
                            {
                              id: 3,
                              name: 'Conta Poupança',
                            },
                            {
                              id: 5,
                              name: 'Outros',
                            },
                          ]}
                          disabled
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="saldo_atual"
                        >
                          Saldo
                        </label>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="input-icon-disabled">
                                <i className="fas fa-money-bill" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              name="saldo_atual"
                              id="saldo"
                              placeholder="Saldo da conta"
                              defaultValue={transformMoeda(
                                financeiroConta.saldo_atual,
                                2,
                              )}
                              disabled
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Atualizar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Extrato</h3>
                </CardHeader>
                {extrato.map((saldo) => (
                  <Card key={saldo.id} className="mx-5 my-4 p-0 border">
                    <CardHeader className="bg-extrato-hf">
                      <h4 className="mb-0">
                        {moment(saldo.data).format('DD/MM/YYYY')}
                      </h4>
                    </CardHeader>
                    <CardBody className="p-0">
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th>Registro</th>
                            <th>Valor da Operação</th>
                            <th>Saldo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saldo.saldoHistorico &&
                            saldo.saldoHistorico.map((historico) => (
                              <tr key={historico.id}>
                                <td className="table-user w-50">
                                  <b>
                                    {historico.financeiroRegistro
                                      ? historico.financeiroRegistro.descricao
                                      : 'Depósito inicial'}
                                  </b>
                                </td>
                                <td>
                                  <span
                                    className={
                                      Number(historico.valor_operacao) > 0
                                        ? 'positivo'
                                        : 'negativo'
                                    }
                                  >
                                    {transformMoeda(
                                      historico.valor_operacao,
                                      2,
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-muted">
                                    {transformMoeda(historico.saldo, 2)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter className="py-2 px-4 d-flex justify-content-end bg-extrato-hf">
                      <b className={Number(saldo.saldo) > 0 ? '' : 'negativo'}>
                        {transformMoeda(saldo.saldo, 2)}
                      </b>
                    </CardFooter>
                  </Card>
                ))}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
