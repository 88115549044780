import React, { useState, useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

import history from '~/services/history';

export default function MainHeader({
  name,
  parentName,
  parentPath,
  backwardHome,
}) {
  const [previousPath, setPreviousPath] = useState(undefined);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (backwardHome) {
      setPreviousPath(backwardHome);
    }

    if (history.location.state) {
      setFilters(
        history.location.state.filters ? history.location.state.filters : {},
      );
    }
  }, [backwardHome]);

  useEffect(() => {
    if (parentPath) {
      setPreviousPath(parentPath);
    }

    if (history.location.state) {
      setFilters(
        history.location.state.filters ? history.location.state.filters : {},
      );
    }
  }, [parentPath]);

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="12" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {`${name} `}
                </h6>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: '/terapeuta/dashboard',
                          },
                          {
                            filters,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: parentPath,
                          },
                          {
                            filters,
                          },
                        );
                      }}
                    >
                      {`${parentName} `}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    aria-current="page"
                    className="active no-before-after"
                  >
                    |
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    aria-current="page"
                    className="active no-before-after"
                  >
                    <a
                      className=""
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: previousPath,
                          },
                          {
                            filters,
                          },
                        );
                      }}
                    >
                      Voltar
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

MainHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  parentPath: PropTypes.string,
  backwardHome: PropTypes.string,
};

MainHeader.defaultProps = {
  name: null,
  parentName: null,
  parentPath: '',
  backwardHome: null,
};
