import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import MainHeader from '../../../components/Headers/MainHeader';
import PacienteUpdate from './pacienteUpdate';
import PacienteRegistros from './pacienteRegistros';
import { pacienteRequest } from '../../../store/modules/pacientes/actions';
import loadingGlobal from '../../../components/loadingGlobal';

export default function PacienteProfile() {
  const pacientesLoadingData = useSelector((state) => state.pacientes.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    loadingGlobal(pacientesLoadingData);
  }, [pacientesLoadingData]);

  useEffect(() => {
    dispatch(pacienteRequest(id));
  }, [dispatch, id]);

  return (
    <>
      <MainHeader
        name="Cliente"
        parentName="Clientes"
        parentPath="/terapeuta/pacientes"
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Dados do Cliente</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <PacienteUpdate />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <PacienteRegistros id={id} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
