export function indexRequest(page, perPage, filters) {
  return {
    type: '@registrosAtendimentos/INDEX_REQUEST',
    payload: {
      page,
      perPage,
      filters,
    },
  };
}

export function indexRequestSuccess(
  total,
  perPage,
  page,
  lastPage,
  data,
  filters,
) {
  return {
    type: '@registrosAtendimentos/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data, filters },
  };
}

export function registroAtendimentoRequest(id) {
  return {
    type: '@registrosAtendimentos/REGISTRO_ATENDIMENTO_REQUEST',
    payload: { id },
  };
}

export function registroAtendimentoRequestSuccess(
  prontuario_categoria_id,
  paciente_id,
  title,
  conteudo,
  paciente,
  files,
) {
  return {
    type: '@registrosAtendimentos/REGISTRO_ATENDIMENTO_REQUEST_SUCCESS',
    payload: {
      prontuario_categoria_id,
      paciente_id,
      title,
      conteudo,
      paciente,
      files,
    },
  };
}

export function updateRequest(
  id,
  paciente_id,
  title,
  prontuario_categoria_id,
  conteudo,
  files,
) {
  return {
    type: '@registrosAtendimentos/UPDATE_REQUEST',
    payload: {
      id,
      paciente_id,
      title,
      prontuario_categoria_id,
      conteudo,
      files,
    },
  };
}

export function updateSuccess() {
  return {
    type: '@registrosAtendimentos/UPDATE_SUCCESS',
  };
}

export function deleteRequest(id, page, perPage, filters) {
  return {
    type: '@registrosAtendimentos/DELETE_REQUEST',
    payload: { id, page, perPage, filters },
  };
}

export function storeRequest(
  paciente_id,
  prontuario_categoria_id,
  title,
  conteudo,
  files,
) {
  return {
    type: '@registrosAtendimentos/STORE_REQUEST',
    payload: { paciente_id, prontuario_categoria_id, title, conteudo, files },
  };
}

export function failure() {
  return {
    type: '@registrosAtendimentos/FAILURE',
  };
}
