import { takeLatest, all, call, put } from 'redux-saga/effects';

import api from '../../../services/api';

import { indexRequestSuccess, failure } from './actions';

import { toast } from 'react-toastify';

export function* selectRequest() {
  try {
    const response = yield call(api.get, 'terapeutas/listagem-agendamento-situations');

    yield put(indexRequestSuccess(response.data.length, 1, 1, 1, response.data));

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export default all([
  takeLatest('@agendamentoSituation/SELECT_REQUEST', selectRequest)
]);
