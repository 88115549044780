/**
 * @todo Refatorar o require de imagens
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import history from '~/services/history';
import { transformMoeda } from '~/helpers/transform';
import MainHeader from '~/components/Headers/MainHeader';
import ModalDelete from '~/components/ConfirmDelete';
import StoreContas from '../components/ModalStoreContas';

import {
  indexRequest as contasIndexRequest,
  deleteRequest,
  togleFechaModalAdd as modalAddContas,
} from '~/store/modules/financeiroContas/actions';

import {
  indexRequest as cardsIndexRequest,
  togleFechaModalAdd as modalAddCards,
} from '~/store/modules/financeiroCards/actions';

import { togleFechaModalAdd as togleModalDelete } from '~/store/modules/modal/actions';

export default function Contas() {
  const dispatch = useDispatch();
  const addModal = useSelector((state) => state.modal.addModal);
  const addContaModal = useSelector((state) => state.financeiroContas.addModal);

  useEffect(() => {
    dispatch(contasIndexRequest(1, 20));
    dispatch(cardsIndexRequest(1, 20));
  }, [dispatch]);

  const financeiroContas = useSelector(
    (state) => state.financeiroContas.financeiroContas.data,
  );

  const financeiroCards = useSelector(
    (state) => state.financeiroCards.financeiroCards.data,
  );

  function handleDelete(id) {
    dispatch(togleModalDelete(!addModal, id));
  }

  function storeConta() {
    dispatch(modalAddContas(!addContaModal));
  }

  function storeCard() {
    dispatch(modalAddCards(!addContaModal));
  }

  return (
    <>
      <MainHeader
        name="Contas"
        parentName="Financeiro"
        backwardHome="/terapeuta/dashboard"
        parentPath="/terapeuta/financeiro"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Contas Cadastradas</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href="#empoderaTerapeuta"
                  onClick={() => storeConta()}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span className="btn-inner--text">Nova Conta</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Instituição</th>
                <th>Saldo</th>
                <th>Relativo ao Total</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {financeiroContas.map((conta) => (
                <tr key={conta.id}>
                  <td className="table-user">
                    <Button
                      className="name-index"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/financeiro/contas/editar/${conta.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                          },
                        );
                      }}
                    >
                      <img
                        className="bank-img"
                        alt="bank logo"
                        src={require(`assets/img/bank-logos/${
                          conta.institution
                            ? conta.institution.img_name
                            : 'outro-banco'
                        }.svg`)}
                      />
                      <b>{conta.name}</b>
                    </Button>
                  </td>
                  <td>
                    <span className="text-muted">
                      {transformMoeda(conta.saldo_atual, 2)}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted">60%</span>
                  </td>
                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/financeiro/contas/editar/${conta.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action-delete"
                      href="#empoderaTerapeuta"
                      onClick={() => {
                        handleDelete(conta.id);
                      }}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Cartões Cadastrados</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href="#empoderaTerapeuta"
                  onClick={() => storeCard()}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span className="btn-inner--text">Novo Cartão</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Bandeira</th>
                <th>Limite total</th>
                <th>Limite disponível</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {financeiroCards.map((card) => (
                <tr key={card.id}>
                  <td className="table-user">
                    <Button
                      className="name-index"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/financeiro/cards/editar/${card.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                          },
                        );
                      }}
                    >
                      <img
                        className="bank-img"
                        alt="bank logo"
                        src={require(`assets/img/bank-logos/${
                          card.institution
                            ? card.institution.img_name
                            : 'outro-cartao'
                        }.svg`)}
                      />
                      <b>{card.name}</b>
                    </Button>
                  </td>
                  <td>
                    <span className="text-muted">
                      {transformMoeda(card.limite_total, 2)}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted">
                      {(
                        Number(card.limite_disponivel * 100) /
                        Number(card.limite_total)
                      ).toFixed(2)}
                      %
                    </span>
                  </td>
                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/financeiro/cards/editar/${card.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action-delete"
                      href="#empoderaTerapeuta"
                      onClick={() => {
                        handleDelete(card.id);
                      }}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
              <ModalDelete deleteRequest={deleteRequest} />
            </tbody>
          </Table>
          <StoreContas contas={financeiroContas} />
        </Card>
      </Container>
    </>
  );
}
