import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  fileModal: false,
  addModal: false,
  commentModal: false,
  registroAtendimento: {
    id: null,
    paciente_id: null,
    prontuario_categoria_id: null,
    title: null,
    conteudo: null,
    files: [],
  },
  registros: [],
};

export default function pacientesRegistro(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@pacientesRegistro/INDEX_REQUEST_SUCCESS': {
        draft.registros = action.payload.data;
        draft.addModal = false;
        draft.fileModal = false;
        draft.loading = false;
        break;
      }

      case '@pacientesRegistro/REGISTRO_ATENDIMENTO_REQUEST_SUCCESS': {
        draft.registroAtendimento.id = action.payload.id;
        draft.registroAtendimento.paciente_id = action.payload.paciente_id;
        draft.registroAtendimento.prontuario_categoria_id =
          action.payload.prontuario_categoria_id;
        draft.registroAtendimento.title = action.payload.title;
        draft.registroAtendimento.conteudo = action.payload.conteudo;
        draft.registroAtendimento.files = action.payload.files;

        draft.fileModal = true;
        draft.loading = false;
        break;
      }
      case '@pacientesRegistro/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        break;
      }
      case '@pacientesRegistro/COMMENT_ADD_MODAL': {
        draft.registroAtendimento.id = action.payload.id;
        draft.commentModal = action.payload.modal;
        break;
      }
      case '@pacientesRegistro/FECHA_FILES_MODAL': {
        draft.fileModal = action.payload.modal;
        break;
      }
      case '@pacientesRegistro/STORE_REQUEST_SUCCESS': {
        draft.addModal = true;
        break;
      }
      default:
    }
  });
}
