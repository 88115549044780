/* eslint-disable no-param-reassign */
import produce from 'immer';
import { transformMoeda, dateFormat } from '~/helpers/transform';

const INITIAL_STATE = {
  loading: false,
  importLoading: false,
  financeiroModal: false,
  importModal: false,
  financeiroRegistros: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
    filter: null,
  },
  registroSelected: {
    id: null,
    terapeuta_id: null,
    parent_id: null,
    financeiro_conta_id: null,
    financeiro_metodo_id: null,
    financeiro_situation_id: null,
    agendamento_id: null,
    tipo: undefined,
    total_parcelas: null,
    parcela_atual: null,
    descricao: null,
    data_vencimento: null,
    data_pagamento: null,
    valor_base: null,
    valor_efetivo: null,
    fatura: null,
    compra_id: null,
    recorrente: null,
    financeiro_categoria_id: null,
    paciente_id: null,
    data_competencia: null,
    juros: null,
    desconto: null,
  },
};

export default function financeiroRegistros(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroRegistros/INDEX_REQUEST_SUCCESS': {
        draft.financeiroRegistros.total = action.payload.total;
        draft.financeiroRegistros.perPage = action.payload.perPage;
        draft.financeiroRegistros.page = action.payload.page;
        draft.financeiroRegistros.lastPage = action.payload.lastPage;
        draft.financeiroRegistros.data = action.payload.data;
        draft.financeiroRegistros.filter = action.payload.filter;
        draft.loading = false;

        break;
      }
      case '@financeiroRegistros/SHOW_REQUEST_SUCSESS': {
        const valor_base =
          Number(action.payload.tipo) === 1
            ? action.payload.valor_base
            : -1 * action.payload.valor_base;

        let valor_efetivo = null;
        if (action.payload.valor_efetivo) {
          valor_efetivo =
            Number(action.payload.tipo) === 1
              ? action.payload.valor_efetivo
              : -1 * action.payload.valor_efetivo;
        }
        draft.registroSelected.id = action.payload.id;
        draft.registroSelected.terapeuta_id = action.payload.terapeuta_id;
        draft.registroSelected.parent_id = action.payload.parent_id;
        draft.registroSelected.financeiro_conta_id =
          action.payload.financeiro_conta_id;
        draft.registroSelected.financeiro_metodo_id =
          action.payload.financeiro_metodo_id;
        draft.registroSelected.financeiro_situation_id = Number(
          action.payload.financeiro_situation_id,
        );
        draft.registroSelected.agendamento_id = action.payload.agendamento_id;
        draft.registroSelected.tipo = Number(action.payload.tipo);
        draft.registroSelected.total_parcelas = action.payload.total_parcelas;
        draft.registroSelected.parcela_atual = action.payload.parcela_atual;
        draft.registroSelected.descricao = action.payload.descricao;
        draft.registroSelected.data_vencimento = dateFormat(
          action.payload.data_vencimento,
          ['YYYY-MM-DD'],
          'DD/MM/YYYY',
        );
        draft.registroSelected.data_pagamento = dateFormat(
          action.payload.data_pagamento,
          ['YYYY-MM-DD'],
          'DD/MM/YYYY',
        );
        draft.registroSelected.valor_base = transformMoeda(valor_base, 2);
        draft.registroSelected.valor_efetivo = transformMoeda(valor_efetivo, 2);
        draft.registroSelected.fatura = action.payload.fatura;
        draft.registroSelected.compra_id = action.payload.compra_id;
        draft.registroSelected.recorrente = action.payload.recorrente;
        draft.registroSelected.financeiro_categoria_id =
          action.payload.financeiro_categoria_id;
        draft.registroSelected.paciente_id = action.payload.paciente_id;
        draft.registroSelected.data_competencia = dateFormat(
          action.payload.data_competencia,
          ['YYYY-MM-DD'],
          'DD/MM/YYYY',
        );
        draft.registroSelected.juros = action.payload.juros;
        draft.registroSelected.desconto = action.payload.desconto;
        draft.loading = false;

        break;
      }
      case '@financeiroRegistros/UPDATE_FATURA_REQUEST':
      case '@financeiroRegistros/SHOW_REQUEST':
      case '@financeiroRegistros/INDEX_REQUEST':
      case '@financeiroRegistros/QUICK_UPDATE_REQUEST':
      case '@financeiroRegistros/STORE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@financeiroRegistros/FECHA_REGISTRO_MODAL': {
        if (action.payload.tipo === 1) {
          draft.registroSelected = INITIAL_STATE.registroSelected;
        }
        draft.financeiroModal = action.payload.modal;
        break;
      }
      case '@financeiroRegistros/IMPORT_REQUEST': {
        draft.importLoading = true;
        break;
      }
      case '@financeiroRegistros/IMPORT_MODAL': {
        draft.importModal = action.payload.modal;
        draft.importLoading = false;
        break;
      }
      case '@financeiroRegistros/FAILURE': {
        draft.loading = false;
        draft.importLoading = false;
        break;
      }
      default:
    }
  });
}
