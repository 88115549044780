/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import loadingGlobal from '../../../components/loadingGlobal';
import MainHeader from '../../../components/Headers/MainHeader';
import history from '../../../services/history';
import {
  indexRequest,
  deleteRequest,
} from '../../../store/modules/registrosAtendimentos/actions';
import { togleFechaModalAdd } from '../../../store/modules/modal/actions';

import SearchHeader from './searchHeader';
import Paginations from '../../../components/Pagination/Paginations';
import ModalDelete from '../../../components/ConfirmDelete';
import Compartilhamento from './Compartilhamento';

export default function RegistrosAtendimentos() {
  const [defaultFilters, setDefaultFilters] = useState({});
  const addModal = useSelector((state) => state.modal.addModal);
  const registrosLoadingData = useSelector(
    (state) => state.registrosAtendimentos.loading,
  );
  const registrosAtendimentos = useSelector(
    (state) => state.registrosAtendimentos.registrosAtendimentos.data,
  );
  const { total, perPage, page, filters } = useSelector(
    (state) => state.registrosAtendimentos.registrosAtendimentos,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    loadingGlobal(registrosLoadingData);
  }, [registrosLoadingData]);

  useEffect(() => {
    setDefaultFilters(
      history.location.state ? history.location.state.filters : {},
    );
  }, []);

  useEffect(() => {
    dispatch(indexRequest(1, 20, defaultFilters));
  }, [dispatch, defaultFilters]);

  function handleDelete(id) {
    dispatch(togleFechaModalAdd(!addModal, id));
  }

  return (
    <>
      <Compartilhamento />
      <MainHeader
        name="Registros por cliente"
        parentName="Registros"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6 border-0" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Filtros</h3>
              </Col>
            </Row>
          </CardHeader>
          <SearchHeader defaultFilters={defaultFilters} />
        </Card>
      </Container>
      <Container fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Registros por cliente</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href=""
                  onClick={() => {
                    history.push(
                      {
                        pathname: '/terapeuta/registros-atendimento/novo',
                      },
                      {
                        previousPath: history.location.pathname,
                        filters,
                      },
                    );
                  }}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-notes-medical" />
                  </span>
                  <span className="btn-inner--text">Novo Registro</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Data de Registro</th>
                <th>Cliente</th>
                <th>Título</th>
                <th>Categoria de registro</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {registrosAtendimentos.map((data) => (
                <tr key={data.id}>
                  <td className="table-user">
                    <a
                      style={{
                        color: '#525f7f',
                      }}
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/registros-atendimento/editar/${data.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                            filters,
                          },
                        );
                      }}
                    >
                      <b>
                        {moment
                          .tz(data.created_at, moment.tz.guess())
                          .format('DD/MM/YYYY HH:mm:ss')}
                      </b>
                    </a>
                  </td>
                  <td>
                    <span className="text-muted">{data.paciente.name}</span>
                  </td>
                  <td>
                    <span className="text-muted">{data.title}</span>
                  </td>
                  <td>
                    <span className="text-muted">
                      {data.prontuarioCategoria.name}
                    </span>
                  </td>
                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/registros-atendimento/editar/${data.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                            filters,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action-delete"
                      href="#empoderaTerapeuta"
                      onClick={() => handleDelete(data.id)}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginations
            page={page}
            total={total}
            perPage={perPage}
            funcaoIndex={indexRequest}
            filter={filters}
          />
          <ModalDelete
            deleteRequest={deleteRequest}
            page={page}
            perPage={perPage}
            filter={filters}
          />
        </Card>
      </Container>
    </>
  );
}
