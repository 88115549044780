import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '~/services/history';

import {
  indexRequest as indexRequestCategorias,
  indexRequestSuccess,
  failure,
  categoriaRequestSuccess,
  selectRequestSuccess,
  selectPaisSuccess,
  selectPaisRequest as selectPaisCats,
  selectRequest as selectCats,
  storeSuccess,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-categorias', {
      params: {
        page: tPage,
        perPage: tPerPage,
      },
    });

    const { total, perPage, page, lastPage } = response.data;

    const data = [];

    response.data.forEach((categoria) => {
      data.push({
        id: categoria.id,
        name: categoria.name,
        color: categoria.color,
      });

      categoria.children.forEach((child) => {
        data.push({
          id: child.id,
          name: child.name,
          parent_id: child.parent_id,
          color: child.color,
        });
      });
    });

    /*
    const cats = [];
    response.data.forEach((nData) => {
      cats.push({
        id: nData.id,
        name: `- ${nData.name}`,
        color: nData.color,
      });

      nData.children.forEach((child) => {
        cats.push({
          id: child.id,
          name: `- - - ${child.name}`,
          color: child.color,
        });
      });
    });
    */

    yield put(indexRequestSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const { name, color, parent_id } = payload;

    const response = yield call(api.post, 'terapeutas/financeiro-categorias', {
      name,
      color,
      parent_id,
    });

    if (payload.type === 1) {
      yield put(indexRequestCategorias(1, 20));
      toast.success('Categoria cadastrada com sucesso');
      history.push('/terapeuta/financeiro-categorias');
    } else if (payload.type === 2) {
      yield put(storeSuccess(response.data.financeiroCategorias, 1));
      yield put(selectCats());
      yield put(selectPaisCats());
    } else {
      yield put(indexRequestCategorias(1, 20));
      toast.success('Categoria cadastrada com sucesso');
    }
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* categoriaRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(
      api.get,
      `terapeutas/financeiro-categorias/${id}`,
    );
    yield put(categoriaRequestSuccess(response.data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const { id, name, color } = payload;

    yield call(api.put, `terapeutas/financeiro-categorias/${id}`, {
      name,
      color,
    });

    yield put(indexRequestCategorias(1, 20));

    toast.success('Categoria atualizada com sucesso!');

    history.push('/terapeuta/financeiro-categorias');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* selectRequest() {
  try {
    const response = yield call(
      api.get,
      'terapeutas/listagem-financeiro-categorias',
    );
    const cats = [];
    response.data.forEach((nData) => {
      cats.push({
        id: nData.id,
        name: `- ${nData.name}`,
        color: nData.color,
      });

      nData.children.forEach((child) => {
        cats.push({
          id: child.id,
          name: `- - - ${child.name}`,
          color: child.color,
        });
      });
    });

    yield put(selectRequestSuccess(cats));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* selectPaisRequest() {
  try {
    const response = yield call(
      api.get,
      'terapeutas/listagem-financeiro-categorias',
    );
    const cats = [{ value: null, label: 'Selecione' }];
    response.data.forEach((nData) => {
      cats.push({
        value: nData.id,
        label: nData.name,
      });
    });

    yield put(selectPaisSuccess(cats));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `terapeutas/financeiro-categorias/${id}`);

    yield put(indexRequestCategorias(1, 20));

    toast.success('Categoria excluida com sucesso');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}
export default all([
  takeLatest('@financeiroCategorias/INDEX_REQUEST', indexRequest),
  takeLatest('@financeiroCategorias/STORE_REQUEST', storeRequest),
  takeLatest('@financeiroCategorias/DELETE_REQUEST', deleteRequest),
  takeLatest('@financeiroCategorias/CATEGORIA_REQUEST', categoriaRequest),
  takeLatest('@financeiroCategorias/UPDATE_REQUEST', updateRequest),
  takeLatest('@financeiroCategorias/SELECT_REQUEST', selectRequest),
  takeLatest('@financeiroCategorias/SELECT_PAIS_REQUEST', selectPaisRequest),
]);
