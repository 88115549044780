import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';

import MainHeader from '../../../components/Headers/MainHeader';
import Input from '../../../components/Input';

import { storeRequest } from '../../../store/modules/categoriasRegistro/actions';
import { selectRequest } from '../../../store/modules/compartilhamentos/actions';

export default function Validation() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const compartilhamentos = useSelector(
    (state) => state.compartilhamentos.compartilhamentos.data,
  );

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        color: Yup.string().required('Você precisa escolher uma cor'),
        icon: Yup.string().required('Você precisa preencher com um icone'),

      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const { name, color, icon, compartilhamento_id } = data;
      dispatch(storeRequest(name, color, icon, compartilhamento_id));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }
  useEffect(() => {
    dispatch(selectRequest());
  }, [dispatch]);
  return (
    <>
      <MainHeader
        name="Nova Categoria"
        parentName="Categorias de Registros"
        parentPath="/terapeuta/categorias-registros"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nova Categoria</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    ref={formRef}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="name">
                          Nome
                        </label>
                        <Input
                          name="name"
                          id="name"
                          placeholder="Nome"
                          type="text"
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="color">
                          Cor
                        </label>
                        <Input
                          name="color"
                          id="color"
                          defaultValue="#2dce89"
                          type="color"
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="icon">
                          Icone&nbsp;
                          <a
                            href="https://fontawesome.com/icons?d=gallery&m=free"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Clique aqui para ver os icones
                          </a>
                        </label>
                        <Input name="icon" id="icon" type="text" />
                      </Col>
                    </div>
                    <Button color="primary" type="submit">
                      Cadastrar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
