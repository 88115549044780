import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  proximosReceberSuccess,
  proximosPagarSuccess,
  bancosSuccess,
  ultimosSuccess,
  dashboardSuccess,
  failure,
} from './actions';

export function* proximosReceberRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-registros', {
      params: {
        page: tPage,
        perPage: tPerPage,
        orderDate: 'asc',
        proximosRecs: 1,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(proximosReceberSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure('proximosRec'));
    toast.error('Erro carregando próximos recebimentos');
  }
}

export function* proximosPagarRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-registros', {
      params: {
        page: tPage,
        perPage: tPerPage,
        orderDate: 'asc',
        proximosPagtos: 1,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(proximosPagarSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure('proximosPag'));
    toast.error('Erro carregando próximos pagamentos.');
  }
}

export function* bancosRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-contas', {
      params: {
        tipoId: [1, 2, 3, 5],
        page: tPage,
        perPage: tPerPage,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(bancosSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure('bancos'));
    toast.error('Erro carregando bancos.');
  }
}

export function* ultimosRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage } = payload;
    const response = yield call(api.get, 'terapeutas/financeiro-registros', {
      params: {
        page: tPage,
        perPage: tPerPage,
        orderDate: 'desc',
        orderBy: 'created_at',
        ultimosRegistros: 1,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(ultimosSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure('ultimos'));
    toast.error('Erro carregando últimos lançamentos.');
  }
}

export function* dashboardRequest() {
  try {
    const response = yield call(api.get, 'terapeutas/financeiro-dashboard');

    const {
      saldos,
      saldoMes,
      variacaoSaldo,
      gastosMes,
      variacaoGastosMes,
      entradasMes,
      variacaoEntradasMes,
    } = response.data;

    yield put(
      dashboardSuccess(
        saldos,
        saldoMes,
        variacaoSaldo,
        gastosMes,
        variacaoGastosMes,
        entradasMes,
        variacaoEntradasMes,
      ),
    );
  } catch (err) {
    yield put(failure('resumes'));
    toast.error('Erro ao carregar resumo da conta.');
  }
}

export default all([
  takeLatest(
    '@financeiroDashboard/PROXIMOS_RECEBER_REQUEST',
    proximosReceberRequest,
  ),
  takeLatest(
    '@financeiroDashboard/PROXIMOS_PAGAR_REQUEST',
    proximosPagarRequest,
  ),
  takeLatest('@financeiroDashboard/BANCOS_REQUEST', bancosRequest),
  takeLatest('@financeiroDashboard/ULTIMOS_REQUEST', ultimosRequest),
  takeLatest('@financeiroDashboard/DASHBOARD_REQUEST', dashboardRequest),
]);
