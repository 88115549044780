/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import ReactDatePicker from 'react-datepicker';
import TextInputMask from 'react-masked-text';
import Select2 from 'react-select2-wrapper';
import ReactSelect from 'react-select';
import ReactQuill from 'react-quill';
import cep from 'cep-promise';
import { toast } from 'react-toastify';

import ptBr from 'date-fns/locale/pt-BR';
import { toDate } from 'date-fns-tz';

import ValidationError from '../ValidationError';
import '~/assets/vendor/select2/dist/css/select2.min.css';
import 'react-datepicker/dist/react-datepicker.css';

export default function Input({ name, className, defaultValue, ...rest }) {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue('');
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <>
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onFocus={clearError}
        className={error ? `${className} invalid` : className}
        {...rest}
      />
      <ValidationError error={error} />
    </>
  );
}

export function FileInput({ name, className, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <input
        ref={inputRef}
        onClick={clearError}
        className={error ? `${className} invalid` : className}
        type="file"
        {...rest}
      />
      <ValidationError error={error} />
    </>
  );
}

export function TextArea({ name, className, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue = '',
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <textarea
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={clearError}
        className={error ? `${className} invalid` : className}
        {...rest}
      />
      <ValidationError error={error} />
    </>
  );
}

export function DatePicker({ name, defaultValue, ...rest }) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setDate(toDate(defaultValue));
    }
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      setValue(ref, v) {
        if (v) {
          setDate(v);
        }
      },
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
        setDate(null);
      },
    });
  }, [fieldName, registerField, date]);

  const handleChange = useCallback(
    (v) => {
      setDate(v);
      clearError();
    },
    [clearError],
  );

  return (
    <>
      <ReactDatePicker
        className="form-control"
        ref={datepickerRef}
        selected={date}
        onChange={handleChange}
        locale={ptBr}
        {...rest}
      />
      <br />
      <ValidationError error={error} />
    </>
  );
}

export function InputMasked({ name, defaultValue, className, ...rest }) {
  const [value, setValue] = useState(undefined);
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue('');
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />

      <TextInputMask
        value={value}
        className={className}
        onFocus={clearError}
        {...rest}
        onChangeText={(v) => setValue(v)}
      />
      <ValidationError error={error} />
    </>
  );
}

export function EditorTexto({ name, defaultValue, className, ...rest }) {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue('');
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue || '');
    }
  }, [defaultValue]);

  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />
      <ReactQuill
        onFocus={clearError}
        value={value}
        onChange={setValue}
        {...rest}
      />
      <ValidationError error={error} />
    </>
  );
}

export function BuscaEndereco({ name, className, defaultValue, formRef }) {
  const [value, setValue] = useState(undefined);
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue('');
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleBuscaCep = useCallback(
    (v) => {
      cep(v)
        .then((data) => {
          formRef.current.setFieldValue('cidade', data.city);
          formRef.current.setFieldValue('uf', data.state);
          formRef.current.setFieldValue('endereco', data.street);
          formRef.current.setFieldValue('bairro', data.neighborhood);
        })
        .catch(() => {
          toast.error('Cep não encontrado');
        });
      clearError();
    },
    [formRef, clearError],
  );

  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />

      <TextInputMask
        placeholder="cep"
        value={value}
        className={className}
        kind="custom"
        options={{ mask: '99999-999' }}
        onChangeText={(v) => setValue(v)}
        onBlur={(e) => handleBuscaCep(e.target.value)}
      />
      <ValidationError error={error} />
    </>
  );
}

export function Select({
  name,
  defaultValue,
  className,
  data,
  onChange,
  ...rest
}) {
  const [value, setValue] = useState(undefined);
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue(undefined);
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const cbSelect = useCallback(
    (v) => {
      setValue(v.params.data.id);
      clearError();

      if (onChange) {
        onChange(v.params.data.id);
      }
    },
    [clearError, onChange],
  );

  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />

      <Select2
        className={`${className} form-control`}
        defaultValue={value}
        onSelect={cbSelect}
        {...rest}
        data={data.map((d) => {
          return { id: d.id, text: d.name };
        })}
      />
      <ValidationError error={error} />
    </>
  );
}

export function SimpleSelect({ name, defaultValue, options, ...rest }) {
  const [value, setValue] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue(undefined);
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    const nSel = options.filter((o) => {
      return o.value === defaultValue;
    });
    setSelected(nSel);
    setValue(defaultValue || '');
  }, [defaultValue, options]);

  const handleChange = useCallback(
    (v) => {
      setValue(v.value);
      setSelected(v);
      clearError();
    },
    [clearError],
  );

  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />
      <ReactSelect
        value={selected}
        classNamePrefix="form-control-sm"
        options={options}
        onChange={handleChange}
        {...rest}
      />
      <ValidationError error={error} />
    </>
  );
}

export function CheckboxInput({ name, options, checked, ...rest }) {
  const [value, setValue] = useState(undefined);
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, v) {
        if (v) {
          setValue(v);
        }
      },
      clearValue: () => {
        setValue(undefined);
      },
    });
  }, [fieldName, registerField, value]);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const cbSelect = useCallback(
    (v) => {
      clearError();
      if (v.target.checked) {
        setValue(Number(v.target.value));
      } else {
        setValue(undefined);
      }
    },
    [clearError],
  );

  return (
    <>
      <input ref={inputRef} defaultValue={value} type="hidden" />
      {options.map((option) => (
        <div
          key={option.id}
          className="custom-control custom-checkbox custom-control-inline"
        >
          <input
            className="custom-control-input"
            value={option.value}
            checked={value === Number(option.value)}
            type="checkbox"
            id={option.id}
            onChange={cbSelect}
            {...rest}
          />
          <label className="custom-control-label" htmlFor={option.id}>
            {option.label}
          </label>
        </div>
      ))}
      <ValidationError error={error} />
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
};

// Same approach for defaultProps too
Input.defaultProps = {
  className: 'form-control',
  defaultValue: '',
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

// Same approach for defaultProps too
FileInput.defaultProps = {
  className: 'form-control',
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
};

// Same approach for defaultProps too
TextArea.defaultProps = {
  className: 'form-control',
  defaultValue: '',
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
};

DatePicker.defaultProps = {
  defaultValue: '',
};

InputMasked.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
};

// Same approach for defaultProps too
InputMasked.defaultProps = {
  className: 'form-control',
  defaultValue: '',
};

EditorTexto.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
};

// Same approach for defaultProps too
EditorTexto.defaultProps = {
  className: 'form-control',
  defaultValue: '',
};

BuscaEndereco.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

// Same approach for defaultProps too
BuscaEndereco.defaultProps = {
  className: 'form-control',
  defaultValue: '',
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  data: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
};

// Same approach for defaultProps too
Select.defaultProps = {
  className: 'form-control',
  defaultValue: undefined,
  data: [],
  onChange: undefined,
};

SimpleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  options: PropTypes.arrayOf(PropTypes.any),
};

SimpleSelect.defaultProps = {
  defaultValue: undefined,
  options: [],
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
};

CheckboxInput.defaultProps = {
  checked: '',
};
