/* eslint-disable react/jsx-curly-newline */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
// reactstrap components
import { Button, Modal, Row, Col, Table } from 'reactstrap';
import { Form } from '@unform/web';
import Gravatar from 'react-gravatar';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  selectRequest,
  compartilhamentoUpdateRequest,
  compartilhamentoDeleteRequest,
  inviteRequest,
  inviteDeleteRequest,
  togleFechaModalEdit,
} from '../../../../store/modules/compartilhamentos/actions';
import Input, { Select } from '../../../../components/Input';

export default function Compartilhamento() {
  const formUp = useRef(null);
  const formMail = useRef(null);
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const editModal = useSelector((state) => state.compartilhamentos.editModal);
  const compartilhamentos = useSelector(
    (state) => state.compartilhamentos.compartilhamentos.data,
  );
  const compartilhamento = useSelector(
    (state) => state.compartilhamentos.compartilhamento,
  );
  const terapeutas = useSelector(
    (state) => state.compartilhamentos.compartilhamento.terapeutas,
  );
  const invites = useSelector(
    (state) => state.compartilhamentos.compartilhamento.invites,
  );

  async function handleCategoria(data) {
    try {
      const schema = Yup.object().shape({
        compartilhamento_id: Yup.number().required(
          'Você precisa selecionar uma categoria',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { compartilhamento_id } = data;
      dispatch(
        compartilhamentoUpdateRequest(compartilhamento.id, compartilhamento_id),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formUp.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  async function handleMail(data) {
    try {
      formMail.current.setErrors([]);
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('E-mail inválido')
          .required('Você precisa digitar um e-mail'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { email } = data;
      dispatch(inviteRequest(compartilhamento.id, email));
      formMail.current.setData({
        email: '',
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formMail.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  function togleModal(value) {
    dispatch(togleFechaModalEdit(value));
  }

  const deleteEventSweetAlert = (funcDelete, param1, param2) => {
    setAlert(() => {
      return (
        <ReactBSAlert
          warning
          style={{ display: 'block' }}
          title="Você tem certeza?"
          onConfirm={() => {
            setAlert(false);
          }}
          onCancel={() => funcDelete(param1, param2)}
          confirmBtnCssClass="btn-secondary"
          cancelBtnBsStyle="danger"
          confirmBtnText="Cancelar"
          cancelBtnText="Sim, remover terapeuta"
          showCancel
          btnSize=""
        >
          Essa exclusão não poderá ser desfeita, tem certeza?
        </ReactBSAlert>
      );
    });
  };

  function deleteShare(registro_id, terapeuta_id) {
    dispatch(compartilhamentoDeleteRequest(registro_id, terapeuta_id));
    setAlert(false);
  }

  function deleteInvite(registro_id, invite_id) {
    dispatch(inviteDeleteRequest(registro_id, invite_id));
    setAlert(false);
  }

  useEffect(() => {
    dispatch(selectRequest());
  }, [dispatch]);

  return (
    <>
      {alert}
      <Modal
        className="modal-dialog-centered"
        isOpen={editModal}
        toggle={() => togleModal(!editModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Configurações de compartilhamento
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => togleModal(!editModal)}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Form ref={formUp} onSubmit={handleCategoria}>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-name">
                  Você pode mudar a categoria de compartilhamento
                </label>
                <Select
                  name="compartilhamento_id"
                  defaultValue={compartilhamento.compartilhamento_id}
                  className="form-control"
                  options={{
                    placeholder: 'Selecione uma categoria',
                  }}
                  data={compartilhamentos}
                />
                <br />
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            </Form>
          </Row>
          <hr />
          <Row>
            <Col lg="12">
              <label className="form-control-label" htmlFor="input-name">
                Terapeutas que podem visualizar esse registro
              </label>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Terapeuta</th>
                    <th style={{ width: '30px' }}>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {terapeutas.map((data) => (
                    <tr key={data.id}>
                      <td className="table-user">
                        <Gravatar
                          email={
                            data.email || '00000000000000000000000000000000'
                          }
                          className="avatar rounded-circle mr-3"
                          default="mp"
                        />
                        <b>{data.name}</b>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href="#empoderaTerapeuta"
                          onClick={() =>
                            deleteEventSweetAlert(
                              deleteShare,
                              compartilhamento.id,
                              data.id,
                            )
                          }
                        >
                          <i className="fas fa-times" />
                        </a>
                      </td>
                    </tr>
                  ))}
                  {invites.map((data) => (
                    <tr key={data.id}>
                      <td className="table-user">
                        <i
                          className="ni ni-send"
                          style={{
                            padding: '8px 8px 8px 0',
                            boxSizing: 'initial',
                            color: '#5e72e4',
                            verticalAlign: 'middle',
                            fontSize: '2rem',
                          }}
                        />

                        <b>{data.email}</b>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href="#empoderaTerapeuta"
                          onClick={() =>
                            deleteEventSweetAlert(
                              deleteInvite,
                              compartilhamento.id,
                              data.id,
                            )
                          }
                        >
                          <i className="fas fa-times" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Form ref={formMail} onSubmit={handleMail}>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-titulo">
                  Compartilhar com terapeuta especifico
                </label>
                <Input name="email" placeholder="Ex. email@terapeuta.com.br" />
                <br />
                <Button color="primary" type="submit">
                  Adicionar
                </Button>
              </Col>
            </Form>
          </Row>
        </div>
      </Modal>
    </>
  );
}
