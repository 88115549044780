export function indexRequest() {
  return {
    type: '@financeiroInstitutions/INDEX_REQUEST',
    payload: {},
  };
}

export function indexRequestSuccess(data) {
  return {
    type: '@financeiroInstitutions/INDEX_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function selectRequest() {
  return {
    type: '@financeiroInstitutions/SELECT_REQUEST',
  };
}

export function selectRequestSuccess(data) {
  return {
    type: '@financeiroInstitutions/SELECT_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function failure() {
  return {
    type: '@financeiroInstitutions/FAILURE',
  };
}
