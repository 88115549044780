import React, { useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';

import wppIcon from '~/assets/img/icons/upload/whatsapp.svg';
import MainHeader from '~/components/Headers/MainHeader';
import Input, { TextArea } from '~/components/Input';

// Requests
import { sendMessage } from '../../../store/modules/support/actions';

export default function Support() {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        subject: Yup.string().required(
          'Por favor insira um assunto em sua mensagem. ',
        ),
        message: Yup.string().required('É obrigatório informar uma mensagem.'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const { subject, message } = data;

      dispatch(sendMessage(subject, message));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <MainHeader
        name="Suporte"
        parentName="Dúvidas ou sugestões"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="12" className="d-flex justify-content-center">
                      <h1>Quer falar com a gente?</h1>
                    </Col>
                    <Col xs="12" className="d-flex justify-content-center ">
                      <h3>
                        Basta nos enviar um e-mail ou entrar em contato via
                        Whatsapp!
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="justify-content-center">
                    <Col
                      xs="8"
                      className="border rounded-lg mr-3 pt-2 pb-3 px-3"
                      style={{ maxWidth: '60%' }}
                    >
                      <Row className="d-flex justify-content-center">
                        <h3>E-mail</h3>
                      </Row>
                      <Form
                        className="needs-validation"
                        ref={formRef}
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        <Row>
                          <Col className="mb-3" md="12">
                            <Input
                              name="subject"
                              id="subject"
                              placeholder="Assunto *"
                              type="text"
                              className="form-control mb-3"
                            />
                            <TextArea
                              name="message"
                              id="message"
                              placeholder="Mensagem *"
                            />
                          </Col>
                        </Row>
                        <Button color="primary" type="submit">
                          Enviar
                        </Button>
                      </Form>
                    </Col>
                    <Col
                      xs="4"
                      className="border rounded-lg ml-3 p-2 message"
                      style={{
                        maxWidth: '30%',
                        maxHeight: '8rem',
                        cursor: 'pointer',
                      }}
                    >
                      <a target="blank" href="https://wa.me/555184354064">
                        <Row className="d-flex justify-content-center">
                          <h3>Whatsapp</h3>
                        </Row>
                        <Row>
                          <Col xs="4" className="m-0 p-3">
                            <div className="mt--4">
                              <img
                                src={wppIcon}
                                alt="Whatsapp icon"
                                style={{ width: '5rem' }}
                              />
                            </div>
                          </Col>
                          <Col xs="8" className=" p-0">
                            <h4>Wanessa Araujo</h4>
                            <h4>(51) 98435-4064</h4>
                          </Col>
                        </Row>
                      </a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
