import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

import {
  indexRequestSuccess,
  indexRequest as indexRequestCategoriaRegistro,
  categoriaRegistroRequestSuccess,
  failure,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage, date } = payload;
    const response = yield call(api.get, 'terapeutas/prontuario-categorias', {
      params: {
        page: tPage,
        perPage: tPerPage,
        date,
      },
    });
    const { total, perPage, page, lastPage, data } = response.data;

    yield put(indexRequestSuccess(total, perPage, page, lastPage, data));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* selectRequest() {
  try {
    const response = yield call(
      api.get,
      'terapeutas/listagem-categoria-registros',
    );

    yield put(
      indexRequestSuccess(response.data.length, 1, 1, 1, response.data),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const { name, color, icon, compartilhamento_id } = payload;
    yield call(api.post, 'terapeutas/prontuario-categorias', {
      name,
      color,
      icon,
      compartilhamento_id,
    });

    history.push('/terapeuta/categorias-registros');
    toast.success('Categoria cadastrada com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const { id, name, color, icon, compartilhamento_id } = payload;
    yield call(api.put, `terapeutas/prontuario-categorias/${id}`, {
      name,
      color,
      icon,
      compartilhamento_id,
    });

    yield put(
      categoriaRegistroRequestSuccess(name, color, icon, compartilhamento_id),
    );
    toast.success('Dados atualizados com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* categoriaRegistroRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(
      api.get,
      `terapeutas/prontuario-categorias/${id}`,
    );

    const { name, color, icon, compartilhamento_id } = response.data;
    if (!name) {
      yield put(failure());
      toast.error('Algo não deu certo.');
      history.push('/terapeuta/categorias-registros');
    }

    yield put(
      categoriaRegistroRequestSuccess(name, color, icon, compartilhamento_id),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
    history.push('/terapeuta/pacientes');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(
      api.delete,
      `terapeutas/prontuario-categorias/${id}`,
    );
    if (response) {
      yield put(indexRequestCategoriaRegistro(1, 20));
      toast.success('Categoria deletada com sucesso');
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error('Algo não deu certo.');
    }
    yield put(failure());
  }
}

export default all([
  takeLatest('@categoriasRegistro/INDEX_REQUEST', indexRequest),
  takeLatest('@categoriasRegistro/STORE_REQUEST', storeRequest),
  takeLatest('@categoriasRegistro/UPDATE_REQUEST', updateRequest),
  takeLatest(
    '@categoriasRegistro/CATEGORIA_REGISTRO_REQUEST',
    categoriaRegistroRequest,
  ),
  takeLatest('@categoriasRegistro/DELETE_REQUEST', deleteRequest),
  takeLatest('@categoriasRegistro/SELECT_REQUEST', selectRequest),
]);
