import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

import {
  signInSuccess,
  signFailure,
  signUpSuccess,
  terapeutaTiposSuccess,
} from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'sessions-terapeuta', {
      email,
      password,
    });

    const { token, terapeuta } = response.data;

    if (!terapeuta.situation) {
      toast.error(
        'Para você acessar o sistema precisa fazer a ativação de sua conta!',
      );
      yield put(signFailure());
      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token.token}`;

    yield put(signInSuccess(token, terapeuta));

    history.push('/terapeuta/dashboard');
  } catch (err) {
    toast.error(
      'Erro ao efetuar login, verifique seus dados e tente novamente.',
    );
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      terapeuta_tipo_id,
      tipo_outro,
      name,
      email,
      password,
      password_confirmation,
    } = payload;

    yield call(api.post, 'cadastro-terapeuta', {
      terapeuta_tipo_id,
      tipo_outro,
      name,
      email,
      password,
      password_confirmation,
    });

    yield put(signUpSuccess(email));

    history.push('/cadastro-success');
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error('Algo deu errado');
    }

    yield put(signFailure());
  }
}

export function* terapeutaTiposRequest() {
  try {
    const response = yield call(api.get, 'terapeutas-tipos');
    yield put(terapeutaTiposSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao carregar areas de atuação');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token.token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/TERAPEUTA_TIPO_REQUEST', terapeutaTiposRequest),
]);
