export function selectRequest() {
  return {
    type: '@agendamentoSituation/SELECT_REQUEST'
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {

  return {
    type: '@agendamentoSituation/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data }

  };
}


export function failure() {
  return {
    type: '@agendamentoSituation/FAILURE'
  };
}
