import produce from 'immer';

const INITIAL_STATE = {
  addModal: false,
  id: null
};

export default function modal(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@modal/FECHA_ADD_MODAL': {
        draft.addModal = action.payload.modal;
        draft.id = action.payload.id;
        break;
      }
      case '@modal/FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
