/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// reactstrap components
import { Button, Modal, Row, Col, FormGroup, Tooltip } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { transformMoeda, dateFormat } from '~/helpers/transform';
// import * as Yup from 'yup';
import Input, {
  Select,
  InputMasked,
  SimpleSelect,
  CheckboxInput,
} from '~/components/Input';
import CreatableAdvanced from './components/selectCategorias';

import {
  storeRequest,
  updateRequest,
  togleFechaModalRegistro,
} from '~/store/modules/financeiroRegistros/actions';

import { selectRequest as selectRequestContas } from '~/store/modules/financeiroContas/actions';
import { selectRequest as selectRequestMetodos } from '~/store/modules/financeiroMetodos/actions';
import { storeSuccess } from '~/store/modules/financeiroCategorias/actions';

export default function RegistroFinanceiro({ fromDashboard }) {
  const [pagoDisable, setPagoDisable] = useState({ disabled: 'disabled' });
  const [repDisable, setRepDisable] = useState({ disabled: 'disabled' });
  const [tooltipCat, setTooltipCat] = useState(false);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const financeiroModal = useSelector(
    (state) => state.financeiroRegistros.financeiroModal,
  );
  const selectContas = useSelector(
    (state) => state.financeiroContas.selectContas,
  );
  const selectMetodos = useSelector(
    (state) => state.financeiroMetodos.selectMetodos,
  );
  const { perPage, page: pageAtual, filter: filtrosAtuais } = useSelector(
    (state) => state.financeiroRegistros.financeiroRegistros,
  );

  const registroSelected = useSelector(
    (state) => state.financeiroRegistros.registroSelected,
  );

  function togleModal(value) {
    setPagoDisable({ disabled: 'disabled' });
    setRepDisable({ disabled: 'disabled' });
    dispatch(togleFechaModalRegistro(value));
    dispatch(
      storeSuccess({
        id: null,
        name: null,
        color: null,
      }),
    );
  }

  function toglePago(e) {
    if (e.checked && Number(e.value) === 3) {
      setPagoDisable(null);
    } else {
      setPagoDisable({ disabled: 'disabled' });
    }
  }

  function togleRecorrente(e) {
    if (e.checked && (Number(e.value) === 2 || Number(e.value) === 3)) {
      setRepDisable(null);
    } else {
      setRepDisable({ disabled: 'disabled' });
    }
  }

  useEffect(() => {
    dispatch(selectRequestContas());
    dispatch(selectRequestMetodos());
  }, [dispatch]);

  useEffect(() => {
    if (Number(registroSelected.financeiro_situation_id) === 3) {
      setPagoDisable(null);
    }
  }, [registroSelected]);

  async function handleCadastro(data) {
    try {
      /**
       * Create/save a new financeiroRegistro.
       * POST financeiro-registros
       *
       * @param {integer} parent_id --body
       * @param {integer} financeiro_categoria_id --body -required
       * @param {integer} financeiro_conta_id --body -required
       * @param {integer} financeiro_metodo_id --body -required
       * @param {integer} financeiro_situation_id --body -required
       * @param {integer} agendamento_id --body
       * @param {boolean} tipo --body -required
       * @param {integer} total_parcelas --body -required at financeiro_metodo = 3
       * @param {string} descricao --body -required
       * @param {date} data_vencimento --body -format "YYYY/MM/DD"
       * @param {date} data_pagamento --body -required -format "YYYY/MM/DD"
       * @param {date} data_competencia --body -format "YYYY/MM/DD"
       * @param {number} valor_base --body -required
       * @param {number} valor_efetivo --body -required
       * @param {boolean} recorrente --body
       * @param {string} tp_repeticao --tipo da repeticao a ser criada
       * @param {integer} registroModo --body (1 = à vista, 2 = parcelado, 3 = recorrente)
       */

      const {
        parent_id,
        financeiro_categoria_id,
        financeiro_conta_id,
        financeiro_metodo_id,
        financeiro_situation_id,
        tipo,
        total_parcelas,
        descricao,
        data_vencimento,
        data_pagamento,
        data_competencia,
        valor_base,
        valor_efetivo,
        registroModo,
        tp_repeticao,
        desconto,
        juros,
      } = data;

      const financeiro_card_id =
        Number(financeiro_metodo_id) === 3 ? financeiro_conta_id : null;

      const schema = Yup.object().shape({
        parent_id: Yup.number(),
        financeiro_categoria_id: Yup.string().required(
          'Selecione uma categoria',
        ),
        financeiro_conta_id: Yup.string().required('Selecione uma conta'),
        financeiro_situation_id: Yup.string(),
        financeiro_metodo_id: Yup.string().required('Selecione um método'),
        tipo: Yup.string().required('Tipo de registro é obrigatório'),
        registroModo: Yup.string().when('registroSelected', {
          is: (r) => Number(r) > 0,
          then: Yup.string().nullable(),
          otherwise: Yup.string()
            .matches(/^[1-3]$/, 'Informe o tipo')
            .required('Informe o tipo'),
        }),
        total_parcelas: Yup.string().when('registroModo', {
          is: (r) => r && (Number(r) === 2 || Number(r) === 3),
          then: Yup.string().required('Informe o total de repetições'),
          otherwise: Yup.string().nullable(),
        }),
        tp_repeticao: Yup.string().when('registroModo', {
          is: (r) => r && Number(r) === 3,
          then: Yup.string().required('Informe o tipo de repetição'),
          otherwise: Yup.string().nullable(),
        }),
        valor_efetivo: Yup.string().when('financeiro_situation_id', {
          is: (r) => Number(r) === 3,
          then: Yup.string()
            .matches(/^(?!R\$?.0,00)(R\$?..+)$/, 'Informe o valor pago')
            .required('Informe o valor pago'),
          otherwise: Yup.string().nullable(),
        }),
        valor_base: Yup.string().matches(
          /^(?!R\$?.0,00)(R\$?..+)$/,
          'Informe o valor base',
        ),
        descricao: Yup.string().required('Descrição é obrigatória'),
        data_vencimento: Yup.string()
          .matches(
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            'Data inválida',
          )
          .required('Informe o vencimento'),
        data_pagamento: Yup.string()
          .nullable(true)
          .when('financeiro_situation_id', {
            is: (r) => Number(r) === 3,
            then: Yup.string()
              .matches(
                /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                'Data inválida',
              )
              .required('Informe o dia de pagamento'),
            otherwise: Yup.string().nullable(),
          }),
        data_competencia: Yup.string()
          .matches(
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            'Data inválida',
          )
          .required('Informe o dia da compra'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (registroSelected.id) {
        dispatch(
          updateRequest(
            registroSelected.id,
            parent_id,
            financeiro_categoria_id,
            financeiro_conta_id,
            financeiro_metodo_id,
            financeiro_situation_id,
            null,
            tipo,
            total_parcelas,
            descricao,
            dateFormat(data_vencimento, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
            dateFormat(data_pagamento, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
            dateFormat(data_competencia, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
            transformMoeda(valor_base, 1),
            transformMoeda(valor_efetivo, 1),
            tp_repeticao,
            transformMoeda(desconto, 1),
            transformMoeda(juros, 1),
            pageAtual,
            perPage,
            filtrosAtuais,
          ),
        );
      } else {
        dispatch(
          storeRequest(
            parent_id,
            financeiro_categoria_id,
            financeiro_conta_id,
            financeiro_card_id,
            financeiro_metodo_id,
            financeiro_situation_id,
            null,
            tipo,
            total_parcelas,
            descricao,
            dateFormat(data_vencimento, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
            dateFormat(data_pagamento, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
            dateFormat(data_competencia, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
            transformMoeda(valor_base, 1),
            transformMoeda(valor_efetivo, 1),
            registroModo,
            tp_repeticao,
            transformMoeda(desconto, 1),
            transformMoeda(juros, 1),
            pageAtual,
            perPage,
            filtrosAtuais,
            fromDashboard,
          ),
        );
      }
      togleModal(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={financeiroModal}
        toggle={() => togleModal(!financeiroModal)}
      >
        <Form ref={formRef} onSubmit={handleCadastro}>
          <Input
            name="registroSelected"
            defaultValue={registroSelected.id}
            type="hidden"
          />
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Registro
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!financeiroModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="financeiroMetodos"
                  >
                    Método
                  </label>
                  <Select
                    id="financeiroMetodos"
                    name="financeiro_metodo_id"
                    className="form-control"
                    defaultValue={registroSelected.financeiro_metodo_id}
                    options={{
                      placeholder: 'Selecione um método',
                    }}
                    data={selectMetodos}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="financeiroContas"
                  >
                    Conta
                  </label>
                  <Select
                    id="financeiroContas"
                    name="financeiro_conta_id"
                    className="form-control"
                    defaultValue={registroSelected.financeiro_conta_id}
                    options={{
                      placeholder: 'Selecione uma conta',
                    }}
                    data={selectContas}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="finacneiroCategorias"
                  >
                    Categoria &nbsp;
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      id="tooltipCat"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-info-circle" />
                    </a>
                    <Tooltip
                      placement="right"
                      isOpen={tooltipCat}
                      target="tooltipCat"
                      toggle={() => setTooltipCat(!tooltipCat)}
                    >
                      Para criar uma nova categoria basta digitar o nome dela e
                      clicar em Nova:
                    </Tooltip>
                  </label>
                  <CreatableAdvanced
                    defaultValue={registroSelected.financeiro_categoria_id}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <label className="form-control-label">Tipo de registro: </label>
              </Col>
              <Col lg="9">
                <CheckboxInput
                  name="tipo"
                  checked={registroSelected.tipo}
                  options={[
                    { id: 'pagoTipo1', value: 1, label: 'Entrada' },
                    { id: 'pagoTipo0', value: 0, label: 'Saída' },
                  ]}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-descricao">
                  Descrição
                </label>
                <Input
                  name="descricao"
                  defaultValue={registroSelected.descricao}
                  className="form-control"
                  id="input-descricao"
                  placeholder="Descrição do registro"
                  type="text"
                />
              </Col>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-titulo">
                  Data competencia
                </label>
                <InputMasked
                  name="data_competencia"
                  className="form-control"
                  placeholder="Dia da compra"
                  kind="custom"
                  defaultValue={registroSelected.data_competencia}
                  options={{
                    mask: '99/99/9999',
                  }}
                />
              </Col>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-titulo">
                  Data Vencimento
                </label>
                <InputMasked
                  name="data_vencimento"
                  defaultValue={registroSelected.data_vencimento}
                  className="form-control"
                  placeholder="Dia para pagamento"
                  kind="custom"
                  options={{
                    mask: '99/99/9999',
                  }}
                />
              </Col>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-titulo">
                  Valor
                </label>
                <InputMasked
                  name="valor_base"
                  defaultValue={registroSelected.valor_base}
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                />
              </Col>
            </Row>
            {!registroSelected.id && (
              <>
                <hr />
                <Row>
                  <Col lg="12">
                    <CheckboxInput
                      name="registroModo"
                      options={[
                        { id: 'pagoRec1', value: 1, label: 'À vista' },
                        { id: 'pagoRec2', value: 2, label: 'Parcelado' },
                        { id: 'pagoRec3', value: 3, label: 'Recorrente' },
                      ]}
                      onClick={(e) => togleRecorrente(e.target)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <label
                      className="form-control-label"
                      htmlFor="selectPeriod"
                      style={{ visibility: 'hidden' }}
                    >
                      &nbsp;
                    </label>
                    <SimpleSelect
                      id="selectPeriod"
                      name="tp_repeticao"
                      options={[
                        { value: 'Diario', label: 'Diariamente' },
                        { value: 'Semanalmente', label: 'Semanalmente' },
                        { value: 'Mensal', label: 'Mensalmente' },
                        { value: 'Bimestralmente', label: 'Bimestralmente' },
                        { value: 'Trimestralmente', label: 'Trimestralmente' },
                        { value: 'Semestral', label: 'Semestralmente' },
                        { value: 'Anual', label: 'Anualmente' },
                      ]}
                      isDisabled={repDisable}
                    />
                  </Col>
                  <Col lg="3">
                    <label
                      className="form-control-label"
                      htmlFor="input-total_parcelas"
                    >
                      Qtd repetições
                    </label>
                    <Input
                      name="total_parcelas"
                      className="form-control"
                      id="input-total_parcelas"
                      placeholder="repetições"
                      type="text"
                      {...repDisable}
                    />
                  </Col>
                </Row>
              </>
            )}
            <hr />
            <Row>
              <Col lg="12">
                <CheckboxInput
                  name="financeiro_situation_id"
                  checked={registroSelected.financeiro_situation_id}
                  options={[{ id: 'pagoCheck1', value: 3, label: 'Pago' }]}
                  onClick={(e) => toglePago(e.target)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-pagto">
                  Data Pagamento
                </label>
                <InputMasked
                  id="input-pagto"
                  name="data_pagamento"
                  className="form-control"
                  placeholder="Dia para pagamento"
                  defaultValue={registroSelected.data_pagamento}
                  kind="custom"
                  options={{
                    mask: '99/99/9999',
                  }}
                  {...pagoDisable}
                />
              </Col>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-desconto">
                  Desconto / Taxas
                </label>
                <InputMasked
                  id="input-desconto"
                  name="desconto"
                  defaultValue={registroSelected.desconto}
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                  {...pagoDisable}
                />
              </Col>
              <Col lg="3">
                <label className="form-control-label" htmlFor="input-titulo">
                  Juros / Multa
                </label>
                <InputMasked
                  name="juros"
                  defaultValue={registroSelected.juros}
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                  {...pagoDisable}
                />
              </Col>
              <Col lg="3">
                <label
                  className="form-control-label"
                  htmlFor="input-valor-efetivo"
                >
                  Valor efetivo
                </label>
                <InputMasked
                  id="input-valor-efetivo"
                  name="valor_efetivo"
                  defaultValue={registroSelected.valor_efetivo}
                  className="form-control"
                  placeholder="Valor"
                  kind="money"
                  {...pagoDisable}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!financeiroModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

RegistroFinanceiro.propTypes = {
  fromDashboard: PropTypes.bool,
};

// Same approach for defaultProps too
RegistroFinanceiro.defaultProps = {
  fromDashboard: false,
};
