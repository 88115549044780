export function indexRequest(page, perPage, filter) {
  return {
    type: '@financeiroRegistros/INDEX_REQUEST',
    payload: {
      page,
      perPage,
      filter,
    },
  };
}

export function indexRequestSuccess(
  total,
  perPage,
  page,
  lastPage,
  data,
  filter,
) {
  return {
    type: '@financeiroRegistros/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data, filter },
  };
}

export function storeRequest(
  parent_id,
  financeiro_categoria_id,
  financeiro_conta_id,
  financeiro_card_id,
  financeiro_metodo_id,
  financeiro_situation_id,
  agendamento_id,
  tipo,
  total_parcelas,
  descricao,
  data_vencimento,
  data_pagamento,
  data_competencia,
  valor_base,
  valor_efetivo,
  registroModo,
  tp_repeticao,
  desconto,
  juros,
  page,
  perPage,
  filter,
  fromDashboard,
) {
  return {
    type: '@financeiroRegistros/STORE_REQUEST',
    payload: {
      parent_id,
      financeiro_categoria_id,
      financeiro_conta_id,
      financeiro_card_id,
      financeiro_metodo_id,
      financeiro_situation_id,
      agendamento_id,
      tipo,
      total_parcelas,
      descricao,
      data_vencimento,
      data_pagamento,
      data_competencia,
      valor_base,
      valor_efetivo,
      registroModo,
      tp_repeticao,
      desconto,
      juros,
      page,
      perPage,
      filter,
      fromDashboard,
    },
  };
}

export function updateRequest(
  id,
  parent_id,
  financeiro_categoria_id,
  financeiro_conta_id,
  financeiro_metodo_id,
  financeiro_situation_id,
  agendamento_id,
  tipo,
  total_parcelas,
  descricao,
  data_vencimento,
  data_pagamento,
  data_competencia,
  valor_base,
  valor_efetivo,
  tp_repeticao,
  desconto,
  juros,
  page,
  perPage,
  filter,
) {
  return {
    type: '@financeiroRegistros/UPDATE_REQUEST',
    payload: {
      id,
      parent_id,
      financeiro_categoria_id,
      financeiro_conta_id,
      financeiro_metodo_id,
      financeiro_situation_id,
      agendamento_id,
      tipo,
      total_parcelas,
      descricao,
      data_vencimento,
      data_pagamento,
      data_competencia,
      valor_base,
      valor_efetivo,
      tp_repeticao,
      desconto,
      juros,
      page,
      perPage,
      filter,
    },
  };
}

export function showRequest(id) {
  return {
    type: '@financeiroRegistros/SHOW_REQUEST',
    payload: {
      id,
    },
  };
}

export function showRequestSuccess(data) {
  return {
    type: '@financeiroRegistros/SHOW_REQUEST_SUCSESS',
    payload: {
      ...data,
    },
  };
}

export function togleFechaModalRegistro(modal, tipo) {
  return {
    type: '@financeiroRegistros/FECHA_REGISTRO_MODAL',
    payload: { modal, tipo },
  };
}

export function togleImportModal(modal) {
  return {
    type: '@financeiroRegistros/IMPORT_MODAL',
    payload: { modal },
  };
}

export function importRequest(conta_id, upload) {
  return {
    type: '@financeiroRegistros/IMPORT_REQUEST',
    payload: { conta_id, upload },
  };
}

export function updateFaturaRequest(
  id,
  cardId,
  data_pagamento,
  financeiro_situation_id,
  valor_efetivo,
  juros,
  desconto,
  financeiro_conta_id,
) {
  return {
    type: '@financeiroRegistros/UPDATE_FATURA_REQUEST',
    payload: {
      id,
      cardId,
      data_pagamento,
      financeiro_situation_id,
      valor_efetivo,
      juros,
      desconto,
      financeiro_conta_id,
    },
  };
}

export function quickUpdateRequest(
  id,
  financeiro_situation_id,
  page,
  perPage,
  filter,
  funcCallback,
) {
  return {
    type: '@financeiroRegistros/QUICK_UPDATE_REQUEST',
    payload: {
      id,
      financeiro_situation_id,
      page,
      perPage,
      filter,
      funcCallback,
    },
  };
}

export function deleteRequest(id, page, perPage, filter) {
  return {
    type: '@financeiroRegistros/DELETE_REQUEST',
    payload: {
      id,
      page,
      perPage,
      filter,
    },
  };
}

export function failure() {
  return {
    type: '@financeiroRegistros/FAILURE',
  };
}
