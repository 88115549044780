export function calendarRequest() {
  return {
    type: '@agendamentos/CALENDAR_REQUEST',
  };
}

export function calendarRequestSuccess(data) {
  return {
    type: '@agendamentos/CALENDAR_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function indexRequest(date) {
  return {
    type: '@agendamentos/INDEX_REQUEST',
    payload: { date },
  };
}

export function indexRequestSuccess(data) {
  return {
    type: '@agendamentos/INDEX_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function storeRequest(
  tp_repeticao,
  agendamentoModo,
  paciente_id,
  agendamento_categoria_id,
  observation,
  initial_date,
  final_date,
) {
  return {
    type: '@agendamentos/STORE_REQUEST',
    payload: {
      tp_repeticao,
      agendamentoModo,
      paciente_id,
      agendamento_categoria_id,
      observation,
      initial_date,
      final_date,
    },
  };
}

export function storeRequestSuccess() {
  return {
    type: '@agendamentos/STORE_REQUEST_SUCCESS',
    payload: {},
  };
}

export function storeAgendamentoConflict(agendamentos) {
  return {
    type: '@agendamentos/STORE_REQUEST_CONFLICT',
    payload: { agendamentos },
  };
}

export function agendamentoRequest(id) {
  return {
    type: '@agendamentos/AGENDAMENTO_REQUEST',
    payload: { id },
  };
}

export function atendimentoRequest(id) {
  return {
    type: '@agendamentos/ATENDIMENTO_REQUEST',
    payload: { id },
  };
}

export function atendimentoRequestSuccess(
  id,
  paciente,
  agendamento_situation_id,
  agendamento_start,
  observation,
  agendamento_categoria_id,
) {
  return {
    type: '@agendamentos/ATENDIMENTO_REQUEST_SUCCESS',
    payload: {
      id,
      paciente,
      agendamento_situation_id,
      agendamento_start,
      observation,
      agendamento_categoria_id,
    },
  };
}

export function agendamentoShowSuccess(
  id,
  paciente_id,
  agendamento_categoria_id,
  agendamento_start,
  observation,
) {
  return {
    type: '@agendamentos/AGENDAMENTO_SHOW_SUCCESS',
    payload: {
      id,
      paciente_id,
      agendamento_categoria_id,
      agendamento_start,
      observation,
    },
  };
}

export function updateRequest(
  id,
  paciente_id,
  agendamento_categoria_id,
  agendamento_start,
  observation,
) {
  return {
    type: '@agendamentos/UPDATE_REQUEST',
    payload: {
      id,
      paciente_id,
      agendamento_categoria_id,
      agendamento_start,
      observation,
    },
  };
}

export function updateByAtendimentoRequest(
  id,
  paciente_id,
  agendamento_situation_id,
  agendamento_categoria_id,
) {
  return {
    type: '@agendamentos/UPDATE_REQUEST',
    payload: {
      id,
      paciente_id,
      agendamento_situation_id,
      agendamento_categoria_id,
    },
  };
}

export function deleteRequest(id) {
  return {
    type: '@agendamentos/DELETE_REQUEST',
    payload: { id },
  };
}

export function deleteRequestSuccess() {
  return {
    type: '@agendamentos/DELETE_REQUEST_SUCCESS',
  };
}

export function togleFechaModalAdd(modal, newDate) {
  return {
    type: '@agendamentos/FECHA_ADD_MODAL',
    payload: { modal, newDate },
  };
}

export function togleFechaModalEdit(modal) {
  return {
    type: '@agendamentos/FECHA_EDIT_MODAL',
    payload: { modal },
  };
}

export function togleFechaModalAtendimento(modal) {
  return {
    type: '@agendamentos/FECHA_ATENDIMENTO_MODAL',
    payload: { modal },
  };
}

export function failure() {
  return {
    type: '@agendamentos/FAILURE',
  };
}
