export function indexRequest(token) {
  return {
    type: '@activation/INDEX_REQUEST',
    payload: {token}
  };
}

export function failure() {
  return {
    type: '@activation/FAILURE'
  };
}
