import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';
import {
  indexRequestSuccess,
  registroAtendimentoRequestSuccess,
  indexRequest as indexRequestRegistrosAtendimento,
  failure,
  updateSuccess,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage, filters } = payload;
    const response = yield call(api.get, 'terapeutas/prontuarios', {
      params: {
        page: tPage,
        perPage: tPerPage,
        ...filters,
      },
    });
    const { total, perPage, page, lastPage, data } = response.data;

    yield put(
      indexRequestSuccess(total, perPage, page, lastPage, data, filters),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    } = payload;

    yield call(api.post, 'terapeutas/prontuarios', {
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    });

    toast.success('Cadastro feito com sucesso!');
    history.push('/terapeuta/registros-atendimentos');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* registroAtendimentoRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `terapeutas/prontuarios/${id}`);
    const {
      prontuario_categoria_id,
      paciente_id,
      title,
      conteudo,
      paciente,
      files,
    } = response.data;

    yield put(
      registroAtendimentoRequestSuccess(
        prontuario_categoria_id,
        paciente_id,
        title,
        conteudo,
        paciente,
        files,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo, verifique os erros e tente novamente.');
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      paciente_id,
      title,
      prontuario_categoria_id,
      conteudo,
      files,
    } = payload;
    yield call(api.put, `terapeutas/prontuarios/${id}`, {
      title,
      paciente_id,
      prontuario_categoria_id,
      conteudo,
      files,
    });

    yield put(updateSuccess());
    toast.success('Dados atualizados com sucesso.');
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id, filters, page, perPage } = payload;
    const response = yield call(api.delete, `terapeutas/prontuarios/${id}`);

    if (response) {
      toast.success('Registro deletado com sucesso.');
      yield put(indexRequestRegistrosAtendimento(page, perPage, filters));
    }
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest('@registrosAtendimentos/INDEX_REQUEST', indexRequest),
  takeLatest('@registrosAtendimentos/STORE_REQUEST', storeRequest),
  takeLatest('@registrosAtendimentos/UPDATE_REQUEST', updateRequest),
  takeLatest('@registrosAtendimentos/DELETE_REQUEST', deleteRequest),
  takeLatest(
    '@registrosAtendimentos/REGISTRO_ATENDIMENTO_REQUEST',
    registroAtendimentoRequest,
  ),
]);
