import React from 'react';
import PropTypes from 'prop-types';

function LoadingCard({ show }) {
  if (show) {
    return (
      <div className="loader-card">
        <div className="spinner-card" />
        <div className="spinner-card-text">Carregando...</div>
      </div>
    );
  }
  return <></>;
}

LoadingCard.propTypes = {
  show: PropTypes.bool,
};

// Same approach for defaultProps too
LoadingCard.defaultProps = {
  show: false,
};

export default LoadingCard;
