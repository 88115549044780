import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { indexRequestSuccess, failure } from './actions';

export function* indexRequest() {
  try {
    const response = yield call(api.get, 'terapeutas/financeiro-institutions');

    const institutions = [];
    response.data.forEach((institution) => {
      institutions.push({
        id: institution.id,
        name: institution.name,
      });
    });

    yield put(indexRequestSuccess(institutions));
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export default all([
  takeLatest('@financeiroInstitutions/INDEX_REQUEST', indexRequest),
]);
