/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import MainHeader from '../../../components/Headers/MainHeader';
import history from '../../../services/history';
import Paginations from '../../../components/Pagination/Paginations';
import ModalDelete from '../../../components/ConfirmDelete';
import loadingGlobal from '../../../components/loadingGlobal';

import { togleFechaModalAdd } from '../../../store/modules/modal/actions';
import {
  indexRequest,
  deleteRequest,
} from '../../../store/modules/categoriasRegistro/actions';

export default function CategoriaRegistros() {
  const addModal = useSelector((state) => state.modal.addModal);
  const categoriasLoadingData = useSelector(
    (state) => state.categoriasRegistro.loading,
  );
  const categoriasRegistros = useSelector(
    (state) => state.categoriasRegistro.categoriasRegistro.data,
  );
  const { total, perPage, page } = useSelector(
    (state) => state.categoriasRegistro.categoriasRegistro,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    loadingGlobal(categoriasLoadingData);
  }, [categoriasLoadingData]);

  useEffect(() => {
    dispatch(indexRequest(1, 20));
  }, [dispatch]);

  function handleDelete(id) {
    dispatch(togleFechaModalAdd(!addModal, id));
  }

  return (
    <>
      <MainHeader
        name="Categorias De Registros"
        parentName="Configurações"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Categorias de Registros</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href="#empoderaTerapeuta"
                  onClick={() =>
                    history.push('/terapeuta/categorias-registro/novo')
                  }
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                  <span className="btn-inner--text">Nova Categoria</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th style={{ width: '10px' }} />
                <th>Nome</th>
                <th>Data de criação</th>

                <th />
              </tr>
            </thead>
            <tbody>
              {categoriasRegistros.map((data) => (
                <tr key={data.id}>
                  <td>
                    {data.color && (
                      <span
                        className="timeline-step badge-success"
                        style={{
                          position: 'relative',
                          backgroundColor: data.color,
                          color: '#fff',
                        }}
                      >
                        <i className={`fa fa-${data.icon}`} />
                      </span>
                    )}
                  </td>
                  <td className="table-user">
                    <b>
                      <a
                        style={{
                          color: '#525f7f',
                        }}
                        className=""
                        href="#empoderaTerapeuta"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(
                            {
                              pathname: `/terapeuta/categorias-registro/editar/${data.id}`,
                            },
                            {
                              previousPath: history.location.pathname,
                            },
                          );
                        }}
                      >
                        {data.name}
                      </a>
                    </b>
                  </td>
                  <td>
                    <span className="text-muted">
                      {moment(data.created_at).format('DD/MM/YYYY')}
                    </span>
                  </td>

                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/categorias-registro/editar/${data.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action-delete"
                      href="#empoderaTerapeuta"
                      onClick={() => handleDelete(data.id)}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
              <ModalDelete deleteRequest={deleteRequest} />
            </tbody>
          </Table>
          <Paginations
            page={page}
            total={total}
            perPage={perPage}
            funcaoIndex={indexRequest}
          />
        </Card>
      </Container>
    </>
  );
}
