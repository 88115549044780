import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// reactstrap components
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
} from 'reactstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from '~/components/Input';

import {
  togleFechaModalAdd,
  storeRequest,
} from '~/store/modules/financeiroCategorias/actions';

export default function ModalCategorias() {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const addCategoriaModal = useSelector(
    (state) => state.financeiroCategorias.addModal,
  );
  const categoriaId = useSelector(
    (state) => state.financeiroCategorias.categoriaId,
  );
  const categoriaName = useSelector(
    (state) => state.financeiroCategorias.categoriaName,
  );

  function togleModal(value) {
    dispatch(togleFechaModalAdd(value));
  }

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        color: Yup.string().required('Você precisa escolher uma cor'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const parent_id = categoriaId;
      const { name, color } = data;

      dispatch(storeRequest(name, color, parent_id));

      togleModal(!addCategoriaModal);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={addCategoriaModal}
        toggle={() => togleModal(!addCategoriaModal)}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {categoriaId ? 'Criar Nova Subcategoria' : 'Criar Nova Categoria'}
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addCategoriaModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            {categoriaId && (
              <>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="categoria-name"
                      >
                        Categoria pai
                      </label>
                      <InputGroup className="input-group-merge input-group mb-3">
                        <Input
                          name="categoria-name"
                          id="name"
                          placeholder="Nome da categoria pai"
                          type="text"
                          defaultValue={categoriaName}
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {categoriaId ? 'Nome da Subcategoria' : 'Nome da Categoria'}
                  </label>
                  <InputGroup className="input-group-merge input-group mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="name"
                      id="name"
                      placeholder={
                        categoriaId
                          ? 'Nome da Subcategoria'
                          : 'Nome da Categoria'
                      }
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <label className="form-control-label" htmlFor="color">
                  Cor
                </label>
                <Input
                  name="color"
                  id="color"
                  defaultValue="#2dce89"
                  type="color"
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addCategoriaModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
