import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// reactstrap components
import { Button, Modal, Row, Col, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Input, { Select, EditorTexto } from '../../../components/Input';
import '../../../assets/vendor/quill/dist/quill.core.css';
import Upload from '../../../components/Upload';

import { storeRequest } from '../../../store/modules/pacientesRegistro/actions';
import {
  updateByAtendimentoRequest,
  togleFechaModalAtendimento,
} from '../../../store/modules/agendamentos/actions';

export default function AddRegistroAtendimento() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const addModal = useSelector((state) => state.agendamentos.atendimentoModal);
  const agendamento = useSelector((state) => state.agendamentos.atendimento);
  const agendamentoSituation = useSelector(
    (state) => state.agendamentoSituation.agendamentoSituation.data,
  );

  async function handleCadastro(data) {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('Titulo é obrigatório'),
        agendamento_situation_id: Yup.number()
          .moreThan(1, 'Você precisa mudar a situação')
          .required('Selecione uma situação'),
        conteudo: Yup.string().required(
          'Registro do atendimento é obrigatório',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { agendamento_situation_id, title, conteudo, files } = data;
      dispatch(
        storeRequest(
          agendamento.id,
          agendamento.paciente.id,
          1,
          title,
          conteudo,
          files,
        ),
      );
      dispatch(
        updateByAtendimentoRequest(
          agendamento.id,
          agendamento.paciente.id,
          agendamento_situation_id,
          agendamento.agendamento_categoria_id,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  function togleModal(value) {
    dispatch(togleFechaModalAtendimento(value));
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addModal}
        toggle={() => togleModal(!addModal)}
      >
        <Form ref={formRef} onSubmit={handleCadastro}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Atendimento
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    Cliente
                  </label>
                  <div>{agendamento.paciente && agendamento.paciente.name}</div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Dia e horário
                  </label>
                  <div>{agendamento.agendamento_start}</div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols3Input"
                  >
                    Situação do atendimento
                  </label>
                  <Select
                    name="agendamento_situation_id"
                    className="form-control"
                    defaultValue={agendamento.agendamento_situation_id}
                    options={{
                      placeholder: 'Selecione',
                    }}
                    data={agendamentoSituation}
                  />
                </FormGroup>
              </Col>
            </Row>
            {agendamento.observation && (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="observation">
                      Notas do agendamento
                    </label>
                    <div id="observation">{agendamento.observation}</div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label" htmlFor="input-titulo">
                  Titulo do Registro de atendimento
                </label>
                <Input
                  name="title"
                  id="input-titulo"
                  placeholder="Titulo"
                  type="text"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12">
                <label className="form-control-label">
                  Detalhamento do atendimento
                </label>
                <EditorTexto
                  name="conteudo"
                  theme="snow"
                  modules={{
                    toolbar: [
                      ['bold', 'italic'],
                      ['link', 'blockquote', 'code', 'image'],
                      [
                        {
                          list: 'ordered',
                        },
                        {
                          list: 'bullet',
                        },
                      ],
                    ],
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Upload />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togleModal(!addModal)}
            >
              Fechar
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
