import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  registroAtendimento: {
    paciente_id: null,
    paciente_name: null,
    prontuario_categoria_id: null,
    title: null,
    conteudo: null,
    files: [],
  },
  registrosCompartilhados: {
    total: 0,
    perPage: 20,
    page: 1,
    lastPage: 1,
    data: [],
    filters: {},
  },
};

export default function registrosCompartilhados(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@registrosCompartilhados/INDEX_REQUEST_SUCCESS': {
        draft.registrosCompartilhados.total = Number(action.payload.total);
        draft.registrosCompartilhados.perPage = Number(action.payload.perPage);
        draft.registrosCompartilhados.page = Number(action.payload.page);
        draft.registrosCompartilhados.lastPage = Number(
          action.payload.lastPage,
        );
        draft.registrosCompartilhados.data = action.payload.data;
        draft.registrosCompartilhados.filters = action.payload.filters;
        draft.loading = false;
        break;
      }
      case '@registrosCompartilhados/REGISTRO_ATENDIMENTO_REQUEST_SUCCESS': {
        draft.registroAtendimento.title = action.payload.title;
        draft.registroAtendimento.conteudo = action.payload.conteudo;
        draft.registroAtendimento.prontuario_categoria_id =
          action.payload.prontuario_categoria_id;
        draft.registroAtendimento.paciente_id = action.payload.paciente_id;
        draft.registroAtendimento.paciente_name = action.payload.paciente.name;
        draft.registroAtendimento.files = action.payload.files;
        draft.loading = false;
        break;
      }
      case '@registrosCompartilhados/INDEX_REQUEST':
      case '@registrosCompartilhados/REGISTRO_ATENDIMENTO_REQUEST': {
        draft.loading = true;
        break;
      }

      default:
    }
  });
}
