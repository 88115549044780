import React from 'react';
// react library for routing
import { Link } from 'react-router-dom';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

class AuthHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  {this.props.title ? (
                    <h1 className="text-white">{this.props.title}</h1>
                  ) : null}
                  {this.props.beforeLink ||
                  this.props.link ||
                  this.props.afterLink ? (
                    <p className="text-lead text-white">
                      {this.props.beforeLink}{' '}
                      <Link
                        className="text-white text-underline"
                        to="/terapeuta-lista-espera"
                      >
                        {this.props.link}
                      </Link>{' '}
                      {this.props.afterLink}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  beforeLink: PropTypes.string,
  link: PropTypes.string,
  afterLink: PropTypes.string,
};

export default AuthHeader;
