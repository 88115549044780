/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Gravatar from 'react-gravatar';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

// core components
import moment from 'moment';
import ModalDelete from '~/components/ConfirmDelete';
import MainHeader from '~/components/Headers/MainHeader';
import Search from './search';
import { indexRequest, deleteRequest } from '~/store/modules/pacientes/actions';
import { togleFechaModalAdd } from '~/store/modules/modal/actions';
import history from '~/services/history';
import Paginations from '~/components/Pagination/Paginations';
import loadingGlobal from '~/components/loadingGlobal';

export default function Pacientes() {
  const [defaultFilters, setDefaultFilters] = useState({});
  const addModal = useSelector((state) => state.modal.addModal);
  const pacientesLoadingData = useSelector((state) => state.pacientes.loading);
  const { total, perPage, page, data: pacientes, filters } = useSelector(
    (state) => state.pacientes.pacientes,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    loadingGlobal(pacientesLoadingData);
  }, [pacientesLoadingData]);

  useEffect(() => {
    setDefaultFilters(
      history.location.state ? history.location.state.filters : {},
    );
  }, []);

  useEffect(() => {
    dispatch(indexRequest(1, 20, defaultFilters));
  }, [dispatch, defaultFilters]);

  function handleDelete(id) {
    dispatch(togleFechaModalAdd(!addModal, id));
  }

  return (
    <>
      <MainHeader
        name="Listagem de clientes"
        parentName="Clientes"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Filtros</h3>
              </Col>
            </Row>
          </CardHeader>
          <Search
            page={page}
            perPage={perPage}
            defaultFilters={defaultFilters}
          />
        </Card>
      </Container>
      <Container fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Clientes</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(
                      {
                        pathname: `/terapeuta/paciente/novo`,
                      },
                      {
                        previousPath: history.location.pathname,
                        filters,
                      },
                    );
                  }}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                  <span className="btn-inner--text">Novo Cliente</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Nome</th>
                <th>Nascimento</th>
                <th>E-mail</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {pacientes.map((data) => (
                <tr key={data.id}>
                  <td className="table-user">
                    <Gravatar
                      email={data.email || '00000000000000000000000000000000'}
                      className="avatar rounded-circle mr-3"
                      default="mp"
                    />
                    <b>
                      <a
                        className=""
                        href="#empoderaTerapeuta"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(
                            {
                              pathname: `/terapeuta/paciente/perfil/${data.id}`,
                            },
                            {
                              previousPath: history.location.pathname,
                              filters,
                            },
                          );
                        }}
                      >
                        {data.name}
                      </a>
                    </b>
                  </td>
                  <td>
                    <span className="text-muted">
                      {moment(data.nascimento).format('DD/MM/YYYY')}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted">{data.email}</span>
                  </td>
                  <td className="table-actions">
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          {
                            pathname: `/terapeuta/paciente/perfil/${data.id}`,
                          },
                          {
                            previousPath: history.location.pathname,
                            filters,
                          },
                        );
                      }}
                    >
                      <i className="fas fa-user-edit" />
                    </a>
                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={() => {
                        handleDelete(data.id);
                      }}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            <ModalDelete
              deleteRequest={deleteRequest}
              page={page}
              perPage={perPage}
              filter={filters}
            />
          </Table>
          <Paginations
            page={page}
            total={total}
            perPage={perPage}
            funcaoIndex={indexRequest}
            filter={filters}
          />
        </Card>
      </Container>
    </>
  );
}
