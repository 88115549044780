import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  indexRequestSuccess,
  indexRequest as indexRequestComments,
  failure
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { prontuario_id } = payload;
    const response = yield call(api.get, `terapeutas/registros-comentarios/${prontuario_id}`);

    yield put(indexRequestSuccess(response.data));

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export function* storeRequest({ payload }) {
  try {
    const { prontuario_id, comment } = payload;

    yield call(api.post, 'terapeutas/registros-comentarios', {
      prontuario_id,
      comment
    });

    toast.success("Comentário feito com sucesso!");
    yield put(indexRequestComments(prontuario_id));

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo, verifique os erros e tente novamente.");
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { comment_id, prontuario_id } = payload;
    const response = yield call(api.delete, `terapeutas/registros-comentarios/${comment_id}`);

    if (response) {
      toast.success("Comentário deletado com sucesso.");
      yield put(indexRequestComments(prontuario_id));
    }

  } catch (err) {
    yield put(failure());
    toast.error("Algo não deu certo.");
  }
}

export default all([
  takeLatest('@registroComments/INDEX_REQUEST', indexRequest),
  takeLatest('@registroComments/STORE_REQUEST', storeRequest),
  takeLatest('@registroComments/DELETE_REQUEST', deleteRequest),
]);
