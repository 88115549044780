import { takeLatest, all, call, put } from 'redux-saga/effects';
import moment from 'moment';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';
import { dateFormat } from '~/helpers/transform';

import {
  indexRequestSuccess,
  pacienteRequestSuccess,
  pacienteFound,
  toglePacienteFound,
  failure,
  indexRequest as indexRequestPacientes,
  quickStoreRequestSuccess,
} from './actions';

export function* indexRequest({ payload }) {
  try {
    const { page: tPage, perPage: tPerPage, filters } = payload;
    const activeFilters = {
      name: null,
      email: null,
      telefone: null,
      nascimento: null,
    };

    if (filters) {
      activeFilters.name = filters.name ? filters.name : null;
      activeFilters.email = filters.email ? filters.email : null;
      activeFilters.telefone = filters.telefone ? filters.telefone : null;
      activeFilters.nascimento = filters.nascimento ? filters.nascimento : null;
    }

    const response = yield call(api.get, 'terapeutas/pacientes', {
      params: {
        page: tPage,
        perPage: tPerPage,
        name: activeFilters.name,
        email: activeFilters.email,
        nascimento: activeFilters.nascimento,
        telefone: activeFilters.telefone,
      },
    });

    const { total, perPage, page, lastPage, data } = response.data;

    yield put(
      indexRequestSuccess(total, perPage, page, lastPage, data, activeFilters),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* selectRequest() {
  try {
    const response = yield call(api.get, 'terapeutas/listagem-pacientes');

    yield put(
      indexRequestSuccess(response.data.length, 1, 1, 1, response.data),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* storeRequest({ payload }) {
  try {
    const {
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      forceAdd,
    } = payload;

    const tratNacimento = nascimento
      ? dateFormat(nascimento, ['DD-MM-YYYY'], 'YYYY-MM-DD')
      : null;

    const response = yield call(api.post, 'terapeutas/pacientes', {
      name,
      nascimento: tratNacimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      forceAdd,
    });

    const { hasPaciente, data: pacientesExistentes, id } = response.data;

    if (hasPaciente) {
      yield put(pacienteFound(pacientesExistentes, payload));
    } else {
      yield put(toglePacienteFound(false));
      toast.success('Cliente cadastrado com sucesso.');

      history.push(`/terapeuta/paciente/perfil/${id}`);
    }
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error('Algo deu errado');
    }

    yield put(failure());
  }
}

export function* quickStoreRequest({ payload }) {
  try {
    const { name, nascimento, email, celular, telefone } = payload;

    const tratNacimento = nascimento
      ? dateFormat(nascimento, ['DD-MM-YYYY'], 'YYYY-MM-DD')
      : null;

    const response = yield call(api.post, 'terapeutas/pacientes', {
      name,
      nascimento: tratNacimento,
      email,
      celular,
      telefone,
    });

    const { hasPaciente, data: pacientesExistentes, id } = response.data;

    if (hasPaciente) {
      yield put(pacienteFound(pacientesExistentes, payload));
    } else {
      yield put(toglePacienteFound(false));
      toast.success('Cliente cadastrado com sucesso.');
      yield put(indexRequestPacientes());
      yield put(
        quickStoreRequestSuccess(
          id,
          name,
          nascimento,
          email,
          celular,
          telefone,
        ),
      );
    }
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error('Algo deu errado');
    }

    yield put(failure());
  }
}

export function* updateRequest({ payload }) {
  try {
    const {
      id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      guardians,
    } = payload;

    const tratNacimento = nascimento
      ? dateFormat(nascimento, ['DD-MM-YYYY'], 'YYYY-MM-DD')
      : null;

    const response = yield call(api.put, `terapeutas/pacientes/${id}`, {
      name,
      email,
      nascimento: tratNacimento,
      uf,
      endereco,
      numero,
      complemento,
      bairro,
      celular,
      telefone,
      cep,
      cidade,
    });

    const { responsavel_id } = response.data;
    yield put(
      pacienteRequestSuccess(
        id,
        responsavel_id,
        name,
        nascimento,
        email,
        celular,
        telefone,
        cep,
        cidade,
        uf,
        bairro,
        endereco,
        numero,
        complemento,
        guardians,
      ),
    );
    toast.success('Dados atualizados com sucesso.');
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((error) => toast.error(error.message));
    } else {
      toast.error('Algo deu errado');
    }

    yield put(failure());
  }
}

export function* pacienteRequest({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `terapeutas/pacientes/${id}`);

    const {
      responsavel_id,
      name,
      nascimento,
      email,
      celular,
      telefone,
      cep,
      cidade,
      uf,
      bairro,
      endereco,
      numero,
      complemento,
      guardians,
    } = response.data;

    if (!name) {
      yield put(failure());
      toast.error('Algo não deu certo.');
      history.push('/terapeuta/pacientes');
    }

    yield put(
      pacienteRequestSuccess(
        id,
        responsavel_id,
        name,
        moment(nascimento).format('DD/MM/YYYY'),
        email,
        celular,
        telefone,
        cep,
        cidade,
        uf,
        bairro,
        endereco,
        numero,
        complemento,
        guardians,
      ),
    );
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
    history.push('/terapeuta/pacientes');
  }
}

export function* pacienteAttach({ payload }) {
  try {
    const { paciente, quickStore } = payload;
    const pacienteId = paciente.id;

    yield call(api.post, 'terapeutas/paciente-attach', { pacienteId });
    yield put(toglePacienteFound(false));
    toast.success('Paciente adicionado com sucesso.');
    if (!quickStore) {
      history.push('/terapeuta/pacientes');
    } else {
      yield put(
        quickStoreRequestSuccess(
          paciente.id,
          paciente.name,
          paciente.nascimento,
          paciente.email,
          paciente.celular,
          paciente.telefone,
        ),
      );
    }
  } catch (err) {
    yield put(failure());
    toast.error('Algo não deu certo.');
  }
}

export function* deleteRequest({ payload }) {
  try {
    const { id, page: tPage, perPage: tPerPage, filters } = payload;
    const response = yield call(api.delete, `terapeutas/pacientes/${id}`);

    if (response) {
      yield put(indexRequestPacientes(tPage, tPerPage, filters));
      toast.success('Cliente deletado com sucesso!');
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error('Algo não deu certo.');
    }
    yield put(failure());
  }
}

export default all([
  takeLatest('@pacientes/STORE_REQUEST', storeRequest),
  takeLatest('@pacientes/QUICK_STORE_REQUEST', quickStoreRequest),
  takeLatest('@pacientes/UPDATE_REQUEST', updateRequest),
  takeLatest('@pacientes/INDEX_REQUEST', indexRequest),
  takeLatest('@pacientes/PACIENTE_REQUEST', pacienteRequest),
  takeLatest('@pacientes/SELECT_REQUEST', selectRequest),
  takeLatest('@pacientes/PACIENTE_ATTACH', pacienteAttach),
  takeLatest('@pacientes/DELETE_REQUEST', deleteRequest),
]);
