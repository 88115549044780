export function indexRequest(id) {
  return {
    type: '@pacientesRegistro/INDEX_REQUEST',
    payload: { id },
  };
}

export function indexRequestSuccess(data) {
  return {
    type: '@pacientesRegistro/INDEX_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function storeRequest(
  agendamento_id,
  paciente_id,
  prontuario_categoria_id,
  title,
  conteudo,
  files,
) {
  return {
    type: '@pacientesRegistro/STORE_REQUEST',
    payload: {
      agendamento_id,
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    },
  };
}

export function storeRequestSuccess() {
  return {
    type: '@pacientesRegistro/STORE_REQUEST_SUCCESS',
  };
}

export function registroAtendimentoRequest(id) {
  return {
    type: '@pacientesRegistro/REGISTRO_ATENDIMENTO_REQUEST',
    payload: { id },
  };
}

export function registroAtendimentoRequestSuccess(
  id,
  paciente_id,
  prontuario_categoria_id,
  title,
  conteudo,
  files,
) {
  return {
    type: '@pacientesRegistro/REGISTRO_ATENDIMENTO_REQUEST_SUCCESS',
    payload: {
      id,
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    },
  };
}

export function updateRequest(
  id,
  paciente_id,
  prontuario_categoria_id,
  title,
  conteudo,
  files,
) {
  return {
    type: '@pacientesRegistro/UPDATE_REQUEST',
    payload: {
      id,
      paciente_id,
      prontuario_categoria_id,
      title,
      conteudo,
      files,
    },
  };
}

export function togleFechaModalComments(modal, id) {
  return {
    type: '@pacientesRegistro/COMMENT_ADD_MODAL',
    payload: { modal, id },
  };
}

export function togleFechaModalAdd(modal) {
  return {
    type: '@pacientesRegistro/FECHA_ADD_MODAL',
    payload: { modal },
  };
}

export function togleFechaModalFiles(modal) {
  return {
    type: '@pacientesRegistro/FECHA_FILES_MODAL',
    payload: { modal },
  };
}

export function failure() {
  return {
    type: '@pacientesRegistro/FAILURE',
  };
}
