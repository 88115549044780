export function recoverPassword(email) {
  return {
    type: '@recoverPassword/INDEX_REQUEST',
    payload: {email}
  };
}

export function failure() {
  return {
    type: '@recoverPassword/FAILURE'
  };
}
