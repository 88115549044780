export function indexRequest(page, perPage, date) {
  return {
    type: '@categoriasAgendamento/INDEX_REQUEST',
    payload: { page, perPage, date },
  };
}

export function selectRequest() {
  return {
    type: '@categoriasAgendamento/SELECT_REQUEST',
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {
  return {
    type: '@categoriasAgendamento/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data },
  };
}

export function storeRequest(
  name,
  color,
  duracao,
  intervalo,
  valor,
  financeiro_conta_id,
  add_registro,
  due_days,
) {
  return {
    type: '@categoriasAgendamento/STORE_REQUEST',
    payload: {
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    },
  };
}

export function updateRequest(
  id,
  name,
  color,
  duracao,
  intervalo,
  valor,
  financeiro_conta_id,
  add_registro,
  due_days,
) {
  return {
    type: '@categoriasAgendamento/UPDATE_REQUEST',
    payload: {
      id,
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    },
  };
}

export function categoriaAgendamentoRequest(id) {
  return {
    type: '@categoriasAgendamento/CATEGORIA_AGENDAMENTO_REQUEST',
    payload: { id },
  };
}

export function categoriaAgendamentoRequestSuccess(
  id,
  name,
  color,
  duracao,
  intervalo,
  valor,
  financeiro_conta_id,
  add_registro,
  due_days,
) {
  return {
    type: '@categoriasAgendamento/CATEGORIA_AGENDAMENTO_REQUEST_SUCCESS',
    payload: {
      id,
      name,
      color,
      duracao,
      intervalo,
      valor,
      financeiro_conta_id,
      add_registro,
      due_days,
    },
  };
}

export function deleteRequest(id) {
  return {
    type: '@categoriasAgendamento/DELETE_REQUEST',
    payload: { id },
  };
}

export function deleteRequestSuccess(data) {
  return {
    type: '@categoriasAgendamento/DELETE_REQUEST_SUCCESS',
    payload: { data },
  };
}

export function togleFechaModalAdd(modal, agendamento) {
  return {
    type: '@categoriasAgendamento/FECHA_ADD_MODAL',
    payload: { modal, agendamento },
  };
}

export function failure() {
  return {
    type: '@categoriasAgendamento/FAILURE',
  };
}
