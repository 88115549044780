import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import Ckeditor from '../../../components/Ckeditor';
import Input, { Select } from '../../../components/Input';
import MainHeader from '../../../components/Headers/MainHeader';
import Upload from '../../../components/Upload';
import RegistroComments from '../../../components/RegistroComments';
import {
  registroAtendimentoRequest,
  updateRequest,
} from '../../../store/modules/registrosAtendimentos/actions';
import loadingGlobal from '../../../components/loadingGlobal';
import { selectRequest as selectRequestCategoriasRegistro } from '../../../store/modules/categoriasRegistro/actions';

export default function RegistrosAtendimentosUpdate() {
  const formRef = useRef(null);
  const registrosLoadingData = useSelector(
    (state) => state.registrosAtendimentos.loading,
  );
  const registroAtendimento = useSelector(
    (state) => state.registrosAtendimentos.registroAtendimento,
  );
  const categoriasRegistros = useSelector(
    (state) => state.categoriasRegistro.categoriasRegistro.data,
  );
  const { paciente_id } = registroAtendimento;

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    loadingGlobal(registrosLoadingData);
  }, [registrosLoadingData]);

  useEffect(() => {
    dispatch(registroAtendimentoRequest(id));
    dispatch(selectRequestCategoriasRegistro());
  }, [dispatch, id]);

  async function handleUpdate(data) {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('Titulo é obrigatório'),
        prontuario_categoria_id: Yup.number().required(
          'Selecione uma categoria',
        ),
        conteudo: Yup.string().required('Conteúdo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { title, prontuario_categoria_id, conteudo, files } = data;
      dispatch(
        updateRequest(
          id,
          paciente_id,
          title,
          prontuario_categoria_id,
          conteudo,
          files,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <MainHeader
        name="Registro"
        parentName="Registros de atendimento"
        parentPath="/terapeuta/registros-atendimentos"
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Dados do Registro</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form ref={formRef} onSubmit={handleUpdate}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label paciente-name"
                            htmlFor="input-name"
                          >
                            Paciente:
                          </label>
                          <span>{registroAtendimento.paciente_name}</span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <label
                          className="form-control-label"
                          htmlFor="input-name"
                        >
                          Categoria de Registro
                        </label>
                        <Select
                          name="prontuario_categoria_id"
                          className="form-control"
                          defaultValue={
                            registroAtendimento.prontuario_categoria_id
                          }
                          options={{
                            placeholder: 'Selecione uma categoria de registro',
                          }}
                          data={categoriasRegistros}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="8">
                        <label
                          className="form-control-label"
                          htmlFor="input-titulo"
                        >
                          Titulo
                        </label>
                        <Input
                          name="title"
                          id="input-titulo"
                          placeholder="Titulo"
                          defaultValue={registroAtendimento.title}
                          type="text"
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="12">
                        <label className="form-control-label">Conteudo</label>
                        {registroAtendimento.conteudo && (
                          <Ckeditor
                            name="conteudo"
                            defaultValue={registroAtendimento.conteudo}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        {registroAtendimento.files && (
                          <Upload
                            initialFiles={registroAtendimento.files}
                            prontuario_id={Number(id)}
                          />
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {id && <RegistroComments prontuario_id={id} />}
    </>
  );
}
