import { combineReducers } from 'redux';

import auth from './auth/reducer';
import terapeuta from './terapeuta/reducer';
import pacientes from './pacientes/reducer';
import guardians from './guardians/reducer';
import categoriasRegistro from './categoriasRegistro/reducer';
import categoriasAgendamento from './categoriasAgendamento/reducer';
import pacientesRegistro from './pacientesRegistro/reducer';
import agendamentos from './agendamentos/reducer';
import registrosAtendimentos from './registrosAtendimentos/reducer';
import registrosCompartilhados from './registrosCompartilhados/reducer';
import registroComments from './registroComments/reducer';
import agendamentoSituation from './agendamentoSituation/reducer';
import compartilhamentos from './compartilhamentos/reducer';
import modal from './modal/reducer';
import financeiroContas from './financeiroContas/reducer';
import financeiroContasTipos from './financeiroContasTipos/reducer';
import financeiroCards from './financeiroCards/reducer';
import financeiroRegistros from './financeiroRegistros/reducer';
import financeiroCategorias from './financeiroCategorias/reducer';
import financeiroMetodos from './financeiroMetodos/reducer';
import financeiroDashboard from './financeiroDashboard/reducer';
import financeiroInstitutions from './financeiroInstitutions/reducer';

export default combineReducers({
  auth,
  terapeuta,
  pacientes,
  guardians,
  categoriasRegistro,
  categoriasAgendamento,
  pacientesRegistro,
  agendamentos,
  registrosAtendimentos,
  registrosCompartilhados,
  registroComments,
  agendamentoSituation,
  compartilhamentos,
  modal,
  financeiroContas,
  financeiroContasTipos,
  financeiroCards,
  financeiroRegistros,
  financeiroCategorias,
  financeiroMetodos,
  financeiroDashboard,
  financeiroInstitutions,
});
