export function sendMessage(subject, message) {
  return {
    type: '@sendMessage/STORE_REQUEST',
    payload: { subject, message },
  };
}

export function failure() {
  return {
    type: '@sendMessage/FAILURE',
  };
}
