/**
 * @todo {Exibir valor efetivo pago se a fatura estiver paga}
 * @todo{Se nao encontrar a fatura do mes deixar ativa a ultima fatura}
 */

import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  Table,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import MainHeader from '~/components/Headers/MainHeader';
import Input, { InputMasked } from '~/components/Input';
import { transformMoeda } from '~/helpers/transform';
import {
  togleFechaModalAdd as togleModalCard,
  cardRequest,
  faturasRequest,
  updateRequest,
} from '~/store/modules/financeiroCards/actions';

import UpdateFaturas from '../components/ModalUpdateFaturas';

export default function Cards() {
  const formRef = useRef(null);

  const financeiroCard = useSelector(
    (state) => state.financeiroCards.financeiroCard,
  );
  const faturas = useSelector((state) => state.financeiroCards.faturas.data);
  // const fatura = useSelector((state) => state.financeiroCards.fatura);
  const [fatura, setFatura] = useState({});
  const updateModal = useSelector((state) => state.financeiroCards.addModal);
  const [registro, setRegistro] = useState(null);

  const [active, setActive] = useState(faturas[0] ? faturas[0].id : null);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(cardRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (financeiroCard.id) {
      dispatch(
        faturasRequest(financeiroCard.id, financeiroCard.dia_vencimento),
      );
    }
  }, [dispatch, financeiroCard]);

  useEffect(() => {
    if (faturas.length > 0) {
      const vencimentoMes = moment()
        .set('date', financeiroCard.dia_vencimento)
        .format('YYYY-MM-DD');
      let selected = null;
      faturas.forEach((element) => {
        if (
          moment(element.data_vencimento).format('YYYY-MM-DD') === vencimentoMes
        ) {
          selected = element;
        }
      });

      if (selected) {
        setActive(selected.id);
        setFatura(selected);
      } else {
        const vencimentoAnt = moment()
          .set('date', financeiroCard.dia_vencimento)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');

        faturas.forEach((element) => {
          if (
            moment(element.data_vencimento).format('YYYY-MM-DD') ===
            vencimentoAnt
          ) {
            selected = element;
          }
        });

        if (selected) {
          setActive(selected.id);
          setFatura(selected);
        } else {
          setActive(faturas[0].id);
          setFatura(faturas[0]);
        }
      }
    }
  }, [faturas, financeiroCard]);

  function togleModal(value, register) {
    setRegistro(register);
    dispatch(togleModalCard(value));
  }

  function situacaoAtual(situation) {
    let label = '';
    let cor = '';
    let classe = '';
    switch (Number(situation)) {
      case 1: {
        label = 'Em aberto';
        cor = 'bg-warning';
        classe = 'badge badge-dot mr-4';
        break;
      }
      case 2: {
        label = 'Em atraso';
        cor = 'bg-danger';
        classe = 'badge badge-dot mr-4';
        break;
      }
      case 3: {
        label = 'Pago';
        cor = 'bg-success';
        classe = 'badge badge-dot mr-6';
        break;
      }
      default:
    }
    return (
      <span className={classe}>
        <i className={cor} />
        <span className="status">{label}</span>
      </span>
    );
  }

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({});
      await schema.validate(data, {
        abortEarly: false,
      });
      const { name } = data;
      const limite_total = transformMoeda(data.limite_total, 1);
      dispatch(updateRequest(id, name, limite_total));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  function selectFatura(faturaId) {
    faturas.forEach((element) => {
      if (element.id === faturaId) {
        setFatura(element);
      }
    });
    setActive(faturaId);
  }

  return (
    <>
      <MainHeader
        name="Editar Cartão"
        parentName="Cartões de crédito"
        parentPath="/terapeuta/financeiro/contas"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Cartão Financeiro</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    ref={formRef}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="name">
                          Nome
                        </label>
                        <Input
                          name="name"
                          id="name"
                          defaultValue={financeiroCard.name}
                          placeholder="Nome"
                          type="text"
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="limite_total"
                        >
                          Limite total
                        </label>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="input-icon">
                                <i className="fas fa-money-bill" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <InputMasked
                              name="limite_total"
                              id="limite"
                              className="form-control"
                              placeholder="Limite total"
                              kind="money"
                              defaultValue={transformMoeda(
                                financeiroCard.limite_total,
                                2,
                              )}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="limite_disponivel"
                        >
                          Limite disponível
                        </label>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="input-icon-disabled">
                                <i className="fas fa-money-bill" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              name="limite_disponivel"
                              id="limite_disp"
                              placeholder="Limite disponível"
                              defaultValue={transformMoeda(
                                financeiroCard.limite_disponivel,
                                2,
                              )}
                              disabled
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Atualizar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="2">
                      <h3 className="mb-0">Faturas</h3>
                    </Col>
                  </Row>
                </CardHeader>
                {faturas.length > 0 && (
                  <>
                    <Card className="mt-5 mb-0 mx-5 p-0 shadow-none">
                      <Nav tabs className="border-0">
                        {faturas.map((item) => (
                          <NavItem key={item.id}>
                            <NavLink
                              className={`bg-extrato-hf border-cinza ${
                                active === item.id ? 'active' : ''
                              }`}
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                selectFatura(item.id);
                              }}
                            >
                              {moment(item.data_vencimento).format('MMM/YYYY')}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Card>
                    <Card className="mb-5 mt-0 mx-5 border">
                      <CardHeader>
                        <Row>
                          <Col xs="6">
                            <h4 className="mb-0">{fatura.descricao}</h4>
                          </Col>
                          <Col
                            className="text-right"
                            xs={
                              fatura.financeiro_situation_id === 3 ? '6' : '4'
                            }
                          >
                            {situacaoAtual(fatura.financeiro_situation_id)}
                          </Col>
                          <Col className="text-middle" xs="2">
                            {fatura.financeiro_situation_id === 3 ? (
                              ''
                            ) : (
                              <>
                                <Button
                                  className="btn-neutral btn-round btn-icon"
                                  color="default"
                                  href="#empoderaTerapeuta"
                                  size="sm"
                                  onClick={() => {
                                    togleModal(!updateModal, fatura);
                                  }}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="ni ni-money-coins" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Pagar Fatura
                                  </span>
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row className="justify-content-center">
                          <Col xs="7" className="p-0">
                            <Card className="border my-4 p-0">
                              <CardHeader className="bg-extrato-hf">
                                <h4>Lançamentos</h4>
                              </CardHeader>
                              <CardBody className="p-0 overflow-hidden">
                                <Table className="align-items-center table-flush mb-0">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>Data</th>
                                      <th>Descricao</th>
                                      <th className="d-flex justify-content-end">
                                        Valor
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fatura.subregistros &&
                                      fatura.subregistros.map((parcela) => (
                                        <tr key={parcela.id}>
                                          <td className="table-user">
                                            <b>
                                              {moment(
                                                parcela.data_vencimento,
                                              ).format('DD/MM/YYYY')}
                                            </b>
                                          </td>
                                          <td>
                                            <span className="text-muted">
                                              {parcela.descricao}
                                            </span>
                                          </td>
                                          <td className="d-flex justify-content-end">
                                            <span className="text-muted">
                                              {transformMoeda(
                                                parcela.valor_base,
                                                2,
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </CardBody>
                              <CardFooter className="py-2 px-4 d-flex justify-content-end bg-extrato-hf" />
                            </Card>
                          </Col>
                          <Col xs="3" className="p-0">
                            <Row>
                              <Card className="border ml-6 my-4 p-0 mw-8r">
                                <CardHeader className="bg-extrato-hf">
                                  <h4 className="d-inline-block">Vencimento</h4>
                                </CardHeader>
                                <CardBody className="p-0 overflow-hidden">
                                  <Row className="justify-content-center">
                                    <h2
                                      className={
                                        Number(
                                          fatura.financeiro_situation_id,
                                        ) !== 2
                                          ? ''
                                          : 'negativo'
                                      }
                                    >
                                      {moment(fatura.data_vencimento).format(
                                        'DD/MM/YYYY',
                                      )}
                                    </h2>
                                  </Row>
                                </CardBody>
                                <CardFooter className="py-2 px-4 d-flex justify-content-end bg-extrato-hf" />
                              </Card>
                            </Row>
                            <Row>
                              <Card className="border ml-6 my-4 p-0 mw-8r">
                                <CardHeader className="bg-extrato-hf">
                                  <h4 className="d-inline-block">
                                    Total a Pagar
                                  </h4>
                                </CardHeader>
                                <CardBody className="p-0 overflow-hidden">
                                  <Row className="justify-content-center">
                                    <h2>
                                      <span
                                        className={
                                          Number(
                                            fatura.financeiro_situation_id,
                                          ) !== 2
                                            ? ''
                                            : 'negativo'
                                        }
                                      >
                                        {transformMoeda(
                                          -1 * fatura.valor_base,
                                          2,
                                        )}
                                      </span>
                                    </h2>
                                  </Row>
                                </CardBody>
                                <CardFooter className="py-2 px-4 d-flex justify-content-end bg-extrato-hf" />
                              </Card>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      {registro ? <UpdateFaturas fatura={registro} /> : ''}
    </>
  );
}
