export function showRequest(id) {
  return {
    type: '@terapeuta/SHOW_REQUEST',
    payload: {
      id,
    },
  };
}

export function showRequestSuccess(terapeuta) {
  return {
    type: '@terapeuta/SHOW_REQUEST_SUCCESS',
    payload: {
      terapeuta,
    },
  };
}

export function updateRequest(
  id,
  name,
  email,
  celular,
  telefone,
  nascimento,
  cep,
  uf,
  cidade,
  bairro,
  endereco,
  numero,
  complemento,
) {
  return {
    type: '@terapeuta/UPDATE_REQUEST',
    payload: {
      id,
      name,
      email,
      celular,
      telefone,
      nascimento,
      cep,
      uf,
      cidade,
      bairro,
      endereco,
      numero,
      complemento,
    },
  };
}

export function updateRequestSuccess(terapeuta) {
  return {
    type: '@terapeuta/UPDATE_REQUEST_SUCCESS',
    payload: { terapeuta },
  };
}

export function UpdatePasswordRequest(
  id,
  oldPassword,
  newPassword,
  passwordConfirmation,
  modal,
) {
  return {
    type: '@terapeuta/UPDATE_PASSWORD_REQUEST',
    payload: { id, oldPassword, newPassword, passwordConfirmation, modal },
  };
}

export function UpdatePasswordRequestSuccess() {
  return {
    type: '@terapeuta/UPDATE_PASSWORD_REQUEST_SUCCESS',
    payload: {},
  };
}

export function failure() {
  return {
    type: '@terapeuta/FAILURE',
  };
}
