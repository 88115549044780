import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';

import MainHeader from '../../../components/Headers/MainHeader';
import Input, { InputMasked } from '../../../components/Input';
import loadingGlobal from '../../../components/loadingGlobal';
import { transformMoeda } from '~/helpers/transform';

import {
  updateRequest,
  categoriaAgendamentoRequest,
} from '../../../store/modules/categoriasAgendamento/actions';

export default function Validation() {
  const formRef = useRef(null);
  const categoriasLoadingData = useSelector(
    (state) => state.categoriasAgendamento.loading,
  );
  const categoriaAgendamento = useSelector(
    (state) => state.categoriasAgendamento.categoriaAgendamento,
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    loadingGlobal(categoriasLoadingData);
  }, [categoriasLoadingData]);

  useEffect(() => {
    dispatch(categoriaAgendamentoRequest(id));
  }, [dispatch, id]);

  async function handleSubmit(data) {
    try {
      if (data.valor) {
        data.valor = transformMoeda(data.valor, 1);
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        duracao: Yup.string().required('Duração é obrigatório'),
        intervalo: Yup.string().required('Intervalo é obrigatório'),
        valor: Yup.string(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const { name, duracao, intervalo, valor } = data;
      dispatch(updateRequest(id, name, duracao, intervalo, valor));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }
  return (
    <>
      <MainHeader
        name="Editar Categoria"
        parentName="Categorias de Agendamentos"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nova Categoria</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    ref={formRef}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <Col>
                        <label className="form-control-label" htmlFor="name">
                          Nome
                        </label>
                        <Input
                          name="name"
                          id="name"
                          placeholder="Nome"
                          type="text"
                          defaultValue={categoriaAgendamento.name}
                        />
                      </Col>
                      <Col>
                        <label className="form-control-label" htmlFor="duracao">
                          Duração
                        </label>
                        <Input
                          name="duracao"
                          id="duracao"
                          placeholder="Duração"
                          type="number"
                          defaultValue={Number(categoriaAgendamento.duracao)}
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="intervalo"
                        >
                          Intervalo
                        </label>
                        <Input
                          name="intervalo"
                          id="intervalo"
                          placeholder="Intervalo"
                          type="number"
                          defaultValue={Number(categoriaAgendamento.intervalo)}
                        />
                      </Col>
                      <Col>
                        <label className="form-control-label" htmlFor="valor">
                          Valor
                        </label>
                        <InputMasked
                          name="valor"
                          id="valor"
                          className="form-control"
                          placeholder="Valor da sessão"
                          kind="money"
                          defaultValue={transformMoeda(
                            categoriaAgendamento.valor,
                            2,
                          )}
                        />
                      </Col>
                    </div>
                    <br />
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
