import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  financeiroContasTipos: {
    data: [],
  },
};

export default function financeiroContasTipos(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiroContasTipos/INDEX_REQUEST_SUCCESS': {
        draft.financeiroContasTipos.data = action.payload.data;
        draft.loading = false;

        break;
      }
      case '@financeiroContasTipos/INDEX_REQUEST':
      case '@financeiroContasTipos/FAILURE': {
        draft.loading = true;
        break;
      }
      default:
    }
  });
}
