export function indexRequest(page, perPage) {
  return {
    type: '@categoriasRegistro/INDEX_REQUEST',
    payload: { page, perPage }
  };
}

export function selectRequest() {
  return {
    type: '@categoriasRegistro/SELECT_REQUEST'
  };
}

export function indexRequestSuccess(total, perPage, page, lastPage, data) {

  return {
    type: '@categoriasRegistro/INDEX_REQUEST_SUCCESS',
    payload: { total, perPage, page, lastPage, data }

  };
}

export function storeRequest(name, color, icon, compartilhamento_id) {
  return {
    type: '@categoriasRegistro/STORE_REQUEST',
    payload: { name, color, icon, compartilhamento_id }
  };
}

export function updateRequest(id, name, color, icon, compartilhamento_id) {
  return {
    type: '@categoriasRegistro/UPDATE_REQUEST',
    payload: { id, name, color, icon, compartilhamento_id }
  };
}

export function categoriaRegistroRequest(id) {
  return {
    type: '@categoriasRegistro/CATEGORIA_REGISTRO_REQUEST',
    payload: { id }
  };
}

export function categoriaRegistroRequestSuccess(name, color, icon, compartilhamento_id) {
  return {
    type: '@categoriasRegistro/CATEGORIA_REGISTRO_REQUEST_SUCCESS',
    payload: { name, color, icon, compartilhamento_id }
  };
}

export function deleteRequest(id) {
  return {
    type: '@categoriasRegistro/DELETE_REQUEST',
    payload: { id }
  }
}

export function deleteRequestSuccess(data) {
  return {
    type: '@categoriasRegistro/DELETE_REQUEST_SUCCESS',
    payload: { data }
  }
}

export function togleFechaModalAdd(modal) {
  return {
    type: '@categoriasRegistro/FECHA_ADD_MODAL',
    payload: { modal }

  };
}

export function failure() {
  return {
    type: '@categoriasRegistro/FAILURE'
  };
}
