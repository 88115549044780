/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import history from '~/services/history';

import MainHeader from '~/components/Headers/MainHeader';
import ModalDelete from '~/components/ConfirmDelete';
import ModalCategorias from '../components/ModalCategorias';

import {
  indexRequest,
  deleteRequest,
  togleFechaModalAdd as modalAddCategoria,
} from '~/store/modules/financeiroCategorias/actions';
import { togleFechaModalAdd } from '~/store/modules/modal/actions';

export default function Categorias() {
  const dispatch = useDispatch();
  const addModal = useSelector((state) => state.modal.addModal);
  const addCategoriaModal = useSelector(
    (state) => state.financeiroCategorias.addModal,
  );

  useEffect(() => {
    dispatch(indexRequest(1, 20));
  }, [dispatch]);

  const financeiroCategorias = useSelector(
    (state) => state.financeiroCategorias.financeiroCategorias.data,
  );

  function deleteCategoria(id) {
    dispatch(togleFechaModalAdd(!addModal, id));
  }

  function newCategoria() {
    const categoriaId = null;
    dispatch(modalAddCategoria(!addCategoriaModal, categoriaId));
  }

  function newSubcategoria(id, categoriaName) {
    const categoriaId = id;

    dispatch(modalAddCategoria(!addCategoriaModal, categoriaId, categoriaName));
  }

  return (
    <>
      <MainHeader
        name="Categorias Financeiras"
        parentName="Configurações"
        backwardHome="/terapeuta/dashboard"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Categorias Financeiras</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href="#empoderaTerapeuta"
                  onClick={() => newCategoria()}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span className="btn-inner--text">Nova Categoria</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th style={{ width: '10px' }} />
                <th>Nome</th>
                <th>Data de criação</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {financeiroCategorias.map((categoria) => (
                <tr key={categoria.id}>
                  <td>
                    {categoria.color && (
                      <span
                        className={`timeline-step badge-success ${
                          categoria.parent_id ? 'ml-4' : ''
                        }`}
                        style={{
                          position: 'relative',
                          backgroundColor: categoria.color,
                          color: categoria.color,
                        }}
                      >
                        <i className="fa fa-circle" />
                      </span>
                    )}
                  </td>
                  <td className="table-user">
                    <Button
                      className={`name-index ${
                        categoria.parent_id ? 'ml-4' : ''
                      }`}
                      onClick={() =>
                        history.push(
                          `/terapeuta/financeiro-categorias/editar/${categoria.id}`,
                        )
                      }
                    >
                      <b>{categoria.name}</b>
                    </Button>
                  </td>
                  <td>
                    <span className="text-muted">
                      {moment(categoria.created_at).format('DD/MM/YYYY')}
                    </span>
                  </td>
                  <td
                    className={`table-actions ${
                      categoria.parent_id ? 'subcategoria-actions' : ''
                    }`}
                  >
                    {!categoria.parent_id ? (
                      <a
                        className="table-action"
                        href="#empoderaTerapeuta"
                        onClick={() =>
                          newSubcategoria(categoria.id, categoria.name)
                        }
                      >
                        <i className="fas fa-plus" />
                      </a>
                    ) : (
                      ''
                    )}

                    <a
                      className="table-action"
                      href="#empoderaTerapeuta"
                      onClick={() => {
                        history.push(
                          `/terapeuta/financeiro-categorias/editar/${categoria.id}`,
                        );
                      }}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action-delete"
                      href="#empoderaTerapeuta"
                      onClick={() => {
                        deleteCategoria(categoria.id);
                      }}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))}
              <ModalDelete deleteRequest={deleteRequest} />
            </tbody>
          </Table>
          <ModalCategorias />
        </Card>
      </Container>
    </>
  );
}
