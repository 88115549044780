import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Card, CardHeader, Table, Row, Col, Button } from 'reactstrap';
import AddRegistroAtendimento from './addRegistroAtendimento';

// reactstrap components

import {
  indexRequest,
  atendimentoRequest,
} from '../../../store/modules/agendamentos/actions';
import { selectRequest } from '../../../store/modules/agendamentoSituation/actions';

export default function IndexAgendamentos() {
  const dispatch = useDispatch();
  const agendamentos = useSelector(
    (state) => state.agendamentos.todayAgendamentos,
  );

  useEffect(() => {
    dispatch(indexRequest(moment().format('YYYY-MM-DD')));
    dispatch(selectRequest());
  }, [dispatch]);

  return (
    <>
      <Card className="w-100">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h3 className="mb-0">Atendimentos do dia</h3>
            </Col>
          </Row>
        </CardHeader>
        <Row>
          <Col>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Situação</th>
                  <th>Categoria</th>
                  <th>Cliente</th>
                  <th>Inicio</th>
                  <th>Fim</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {agendamentos &&
                  agendamentos.map((data) => (
                    <tr key={data.id}>
                      <td>
                        <span className="text-muted">
                          {data.situation.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {data.agendamentoCategoria.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">{data.paciente.name}</span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .tz(data.agendamento_start, moment.tz.guess())
                            .format('DD/MM/YYYY HH:mm')}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .tz(data.agendamento_end, moment.tz.guess())
                            .format('DD/MM/YYYY HH:mm')}
                        </span>
                      </td>

                      <td className="table-actions">
                        {data.agendamento_situation_id === 1 && (
                          <Button
                            className="btn-round btn-icon"
                            color="primary"
                            onClick={() => {
                              dispatch(atendimentoRequest(data.id));
                            }}
                            size="sm"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-play-circle" />
                            </span>
                            <span className="btn-inner--text">
                              Iniciar atendimento
                            </span>
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <AddRegistroAtendimento />
      </Card>
    </>
  );
}
