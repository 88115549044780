import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
} from 'reactstrap';
import TextInputMask from 'react-masked-text';
import { dateFormat } from '~/helpers/transform';

import { indexRequest } from '~/store/modules/financeiroRegistros/actions';
import { selectRequest as selectRequestCategorias } from '~/store/modules/financeiroCategorias/actions';
import { selectRequest as selectRequestContas } from '~/store/modules/financeiroContas/actions';

export default function Search() {
  const dispatch = useDispatch();

  const [descricao, setDescricao] = useState('');
  const [contaId, setContaId] = useState(null);
  const [situationId, setSituationId] = useState(null);
  const [categoriasId, setCategoriasId] = useState(null);
  const [vencimentoIni, setVencimentoIni] = useState(null);
  const [vencimentoEnd, setVencimentoEnd] = useState(null);
  const [pagamentoIni, setPagamentoIni] = useState(null);
  const [pagamentoEnd, setPagamentoEnd] = useState(null);

  /** Variaveis para montar o select Categorias */
  const [isLoadingCats, setIsLoadingCats] = useState(false);
  const [optionCats, setOptionCats] = useState([]);
  const [valueCats, setValueCats] = useState([]);

  /** Variaveis para montar o select Contas */
  const [isLoadingConta, setIsLoadingConta] = useState(false);
  const [optionConta, setOptionConta] = useState([]);
  const [valueConta, setValueConta] = useState([]);

  /** Variaveis para Situation */
  const [valueSituation, setValueSituation] = useState([]);

  const handleChangeSituation = (newValue) => {
    setValueSituation(newValue);
    const sitIds = [];
    if (newValue) {
      newValue.forEach((v) => sitIds.push(v.value));
    }
    setSituationId(sitIds);
  };

  const handleChangeCats = (newValue) => {
    setValueCats(newValue);
    const catIds = [];
    if (newValue) {
      newValue.forEach((v) => catIds.push(v.value));
    }
    setCategoriasId(catIds);
  };

  const handleChangeConta = (newValue) => {
    setValueConta(newValue);
    const ctIds = [];
    if (newValue) {
      newValue.forEach((v) => ctIds.push(v.value));
    }
    setContaId(ctIds);
  };

  const selectCategorias = useSelector(
    (state) => state.financeiroCategorias.selectCategorias,
  );

  const selectContas = useSelector(
    (state) => state.financeiroContas.selectContas,
  );

  useEffect(() => {
    const nArray = [];
    selectCategorias.forEach((e) => {
      nArray.push({ label: e.name, value: e.id });
    });
    setOptionCats(nArray);
    setIsLoadingCats(false);
  }, [selectCategorias]);

  useEffect(() => {
    const nArray = [];
    selectContas.forEach((e) => {
      nArray.push({ label: e.name, value: e.id });
    });
    setOptionConta(nArray);
    setIsLoadingConta(false);
  }, [selectContas]);

  useEffect(() => {
    setIsLoadingCats(true);
    setIsLoadingConta(true);
    dispatch(selectRequestContas());
    dispatch(selectRequestCategorias());
  }, [dispatch]);

  function handleClean() {
    setDescricao('');
    setContaId(null);
    setSituationId(null);
    setCategoriasId(null);
    setVencimentoIni(null);
    setVencimentoEnd(null);
    setPagamentoIni(null);
    setPagamentoEnd(null);
    setValueCats([]);
    setValueConta([]);
    setValueSituation([]);

    dispatch(indexRequest(1, 20, null));
  }

  async function handleSubmit() {
    const filter = {
      descricao,
      contaId,
      situationId,
      categoriasId,
      vencimentoIni: dateFormat(vencimentoIni, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
      vencimentoEnd: dateFormat(vencimentoEnd, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
      pagamentoIni: dateFormat(pagamentoIni, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
      pagamentoEnd: dateFormat(pagamentoEnd, ['DD/MM/YYYY'], 'YYYY-MM-DD'),
    };

    dispatch(indexRequest(1, 20, filter));
  }

  return (
    <>
      <Container className="search-header">
        <Row>
          <Col>
            <Row>
              <Col xs="3">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-caps-small" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Descrição"
                      className="form-control"
                      type="text"
                      onChange={(v) => setDescricao(v.target.value)}
                      value={descricao}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="3">
                <ReactSelect
                  placeholder="Categoria..."
                  isMulti
                  className="basic-multi-select"
                  isClearable
                  isDisabled={isLoadingCats}
                  isLoading={isLoadingCats}
                  onChange={handleChangeCats}
                  options={optionCats}
                  value={valueCats}
                />
              </Col>
              <Col xs="3">
                <ReactSelect
                  placeholder="Conta..."
                  isMulti
                  className="basic-multi-select"
                  isClearable
                  isDisabled={isLoadingConta}
                  isLoading={isLoadingConta}
                  onChange={handleChangeConta}
                  options={optionConta}
                  value={valueConta}
                />
              </Col>
              <Col xs="3">
                <ReactSelect
                  placeholder="Situação..."
                  isMulti
                  className="basic-multi-select"
                  isClearable
                  onChange={handleChangeSituation}
                  options={[
                    { label: 'Em aberto', value: 1 },
                    { label: 'Pago', value: 3 },
                    { label: 'Em atraso', value: 2 },
                  ]}
                  value={valueSituation}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <TextInputMask
                      className="form-control"
                      placeholder="Vencimento inicio"
                      kind="datetime"
                      options={{
                        format: 'DD/MM/YYYY',
                      }}
                      onChangeText={(v) => setVencimentoIni(v)}
                      value={vencimentoIni}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="3">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <TextInputMask
                      name="phone_input"
                      className="form-control"
                      placeholder="Vencimento fim"
                      kind="datetime"
                      options={{
                        format: 'DD/MM/YYYY',
                      }}
                      onChangeText={(v) => setVencimentoEnd(v)}
                      value={vencimentoEnd}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="3">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <TextInputMask
                      className="form-control"
                      placeholder="Pagamento inicio"
                      kind="datetime"
                      options={{
                        format: 'DD/MM/YYYY',
                      }}
                      onChangeText={(v) => setPagamentoIni(v)}
                      value={pagamentoIni}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="3">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <TextInputMask
                      name="phone_input"
                      className="form-control"
                      placeholder="Pagamento fim"
                      kind="datetime"
                      options={{
                        format: 'DD/MM/YYYY',
                      }}
                      onChangeText={(v) => setPagamentoEnd(v)}
                      value={pagamentoEnd}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-left btn-padding" xs="12">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  href=""
                  onClick={() => {
                    handleClean();
                  }}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-notes-medical" />
                  </span>
                  <span className="btn-inner--text">Limpar Filtros</span>
                </Button>
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="default"
                  type="submit"
                  size="sm"
                  onClick={() => handleSubmit()}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-notes-medical" />
                  </span>
                  <span className="btn-inner--text">Buscar</span>
                </Button>
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}
