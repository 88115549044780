import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';

import MainHeader from '~/components/Headers/MainHeader';
import Input from '~/components/Input';

import {
  updateRequest,
  categoriaRequest,
} from '~/store/modules/financeiroCategorias/actions';

export default function Categoria() {
  const formRef = useRef(null);
  const financeiroCategoria = useSelector(
    (state) => state.financeiroCategorias.financeiroCategoria,
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(categoriaRequest(id));
  }, [dispatch, id]);

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string(),
        color: Yup.string(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const { name, color } = data;
      dispatch(updateRequest(id, name, color));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }
  return (
    <>
      <MainHeader
        name="Editar Categoria"
        parentName="Categorias de Registros"
        parentPath="/terapeuta/financeiro-categorias"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Categoria Financeira</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    ref={formRef}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="name">
                          Nome
                        </label>
                        <Input
                          name="name"
                          id="name"
                          defaultValue={financeiroCategoria.name}
                          placeholder="Nome"
                          type="text"
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="color">
                          Cor
                        </label>
                        <Input
                          name="color"
                          id="color"
                          defaultValue={
                            financeiroCategoria.color
                              ? financeiroCategoria.color
                              : '#2dce89'
                          }
                          type="color"
                        />
                      </Col>
                    </div>

                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
