export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function terapeutaTiposRequest() {
  return {
    type: '@auth/TERAPEUTA_TIPO_REQUEST',
  };
}

export function terapeutaTiposSuccess(tipos) {
  return {
    type: '@auth/TERAPEUTA_TIPO_SUCCESS',
    payload: { tipos },
  };
}

export function signUpRequest(
  terapeuta_tipo_id,
  tipo_outro,
  name,
  email,
  password,
  password_confirmation,
) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      terapeuta_tipo_id,
      tipo_outro,
      name,
      email,
      password,
      password_confirmation,
    },
  };
}

export function signUpSuccess(email) {
  return {
    type: '@auth/SIGN_UP_SUCCESS',
    payload: {
      email,
    },
  };
}

export function signInSuccess(token, terapeuta) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, terapeuta },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}
