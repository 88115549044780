import PropTypes from 'prop-types';

function loadingGlobal(show) {
  const loader = document.querySelector('.loaderGlobalMT');
  if (show) {
    return loader.classList.remove('loader--hide');
  }
  return loader.classList.add('loader--hide');
}

loadingGlobal.propTypes = {
  show: PropTypes.number,
};

// Same approach for defaultProps too
loadingGlobal.defaultProps = {
  show: false,
};

export default loadingGlobal;
