/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
} from 'reactstrap';

import MainHeader from '~/components/Headers/MainHeader';
import Input, { InputMasked } from '~/components/Input';
import { transformMoeda } from '~/helpers/transform';

import {
  indexRequest,
  storeRequest,
} from '~/store/modules/financeiroCards/actions';

export default function Contas() {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  useEffect(() => {
    dispatch(indexRequest(1, 20));
  }, [dispatch]);

  const financeiroCards = useSelector(
    (state) => state.financeiroCards.financeiroCards.data,
  );

  async function handleSubmit(data) {
    try {
      if (data.limite_total) {
        data.limite_total = transformMoeda(data.limite_total, 1);
      }
      const schema = Yup.object().shape({
        name: Yup.string().required(
          'Informar um nome para a conta é obrigatório',
        ),
        saldo_inicial: Yup.number(),
        data_saldo_inicial: Yup.date(),
        dia_fechamento: Yup.number(),
        dia_vencimento: Yup.number(),
        limite_total: Yup.number(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { name, dia_fechamento, dia_vencimento, limite_total } = data;
      const conta_tipo_id = 4;

      dispatch(
        storeRequest(
          name,
          conta_tipo_id,
          dia_fechamento,
          dia_vencimento,
          limite_total,
        ),
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Algo não deu certo, verifique os erros e tente novamente.');
    }
  }

  return (
    <>
      <MainHeader name="Cartões" parentName="Financeiro" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <Card className="pb-0">
              <CardHeader className="border-0 pb-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Cadastrar Cartão</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  role="form"
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="needs-validation"
                >
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <InputGroup className="input-group-merge input-group mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="name"
                            id="name"
                            placeholder="Nome da cartão"
                            type="text"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <InputGroup className="input-group-merge input-group mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-money-bill" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <InputMasked
                            name="limite_total"
                            id="limite"
                            className="form-control"
                            placeholder="Limite total"
                            kind="money"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <InputGroup className="input-group-merge input-group mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-calendar-check" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            name="dia_fechamento"
                            id="fechamento"
                            placeholder="Dia de fechamento"
                            type="number"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <InputGroup className="input-group-merge input-group mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-calendar-check" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            name="dia_vencimento"
                            id="vencimento"
                            placeholder="Dia de vencimento"
                            type="number"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">
                    Cadastrar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Cartões Cadastrados</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Bandeira</th>
                      <th>Limite</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financeiroCards.map((card) => (
                      <tr key={card.id}>
                        <td className="table-user">
                          <b>{card.name}</b>
                        </td>
                        <td>
                          <span className="text-muted">
                            R$
                            {card.limite_total}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
